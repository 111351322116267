import { Events } from "phaser";
import { PrerenderedPhraseData } from "Types";
import { Timestamp } from "Types/ExerciseData";
import { ProgressState } from "./GameObjects/Progress";
import { PhraseData } from "./GameObjects/StaffLine";

export enum ReactToExerciseEventType {
  UpdateObjective = "update-objective",
  PauseExercise = "pause-exercise",
  ResumeExercise = "resume-exercise",
  StartExercise = "start-exercise",
  MidiOn = "midi-on",
  MidiOff = "midi-off",
  SkipExercise = "skip-exercise",
  UnloadExercise = "unload-exercise",
  AddPhrase = "add-phrase",
  PulseNote = "pulse-note",
  SetNoteState = "set-note-color",
  SetCursor = "set-cursor",
}

type UpdateObjective = [ReactToExerciseEventType.UpdateObjective];
type PauseExercise = [ReactToExerciseEventType.PauseExercise];
type ResumeExercise = [ReactToExerciseEventType.ResumeExercise];
type StartExercise = [ReactToExerciseEventType.StartExercise];
type UnloadExercise = [ReactToExerciseEventType.UnloadExercise];
type MidiOn = [ReactToExerciseEventType.MidiOn, { note: number, velocity: number }];
type MidiOff = [ReactToExerciseEventType.MidiOff, { note: number, velocity: number }];
type SkipExercise = [ReactToExerciseEventType.SkipExercise];
type PulseNote = [ReactToExerciseEventType.PulseNote, { id: string }];
type SetNoteState = [ReactToExerciseEventType.SetNoteState, { id: string, state: ProgressState }];

// Events related to updating variables related to the lesson scene
type AddPhrase = [
  ReactToExerciseEventType.AddPhrase,
  { phrase: PhraseData | undefined, cursorData: PrerenderedPhraseData[] },
];
type SetCursor = [ReactToExerciseEventType.SetCursor, { x: number }];

export type ReactToExerciseEvent =
  | UpdateObjective
  | PauseExercise
  | ResumeExercise
  | StartExercise
  | MidiOn
  | MidiOff
  | UnloadExercise
  | SkipExercise
  | AddPhrase
  | PulseNote
  | SetNoteState
  | SetCursor;

export enum ExerciseToReactEventType {
  ExercisePassed = "exercise-passed",
  ObjectiveComplete = "objective-complete",
  ExercisePaused = "exercise-paused",
  ExerciseResumed = "exercise-resumed",
  CurrentSceneReady = "current-scene-ready",
  ShowExercise = "show-exercise",
  ObjectiveSkipped = "objective-skipped",
  HideExercise = "hide-exercise",
  SkipButtonRequest = "skip-button-request",
  PhaserReady = "phaser-ready",
}

type ObjectiveComplete = [
  ExerciseToReactEventType.ObjectiveComplete,
  { jumpTo: Timestamp },
];
type ExercisePassed = [ExerciseToReactEventType.ExercisePassed];
type ExercisePaused = [ExerciseToReactEventType.ExercisePaused];
type ExerciseResumed = [ExerciseToReactEventType.ExerciseResumed];
type PhaserReady = [ExerciseToReactEventType.PhaserReady];
type CurrentSceneReady = [
  ExerciseToReactEventType.CurrentSceneReady,
  Phaser.Scene,
];
type ShowExercise = [typeof ExerciseToReactEventType.ShowExercise];
type ObjectiveSkipped = [
  ExerciseToReactEventType.ObjectiveSkipped,
  { jumpTo: Timestamp },
];
type HideExercise = [ExerciseToReactEventType.HideExercise];
type SkipButtonRequest = [ExerciseToReactEventType.SkipButtonRequest];

type ExerciseToReactEvent =
  | ExercisePassed
  | ObjectiveComplete
  | ExercisePaused
  | ExerciseResumed
  | CurrentSceneReady
  | HideExercise
  | ObjectiveSkipped
  | ShowExercise
  | PhaserReady
  | SkipButtonRequest;

export const UpdateObjective = () =>
  [ReactToExerciseEventType.UpdateObjective] as UpdateObjective;
export const UnloadExercise = () =>
  [ReactToExerciseEventType.UnloadExercise] as UnloadExercise;
export const PauseExercise = () =>
  [ReactToExerciseEventType.PauseExercise] as PauseExercise;
export const ResumeExercise = () =>
  [ReactToExerciseEventType.ResumeExercise] as ResumeExercise;
export const StartExercise = () =>
  [ReactToExerciseEventType.StartExercise] as StartExercise;
export const MidiOn = (note: number, velocity: number) =>
  [ReactToExerciseEventType.MidiOn, { note, velocity }] as MidiOn;
export const MidiOff = (note: number) =>
  [ReactToExerciseEventType.MidiOff, { note }] as MidiOff;
export const SkipExercise = () =>
  [ReactToExerciseEventType.SkipExercise] as SkipExercise;

export const AddPhrase = (phrase: PhraseData | undefined, cursorData: PrerenderedPhraseData[]) =>
  [ReactToExerciseEventType.AddPhrase, { phrase, cursorData }] as AddPhrase;
export const SetCursor = (x: number) =>
  [ReactToExerciseEventType.SetCursor, { x }] as SetCursor;
export const SetNoteState = (id: string, state: ProgressState) =>
  [ReactToExerciseEventType.SetNoteState, { id, state }] as SetNoteState;
export const PulseNote = (id: string) => [ReactToExerciseEventType.PulseNote, { id }] as PulseNote;

export const ListenUnloadExercise = (cb: () => void) =>
  [ReactToExerciseEventType.UnloadExercise, cb] as Cb<UnloadExercise>;
export const ListenUpdateObjective = (cb: () => void) =>
  [ReactToExerciseEventType.UpdateObjective, cb] as Cb<UpdateObjective>;
export const ListenPauseExercise = (cb: () => void) =>
  [ReactToExerciseEventType.PauseExercise, cb] as Cb<PauseExercise>;
export const ListenResumeExercise = (cb: () => void) =>
  [ReactToExerciseEventType.ResumeExercise, cb] as Cb<ResumeExercise>;
export const ListenStartExercise = (cb: () => void) =>
  [ReactToExerciseEventType.StartExercise, cb] as Cb<StartExercise>;
export const ListenMidiOn = (cb: (arg: { note: number, velocity: number }) => void) =>
  [ReactToExerciseEventType.MidiOn, cb] as Cb<MidiOn>;
export const ListenMidiOff = (cb: (arg: { note: number }) => void) =>
  [ReactToExerciseEventType.MidiOff, cb] as Cb<MidiOff>;
export const ListenSkipExercise = (cb: () => void) =>
  [ReactToExerciseEventType.SkipExercise, cb] as Cb<SkipExercise>;
export const ListenAddPhrase = (
  cb: (arg: { phrase: PhraseData | undefined, cursorData: PrerenderedPhraseData[] }) => void,
) => [ReactToExerciseEventType.AddPhrase, cb] as Cb<AddPhrase>;
export const ListenSetCursor = (cb: (arg: { x: number }) => void) =>
  [ReactToExerciseEventType.SetCursor, cb] as Cb<SetCursor>;
export const ListenPulseNote = (cb: (arg: { id: string }) => void) =>
  [ReactToExerciseEventType.PulseNote, cb] as Cb<PulseNote>;
export const ListenSetNoteState = (cb: (arg: { id: string, state: ProgressState }) => void) =>
  [ReactToExerciseEventType.SetNoteState, cb] as Cb<SetNoteState>;

export const ExercisePassed = () =>
  [ExerciseToReactEventType.ExercisePassed] as ExercisePassed;
export const ObjectiveComplete = (jumpTo: Timestamp) =>
  [ExerciseToReactEventType.ObjectiveComplete, { jumpTo }] as ObjectiveComplete;
export const ExercisePaused = () =>
  [ExerciseToReactEventType.ExercisePaused] as ExercisePaused;
export const ExerciseResumed = () =>
  [ExerciseToReactEventType.ExerciseResumed] as ExerciseResumed;
export const ObjectiveSkipped = (jumpTo: Timestamp) =>
  [ExerciseToReactEventType.ObjectiveSkipped, { jumpTo }] as ObjectiveSkipped;
export const HideExercise = () =>
  [ExerciseToReactEventType.HideExercise] as HideExercise;
export const ShowExercise = () =>
  [ExerciseToReactEventType.ShowExercise] as ShowExercise;
export const SkipButtonRequest = () =>
  [ExerciseToReactEventType.SkipButtonRequest] as SkipButtonRequest;
export const CurrentSceneReady = (scene: Phaser.Scene) =>
  [ExerciseToReactEventType.CurrentSceneReady, scene] as CurrentSceneReady;
export const PhaserReady = () =>
  [ExerciseToReactEventType.PhaserReady] as PhaserReady;

export const ListenExercisePassed = (cb: () => void) =>
  [ExerciseToReactEventType.ExercisePassed, cb] as Cb<ExercisePassed>;
export const ListenObjectiveComplete = (
  cb: (arg: { jumpTo: Timestamp }) => void,
) => [ExerciseToReactEventType.ObjectiveComplete, cb] as Cb<ObjectiveComplete>;
export const ListenExercisePaused = (cb: () => void) =>
  [ExerciseToReactEventType.ExercisePaused, cb] as Cb<ExercisePaused>;
export const ListenExerciseResumed = (cb: () => void) =>
  [ExerciseToReactEventType.ExerciseResumed, cb] as Cb<ExerciseResumed>;
export const ListenObjectiveSkipped = (
  cb: (arg: { jumpTo: Timestamp }) => void,
) => [ExerciseToReactEventType.ObjectiveSkipped, cb] as Cb<ObjectiveSkipped>;
export const ListenHideExercise = (cb: () => void) =>
  [ExerciseToReactEventType.HideExercise, cb] as Cb<HideExercise>;
export const ListenShowExercise = (cb: () => void) =>
  [ExerciseToReactEventType.ShowExercise, cb] as Cb<ShowExercise>;
export const ListenSkipButtonRequest = (cb: () => void) =>
  [ExerciseToReactEventType.SkipButtonRequest, cb] as Cb<SkipButtonRequest>;
export const ListenCurrentSceneReady = (cb: () => void) =>
  [ExerciseToReactEventType.CurrentSceneReady, cb] as Cb<CurrentSceneReady>;
export const ListenPhaserReady = (cb: () => void) =>
  [ExerciseToReactEventType.PhaserReady, cb] as Cb<PhaserReady>;

// Used to emit events between React components and Phaser scenes
// https://newdocs.phaser.io/docs/3.70.0/Phaser.Events.EventEmitter
export const EventBus = new Events.EventEmitter();

// Queue of events that dont currently have any listeners registered for them
const waitingQueueFromReact = new Map<
  ReactToExerciseEventType,
  ReactToExerciseEvent[]
>();
const waitingQueueFromPhaser = new Map<
  ExerciseToReactEventType,
  ExerciseToReactEvent[]
>();

/// Send event from phaser to react
export const NotifyReact = ([type, payload]: ExerciseToReactEvent) => {
  EventBus.emit(type, payload);
  if (EventBus.listeners(type).length === 0) {
    // There are no listeners registered for this event yet. Push this to the back of the queue in case
    // someone comes looking for it later
    const queue = waitingQueueFromPhaser.get(type);
    if (!queue) {
      waitingQueueFromPhaser.set(type, [
        [type, payload] as ExerciseToReactEvent,
      ]);
    } else {
      queue.push([type, payload] as ExerciseToReactEvent);
    }
  }
};
/// Send event from react to phaser
export const NotifyPhaser = ([type, payload]: ReactToExerciseEvent) => {
  console.debug("notifying phaser", type, payload);
  if (EventBus.listeners(type).length === 0) {
    // There are no listeners registered for this event yet. Push this to the back of the queue in case
    // someone comes looking for it later
    const queue = waitingQueueFromReact.get(type);
    if (!queue) {
      waitingQueueFromReact.set(type, [
        [type, payload] as ReactToExerciseEvent,
      ]);
    } else {
      queue.push([type, payload] as ReactToExerciseEvent);
    }
  }
  EventBus.emit(type, payload);
};

export type Cb<T extends [any, any?]> = [[T][0][0], (arg: T[1]) => void];

/// Listen for events emitted by react
export function ListenForReactEvent<T extends ReactToExerciseEvent>(
  cb: Cb<T>,
  ctx?: object,
  once?: boolean,
  collectWaiting?: boolean,
) {
  if (collectWaiting) {
    const [type] = cb;
    const queue = waitingQueueFromReact.get(type);
    console.log("collecting");
    if (queue) {
      let popped = queue.shift();
      while (popped) {
        const [_, payload] = popped;
        cb[1].call(ctx, payload);
        if (once) return;
        popped = queue.shift();
      }
    }
  }
  once ? EventBus.once(cb[0], cb[1], ctx) : EventBus.on(cb[0], cb[1], ctx);
}

/// Listen for events emitted by phaser
export function ListenForPhaserEvent<T extends ExerciseToReactEvent>(
  cb: Cb<T>,
  ctx?: object,
  collectWaiting?: boolean,
) {
  if (collectWaiting) {
    const [type] = cb;
    const queue = waitingQueueFromPhaser.get(type);
    if (queue) {
      let popped = queue.shift();
      while (popped) {
        const [_, payload] = popped;
        cb[1].call(ctx, payload);
        popped = queue.shift();
      }
    }
  }
  EventBus.on(cb[0], cb[1], ctx);
}

export const RemoveListener = (
  event: ReactToExerciseEventType | ExerciseToReactEventType,
  fn?: () => void,
) => EventBus.off(event, fn);
