import TimeSignature from 'Models/TimeSignature';
import { GameObjects, Scene } from 'phaser';
import { colors } from 'Phaser/config';
import { BaseNote } from '../GameObjects/Piano';
import IPlayable from './Playable';
import { ProgressState } from './Progress';
import { Pitch, PrerenderedPhraseData } from 'Types';

interface Config {
  globalScaling: number;
  pitchLineGap: number;
  phraseWidth: number;
  pitchLineWidth: number;
  measureLineWidth: number;
  noteScaling: number;
  staffSpacingVertical: number;
  // The gap between the first note in a measure and the measure line.
  // In case of a measure which contains the clef symbol, the total
  // gap between the first note and the measure line for that measure
  // will be firstNoteGap + any other symbols that appear before it
  // such as time signatures, clefs, etc.
  firstNoteGap: number;
  clefSymbolMargin: number;
  centreAlign: boolean;
}

export function DefaultConfig(config: Partial<Config>): Config {
  const dpr = 1;
  const def = {
    globalScaling: (config.globalScaling ?? 1) * dpr,
    noteScaling: (config.noteScaling ?? 1) * dpr,
    phraseWidth: (config.phraseWidth ?? 1440) * dpr,
    pitchLineWidth: (config.pitchLineWidth ?? 4) * dpr,
    pitchLineGap: (config.pitchLineGap ?? 40) * dpr,
    measureLineWidth: (config.measureLineWidth ?? 6) * dpr,
    staffSpacingVertical: (config.staffSpacingVertical ?? 200) * dpr,
    firstNoteGap: (config.firstNoteGap ?? 40) * dpr,
    clefSymbolMargin: (config.clefSymbolMargin ?? 20) * dpr,
    centreAlign: config.centreAlign ?? false,
  };
  return def;
}

function getAccidentalShift(accidental: number) {
  switch (accidental) {
    // Sharp
    case 0:
      return 1;
    // Flat
    case 1:
      return -1;
    // None
    case 2:
      return 0;
    default:
      console.error('other accidental shifts not currently supported :(');
      return null;
  }
}

function getBaseNoteFrom(fundamentalNote: number, accidental: number) {
  const shiftedNote = fundamentalNote + getAccidentalShift(accidental)!;
  switch (shiftedNote) {
    case 0:
      return 'C';
    case 1:
      return 'C#';
    case 2:
      return 'D';
    case 3:
      return 'D#';
    case 4:
      return 'E';
    case 5:
      return 'F';
    case 6:
      return 'F#';
    case 7:
      return 'G';
    case 8:
      return 'G#';
    case 9:
      return 'A';
    case 10:
      return 'A#';
    case 11:
      return 'B';
    default:
      console.error(
        `Note number must lie within the range 0-11 found ${fundamentalNote}, ${accidental}`
      );
      return null;
  }
}

export function noteFromOsmdPitch(pitch: Pitch): [BaseNote, number] {
  return [
    getBaseNoteFrom(pitch.fundamentalNote, pitch.accidental)!,
    pitch.octave + 3,
  ];
}

/*export class PhraseData {
  phrase: Phrase;
  uuid: string;
  phraseIdx: number;
  bassClef: ClefData;
  trebleClef: ClefData;
  measureData: MeasureProps[] = [];
  startTimestamp: number;
  timeSignature?: TimeSignature;

  constructor(
    uuid: string,
    phraseIdx: number,
    bassNotes: VoiceData[],
    trebleNotes: VoiceData[],
    measureData: MeasureProps[],
    startTimestamp: number,
    phrase: Phrase,
    timeSignature?: TimeSignature
  ) {
    this.phrase = phrase;
    this.uuid = uuid;
    this.startTimestamp = startTimestamp;
    this.timeSignature = timeSignature;
    this.phraseIdx = phraseIdx;
    this.trebleClef = {
      notes: trebleNotes,
      clef: 'treble',
      octaveOffset: 0,
    };
    this.bassClef = {
      notes: bassNotes,
      clef: 'bass',
      octaveOffset: 0,
    };
    this.measureData = measureData;
  }
}*/

export interface VoiceData {
  x: number;
  measureIdx: number;
  timestamp: number;
  voice: 'rest' | { note: [BaseNote, number] };
  id: string;
  length: number;
}

export type ClefType = 'bass' | 'treble';

class RestGraphic extends Phaser.GameObjects.Image implements IPlayable {
  isFocused: boolean = false;
  timingState?: ProgressState;
  id: string;
  constructor(
    scene: Phaser.Scene,
    id: string,
    x: number,
    y: number,
    length: number
  ) {
    let lengthToRestTexture =
      (length === 1 ? 'whole' : length === 0.5 ? 'half' : 'quarter') + '_rest';
    super(scene, x, y, lengthToRestTexture);
    this.id = id;
  }

  update(_: number) { }

  destroy() {
    //this.resetTimer?.remove();
    this.isFocused = false;
    super.destroy();
  }

  setFocus(focus: boolean) {
    this.isFocused = focus;
  }

  setTimingState(state: ProgressState) {
    this.timingState = state;
    switch (state) {
      case ProgressState.LateStart:
      case ProgressState.MistimedRelease:
        this.setTintFill(colors.noteFillOffTime);
        break;
      case ProgressState.Ended:
        this.setTintFill(colors.noteFillOnTime);
        break;
      case ProgressState.Missed:
        this.setTintFill(colors.noteFillMiss);
        break;
      default:
    }
  }

  getState() {
    return this.timingState;
  }

  shake() { }

  reset(force: boolean) {
    this.timingState = undefined;
    if (force) {
      this.setTintFill(0);
    }
  }

  getPosition() {
    return { x: this.x, y: this.y };
  }

  getHaloOffset() {
    return 0;
  }

  setColor(_: number | string) {
    //this.graphic.setTintFill(color);
  }

  pulse() { }
}

class NoteGraphic extends Phaser.GameObjects.Container implements IPlayable {
  isFocused: boolean = false;
  timestamp: number;
  timingState?: ProgressState;
  noteHead!: Phaser.GameObjects.Image;
  stem?: Phaser.GameObjects.Rectangle;
  ledgerLines: Phaser.GameObjects.Rectangle[] = [];
  noteLength: number;
  id: string;
  tweenHandles: Phaser.Tweens.Tween[] = [];
  noteDot?: Phaser.GameObjects.Arc;
  pulseObject?: Phaser.GameObjects.Arc;
  pulseTween?: Phaser.Tweens.Tween;
  originalPosition: number;

  constructor(
    scene: Scene,
    id: string,
    parent: Clef,
    x: number,
    line: number,
    length: number,
    { pitchLineGap, pitchLineWidth }: Config,
    timestamp: number
  ) {
    super(scene);
    this.timestamp = timestamp;
    this.noteLength = length;
    this.id = id;
    scene.add.existing(this);
    const yPos = (line / 2) * pitchLineGap;
    let dotted = false;
    switch (length) {
      case 0.25:
        this.noteHead = scene.add.image(x, yPos, 'quarter_note');
        break;
      case 0.3125:
        this.noteHead = scene.add.image(x, yPos, 'quarter_note');
        dotted = true;
        break;
      case 0.5:
        this.noteHead = scene.add.image(x, yPos, 'half_note');
        break;
      case 0.75:
        this.noteHead = scene.add.image(x, yPos, 'half_note');
        dotted = true;
        break;
      case 1:
        this.noteHead = scene.add.image(x, yPos, 'whole_note');
        break;
      case 1.5:
        this.noteHead = scene.add.image(x, yPos, 'whole_note');
        dotted = true;
        break;
      default:
        console.error('Only half, quarter and whole notes supported for now!');
    }
    this.noteHead.setScale(pitchLineGap / this.noteHead.displayHeight);
    this.add(this.noteHead);
    if (dotted) {
      const dotX = this.noteHead.x + this.noteHead.displayWidth * 1.25;
      const dotY = this.noteHead.y - this.noteHead.displayHeight / 2.5;
      const dotR = this.noteHead.displayWidth / 4;
      this.noteDot = scene.add.circle(dotX, dotY, dotR, 0x0, 1);
      this.add(this.noteDot);
    }
    const ledgerLineWidth = this.noteHead.displayWidth * 1.5;
    // The stem width should be relative to the width of the note head itself,
    // otherwise it doesn't scale properly along with everything else.
    const stemWidth = this.noteHead.displayWidth * 0.1;
    const stemHeight = pitchLineGap * 3.5;
    let stemYOffset = stemHeight / 2;
    let stemXOffset = -this.noteHead.displayWidth / 2 + stemWidth / 2;
    if (line >= 6) {
      stemYOffset *= -1;
      stemXOffset *= -1;
    }
    this.stem = [0.25, 0.375, 0.5, 0.75].contains(length)
      ? scene.add.rectangle(
        x + stemXOffset,
        yPos + stemYOffset,
        stemWidth,
        stemHeight,
        0,
        1
      )
      : undefined;
    this.stem && this.add(this.stem);
    if (line < 0) {
      const numLedgerLines = Math.floor((0 - line) / 2);
      for (let i = 0; i < numLedgerLines; i++) {
        this.ledgerLines.push(
          scene.add.rectangle(
            x,
            (-i - 1) * pitchLineGap,
            ledgerLineWidth,
            pitchLineWidth,
            0x0,
            1
          )
        );
      }
    } else {
      const numLedgerLines = Math.floor((line - 8) / 2);
      for (let i = 0; i < numLedgerLines; i++) {
        this.ledgerLines.push(
          scene.add.rectangle(
            x,
            (i + 5) * pitchLineGap,
            ledgerLineWidth,
            pitchLineWidth,
            0x0,
            1
          )
        );
      }
    }
    this.ledgerLines.forEach(line => {
      this.add(line);
      this.sendToBack(line);
    });
    parent.add(this);
    this.originalPosition = this.x;
  }

  update(_: number) { }

  destroy() {
    this.tweenHandles.forEach((tween) => tween.remove());
    //this.resetTimer?.remove();
    this.isFocused = false;
    this.stem?.destroy();
    this.ledgerLines.forEach((l) => l.destroy());
    this.ledgerLines.clear();
    this.noteHead.destroy();
    this.pulseTween?.stop();
    this.pulseObject?.destroy();
    this.noteDot?.destroy();
    super.destroy();
  }

  setFocus(focus: boolean) {
    this.isFocused = focus;
    this.tweenHandles.forEach((tween) => tween.remove());
    /*if (focus) {
      this.tween = this.scene.tweens.add({
        targets: this,
        scale: this.origScale * 1.35,
        yoyo: true,
        duration: 500,
        ease: "Bounce",
      });
    }*/
  }

  setTintFill(color: number) {
    this.stem?.setFillStyle(color);
    this.noteHead.setTintFill(color);
    this.noteDot?.setFillStyle(color);
  }

  setTimingState(state: ProgressState) {
    this.timingState = state;
    switch (state) {
      case ProgressState.LateStart:
      case ProgressState.MistimedRelease:
        this.setTintFill(colors.noteFillOffTime);
        break;
      case ProgressState.Ended:
        this.setTintFill(colors.noteFillOnTime);
        break;
      case ProgressState.Missed:
        this.setTintFill(colors.noteFillMiss);
        break;
      default:
    }
  }

  getState() {
    return this.timingState;
  }

  shake() {
    this.tweenHandles.push(
      this.scene.tweens.add({
        targets: { value: 0 },
        value: Math.PI * 2,
        duration: 250,
        //ease: Phaser.Math.Easing.Sine.In,
        repeat: 1,
        onUpdate: (_tween, _target, _key, current) => {
          console.log(current);
          this.setX(this.originalPosition + Math.sin(current) * 10 * 1);
        },
      })
    );
  }

  reset(force: boolean) {
    this.timingState = undefined;
    if (force) {
      this.setTintFill(0);
      this.pulseObject?.destroy();
      this.pulseObject = undefined;
      this.pulseTween?.remove();
      this.tweenHandles.forEach((tween) => tween.remove());
      //this.setScale(this.origScale);
    }
  }

  getPosition() {
    return { x: this.noteHead.x, y: this.noteHead.y };
  }

  getHaloOffset() {
    if (this.noteLength !== 1) {
      return this.height / 3.75;
    } else {
      return 0;
    }
  }

  pulse() {
    if (!this.pulseObject) {
      this.pulseObject = this.scene.add.circle(
        this.noteHead.x,
        this.noteHead.y,
        0,
        colors.green,
        1
      );
      this.add(this.pulseObject);
    }
    this.pulseTween = this.scene.tweens.add({
      targets: this.pulseObject,
      radius: 40,
      alpha: 0,
      duration: 750,
      onComplete: () => {
        this.pulseObject?.setAlpha(0);
      },
      ease: 'Power2',
      callbackScope: this,
    });
  }

  setColor(color: string | number) {
    this.setTintFill(Phaser.Display.Color.ValueToColor(color).color);
    //this.noteHead.setTintFill(col);
    //this.stem?.setFillStyle(col);
  }
}

interface ClefData {
  notes: VoiceData[];
  clef: ClefType;
  octaveOffset: number;
}

type VoiceGraphic = NoteGraphic | RestGraphic;

class Clef extends GameObjects.Container {
  pitchLines: GameObjects.Rectangle[] = [];
  barWidths: number[] = [];
  notes: Map<string, VoiceGraphic> = new Map();
  _data: ClefData;
  measureData: MeasureProps[];
  config: Config;
  clefSymbol?: Phaser.GameObjects.Image;
  timeSignatureGraphics?: Phaser.GameObjects.Image[] = [];

  constructor(
    scene: Scene,
    config: Config,
    data: ClefData,
    measureData: MeasureProps[],
    clefSymbol?: Phaser.GameObjects.Image,
    timeSignature?: Phaser.GameObjects.Image[]
  ) {
    super(scene);
    this.measureData = measureData;
    this.config = config;
    this._data = data;
    scene.add.existing(this);
    this.createLines();
    this.setPosition(
      0,
      data.clef === 'bass'
        ? config.pitchLineGap * 4 + config.staffSpacingVertical
        : 0
    );
    this.reset(data, measureData, clefSymbol, timeSignature);
  }

  checkAllNotes() {
    for (const note of this.notes) {
      if (
        note[1].timingState === undefined &&
        Object.keys(note[1]).contains('timestamp')
      ) {
        console.warn('undefined', note[1].timingState);
        return undefined;
      } else if (
        note[1].timingState !== ProgressState.Ended &&
        Object.keys(note[1]).contains('timestamp')
      ) {
        return false;
      }
    }
    return true;
  }

  destroy() {
    this.pitchLines.forEach((l) => l.destroy());
    this.pitchLines.clear();
    this.barWidths.clear();
    this.notes.forEach((note) => note.destroy());
    this.notes.clear();
    this.timeSignatureGraphics?.map((t) => t.destroy());
    this.timeSignatureGraphics?.clear();
    this.clefSymbol?.destroy();
  }

  reset(
    data: ClefData,
    measureData: MeasureProps[],
    clefSymbol?: Phaser.GameObjects.Image,
    timeSignature?: Phaser.GameObjects.Image[]
  ) {
    this._data = data;
    this.measureData = measureData;
    if (clefSymbol) {
      clefSymbol.setOrigin(0, 0.5);
      const yPos =
        this._data.clef === 'bass'
          ? this.pitchLines[1].y
          : this.pitchLines[3].y + 3 * 1;
      clefSymbol.setPosition(this.config.clefSymbolMargin + this.x, yPos);
      this.add(clefSymbol);
      if (timeSignature) {
        timeSignature.forEach((item, idx) => {
          item.setOrigin(0.5, 0.5);
          item.setPosition(
            clefSymbol.x +
            clefSymbol.displayWidth +
            this.config.clefSymbolMargin * 2,
            this.pitchLines[3 - 2 * (1 - idx)].y
          );
          this.add(item);
        });
        this.timeSignatureGraphics = timeSignature;
      }
    }
    this.createNotes(measureData);
  }

  getFirstNotePosition() {
    let minX: number | undefined = undefined;
    let minNote: VoiceGraphic | undefined = undefined;
    this.notes.forEach((note) => {
      const noteX = note.getPosition().x;
      if (!minX) {
        minX = noteX;
        minNote = note;
      } else {
        if (noteX < minX) {
          minX = Math.min(minX, noteX);
          minNote = note;
        }
      }
    });
    console.debug('min note', minNote, minX);
    return minX ?? Infinity;
  }

  getNotePosition(id: string) {
    /*if (!this.notes.get(id)) {
      console.error("no note with the given id: ", id);
    }*/
    const noteX = this.notes.get(id)?.getPosition().x;
    if (noteX) return noteX + this.x;
  }

  getNoteNumber(note: BaseNote) {
    switch (note) {
      case 'C':
      case 'C#':
        return 0;
      case 'D':
      case 'D#':
        return 1;
      case 'E':
        return 2;
      case 'F':
      case 'F#':
        return 3;
      case 'G':
      case 'G#':
        return 4;
      case 'A':
      case 'A#':
        return 5;
      case 'B':
        return 6;
      default:
        console.error(`invalid base note: ${note}`);
        return null;
    }
  }

  getNoteLineNumber([step, octave]: [BaseNote, number]) {
    const num = this.getNoteNumber(step);
    const oct = (this._data.octaveOffset - octave) * 7;
    const line = oct - num!;
    switch (this._data.clef) {
      case 'treble': {
        return line + 38;
      }
      case 'bass': {
        return line + 26;
      }
    }
  }

  createNotes(measureData: MeasureProps[]) {
    const { globalScaling } = this.config;
    console.debug('notes', this._data);
    this._data.notes
      .map(({ x, voice, length, id, timestamp, measureIdx }) => {
        const xPos =
          x * globalScaling +
          measureData[measureIdx].firstNoteRelativeOffset! +
          measureData[measureIdx].left!;
        if (voice === 'rest') {
          //return new RestGraphic(this.scene, id, x, 0, length);
          return null;
        } else {
          const line = this.getNoteLineNumber(voice.note);
          return new NoteGraphic(
            this.scene,
            id,
            this,
            xPos,
            line,
            length,
            this.config,
            timestamp
          );
        }
      })
      .forEach((val) => val && this.notes.set(val.id, val));
  }

  createLines() {
    const { pitchLineGap, phraseWidth, pitchLineWidth } = this.config;
    this.pitchLines = [0, 1, 2, 3, 4].map(
      (i) =>
        new GameObjects.Rectangle(
          this.scene,
          0,
          i * pitchLineGap,
          phraseWidth,
          pitchLineWidth,
          0x0,
          1
        )
    );
    this.pitchLines.forEach((line) => {
      this.add(line);
      line.setOrigin(0, 0);
    });
  }
}

/*export class PhraseGraphic extends GameObjects.Container {
  bassClef?: Clef;
  trebleClef?: Clef;
  config: Config;
  _data: PhraseData;
  cursorData: Types.PrerenderedPhraseData[];

  notes: Map<string, VoiceGraphic>;

  measureLines: GameObjects.Rectangle[] = [];
  totalWidth!: number;

  constructor(
    scene: Scene,
    x: number,
    config: Partial<Config>,
    data: PhraseData,
    cursorData: Types.PrerenderedPhraseData[]
  ) {
    super(scene);
    this._data = data;
    this.scene = scene;
    this.config = DefaultConfig(config);
    this.notes = new Map();
    this.reset(data, x);
    this.scene.add.existing(this);
    this.cursorData = cursorData;
  }

  checkAllNotes(): boolean {
    const bass = this.bassClef?.checkAllNotes();
    const treble = this.trebleClef?.checkAllNotes();
    if (bass === undefined || treble === undefined) {
      console.warn('some notes have not finished playing yet!');
    }
    return bass === true && treble === true;
  }

  destroy() {
    this.measureLines.forEach((l) => l.destroy());
    this.measureLines.clear();
    this.bassClef?.destroy();
    this.trebleClef?.destroy();
    this.notes.clear();
    super.destroy();
  }

  getNextPosition() {
    return this.x + this.getWidth();
  }

  getWidth() {
    return this.totalWidth;
  }

  getNoteById(id: string) {
    //dbg(["getting note: ", id]);
    //dbg(this.notes);
    return this.notes.get(id);
  }

  getNotePosition(id: string) {
    const noteX =
      this.trebleClef!.getNotePosition(id) ??
      this.bassClef!.getNotePosition(id);
    if (noteX) return noteX! + this.x;
  }

  getFirstNotePosition() {
    return (
      Math.min(
        this.trebleClef!.getFirstNotePosition(),
        this.bassClef!.getFirstNotePosition()
      ) + this.x
    );
  }

  getX() {
    return this.x;
  }

  getHeight() {
    let { pitchLineGap, staffSpacingVertical } = this.config;
    return pitchLineGap * 8 + staffSpacingVertical;
  }

  reset(data: PhraseData, xPos: number) {
    let { globalScaling, centreAlign, phraseWidth } = this.config;
    if (centreAlign) {
      this.x = this.scene.cameras.main.width / 2 - phraseWidth / 2;
      this.y = this.scene.cameras.main.height / 2 - this.getHeight() / 2;
    } else {
      this.x = xPos;
    }
    this._data = data;
    this.notes = new Map();
    let trebleClefSymbol;
    let bassClefSymbol;
    let timeSig;
    // Compute the new widths of every measure by taking into account
    // the configured padding values and other symbols that might take
    // up any space
    let acc = 0;
    data.measureData = data.measureData.map(({ width }, idx) => {
      let gap = this.config.firstNoteGap;
      // the first measure will include the clef symbols and time
      // signature (optional)
      if (idx === 0) {
        // The clef symbols are created here and then passed onto the Clef
        // object for proper organisation. They must be created here as the
        // overall positioning and size numbers depend on their size.
        // The x, y values for the position can be zero as they're not
        // important for computing the size. The placement is handled
        // by the parent Clef object instead.
        trebleClefSymbol = this.scene.add.image(0, 0, 'treble_clef');
        // The height of the clef symbol happens to be rather proportional
        // relative to the gap between the pitch lines. After some trial
        // and error, I have found the following ratio to work quite well
        // for the proportions. This might change if the actual asset file
        // for the clef symbols are modified so tread carefully.
        const clefGapRatio = 1 / 27;
        const clefScale = clefGapRatio * this.config.pitchLineGap;
        trebleClefSymbol.setScale(clefScale);
        trebleClefSymbol.setRotation(-0.05);
        bassClefSymbol = this.scene.add.image(0, 0, 'bass_clef');
        bassClefSymbol.setScale(clefScale);
        // It is important to draw both clef's since we'll be taking the
        // widest of the two to compute the necessary gap dynamically.
        const clefSymbolWidth = Math.max(
          trebleClefSymbol.displayWidth,
          bassClefSymbol.displayWidth
        );
        gap =
          clefSymbolWidth +
          this.config.firstNoteGap +
          this.config.clefSymbolMargin;
        // We have to repeat the process for the timeSignature symbols as well
        if (data.timeSignature) {
          const timeSigNumText = this.scene.add.image(
            0,
            0,
            `time_sig_${data.timeSignature.numerator}`
          );
          const timeSigDenText = this.scene.add.image(
            0,
            0,
            `time_sig_${data.timeSignature.denominator}`
          );
          // The time signature numbers should have heights that equal twice the gap between two pitch lines
          const sc1 =
            timeSigNumText.displayHeight / (2 * this.config.pitchLineGap);
          const sc2 =
            timeSigDenText.displayHeight / (2 * this.config.pitchLineGap);
          timeSigNumText.setScale(1 / sc1);
          timeSigDenText.setScale(1 / sc2);
          timeSig = [timeSigNumText, timeSigDenText];
          const w = Math.max(
            timeSigNumText.displayWidth,
            timeSigDenText.displayWidth
          );
          gap += w + this.config.clefSymbolMargin;
        }
      }
      const newWidth: number = width * globalScaling + gap;
      acc += newWidth;
      return {
        left: acc - newWidth,
        width: newWidth,
        firstNoteRelativeOffset: gap,
      };
    });
    const fullWidth = data.measureData
      .map(({ width }) => width)
      .reduce((prev, val) => prev + val, 0);
    this.config.phraseWidth = fullWidth;
    this.bassClef = new Clef(
      this.scene,
      this.config,
      data.bassClef,
      data.measureData,
      bassClefSymbol,
      timeSig
    );
    this.trebleClef = new Clef(
      this.scene,
      this.config,
      data.trebleClef,
      data.measureData,
      trebleClefSymbol,
      timeSig
    );
    this.bassClef.notes.forEach((note) => this.notes.set(note.id, note));
    this.trebleClef.notes.forEach((note) => this.notes.set(note.id, note));
    this.add(this.bassClef);
    this.add(this.trebleClef);
    const grandStaffHeight =
      this.config.pitchLineGap * 8 +
      this.config.pitchLineWidth * 2 +
      this.config.staffSpacingVertical;
    const strokeSize = this.config.measureLineWidth;
    let totalWidth = 0;
    const measureLines = data.measureData.map(({ left, width }) => {
      totalWidth += width;
      return this.scene.add.rectangle(
        left,
        0,
        strokeSize,
        grandStaffHeight,
        0x0,
        1
      );
    });
    measureLines.push(
      this.scene.add.rectangle(
        totalWidth,
        0,
        strokeSize,
        grandStaffHeight,
        0x0,
        1
      )
    );
    measureLines.forEach((line) => {
      line.setOrigin(0, 0);
      this.add(line);
    });
    this.measureLines = measureLines;
    this.totalWidth = totalWidth;
  }
}*/

/*interface PrerenderedPhraseData {
  currentVoiceEntries: PrerenderedVoiceEntry[];
  currentTimestamp: number;
  currentMeasureIndex: number;
}*/

export interface CursorData {
  measureRects: MeasureRect[];
  serializedIterator: PrerenderedPhraseData[];
}

interface MeasureProps {
  width: number;
  left: number;
  firstNoteRelativeOffset: number;
}

interface MeasureRect {
  x: number;
  width: number;
}

interface BaseData {
  bassClef: ClefData;
  trebleClef: ClefData;
  measureData: MeasureProps[];
  timeSignature?: TimeSignature;
  showBrace?: boolean;
  endMeasure?: boolean;
}

export class PhraseData implements BaseData {

  bassClef: ClefData;
  trebleClef: ClefData;
  measureData: MeasureProps[] = [];
  timeSignature?: TimeSignature;
  phraseIdx: number;
  serializedIterator: PrerenderedPhraseData[];
  startTimestamp: number;
  uuid: string;
  showBrace?: boolean;
  endMeasure?: boolean;

  constructor(
    phraseIdx: number,
    cursorData: CursorData,
    uuid: string,
    timeSignature?: TimeSignature,
    showBrace?: boolean,
    endMeasure?: boolean
  ) {
    this.uuid = uuid;
    this.startTimestamp = 0;
    this.timeSignature = timeSignature;
    this.phraseIdx = phraseIdx;
    this.serializedIterator = cursorData.serializedIterator;
    this.showBrace = showBrace;
    this.endMeasure = endMeasure;
    let totalWidth = 0;
    const noteAdjustmentValues: number[] = [];
    this.measureData = cursorData.measureRects.map((rect, indx) => {
      const left = totalWidth;
      const notes = cursorData.serializedIterator
        .filter(({ currentMeasureIndex }) => currentMeasureIndex === indx)
        .flatMap((slice) =>
          slice.currentVoiceEntries.flatMap(({ Notes }) =>
            Notes.flatMap((note) => note.graphics)
          )
        )
        .sort((a, b) => a.staveNote.left! - b.staveNote.left!);
      const firstNote = notes.at(0)!;
      const firstNoteRelativeOffset = firstNote.staveNote.left! - rect.x;
      const width = rect.width - firstNoteRelativeOffset;
      totalWidth += width;
      noteAdjustmentValues[indx] = firstNote.staveNote.left! - left;
      return {
        left,
        width,
        firstNoteRelativeOffset,
      };
    });
    // Since the notes in the serialized iterator are the actual x positions of the
    // elements in the source svg and therefore not adjusted to account for any gaps,
    // we remove the gaps and then transform and add them to the appropriate clef.
    const trebleNotes: VoiceData[] = [];
    const bassNotes: VoiceData[] = [];
    cursorData.serializedIterator.forEach(
      ({ currentTimestamp, currentVoiceEntries, currentMeasureIndex }) => {
        currentVoiceEntries.forEach(({ Notes }) =>
          Notes.forEach(
            ({
              isRest,
              graphics,
              pitch,
              staffPosition,
              length,
              printObject,
            }) => {
              if (!printObject) return;
              const x =
                graphics[0].staveNote.left! -
                (noteAdjustmentValues[currentMeasureIndex] +
                  this.measureData[currentMeasureIndex].left);
              const voice = {
                x,
                id: graphics[0].staveNote.id,
                timestamp: currentTimestamp,
                voice: isRest ? 'rest' : { note: noteFromOsmdPitch(pitch) },
                length,
                measureIdx: currentMeasureIndex,
                staffPosition,
              } as VoiceData;
              if (staffPosition === 'top') {
                trebleNotes.push(voice);
              } else {
                bassNotes.push(voice);
              }
            }
          )
        );
      }
    );
    this.trebleClef = {
      notes: trebleNotes,
      clef: 'treble',
      octaveOffset: 0,
    };
    this.bassClef = {
      notes: bassNotes,
      clef: 'bass',
      octaveOffset: 0,
    };
  }
}

class PhraseGraphicBase<DataType extends BaseData> extends GameObjects.Container {
  bassClef?: Clef;
  trebleClef?: Clef;
  config: Config;
  _data: DataType;

  notes: Map<string, VoiceGraphic> = new Map();

  measureLines: GameObjects.Rectangle[] = [];
  brace?: Phaser.GameObjects.Image;
  totalWidth!: number;

  constructor(
    scene: Scene,
    x: number,
    config: Partial<Config>,
    data: DataType,
  ) {
    super(scene);
    this._data = data;
    this.scene = scene;
    this.config = DefaultConfig(config);
    this.notes = new Map();
    this.reset(data, x);
    this.scene.add.existing(this);
  }

  checkAllNotes(): boolean {
    const bass = this.bassClef?.checkAllNotes();
    const treble = this.trebleClef?.checkAllNotes();
    if (bass === undefined || treble === undefined) {
      console.warn('some notes have not finished playing yet!');
    }
    return bass === true && treble === true;
  }

  destroy() {
    this.measureLines.forEach((l) => l.destroy());
    this.measureLines.clear();
    this.bassClef?.destroy();
    this.trebleClef?.destroy();
    this.notes.clear();
    super.destroy();
  }

  getNextPosition() {
    return this.x + this.getWidth();
  }

  getWidth() {
    return this.totalWidth;
  }

  getNoteById(id: string) {
    return this.notes.get(id);
  }

  getNotePosition(id: string) {
    const noteX =
      this.trebleClef!.getNotePosition(id) ??
      this.bassClef!.getNotePosition(id);
    if (noteX) return noteX! + this.x;
  }

  getFirstNotePosition() {
    return (
      Math.min(
        this.trebleClef!.getFirstNotePosition(),
        this.bassClef!.getFirstNotePosition()
      ) + this.x
    );
  }

  getX() {
    return this.x;
  }

  getHeight() {
    let { pitchLineGap, staffSpacingVertical } = this.config;
    return pitchLineGap * 8 + staffSpacingVertical;
  }

  reset(data: DataType, xPos: number) {
    let {
      globalScaling,
      centreAlign,
      phraseWidth,
    } = this.config;
    const height = this.getHeight();
    if (data.showBrace) {
      this.brace = this.scene.add.image(-5 * 1, height / 2, 'brace');
      const braceScale = this.brace.displayHeight / height;
      this.brace.setScale(1.02 / braceScale);
      this.brace.setOrigin(1, 0.5);
      this.brace.alpha = 1;
      this.add(this.brace);
    }
    this._data = data;
    this.notes = new Map();
    let trebleClefSymbol;
    let bassClefSymbol;
    let timeSig;
    // Compute the new widths of every measure by taking into account
    // the configured padding values and other symbols that might take
    // up any space
    let acc = 0;
    data.measureData = data.measureData.map(({ width }, idx) => {
      let gap = this.config.firstNoteGap;
      // the first measure will include the clef symbols and time
      // signature (optional)
      if (idx === 0) {
        // The clef symbols are created here and then passed onto the Clef
        // object for proper organisation. They must be created here as the
        // overall positioning and size numbers depend on their size.
        // The x, y values for the position can be zero as they're not
        // important for computing the size. The placement is handled
        // by the parent Clef object instead.
        trebleClefSymbol = this.scene.add.image(0, 0, 'treble_clef');
        // The height of the clef symbol happens to be rather proportional
        // relative to the gap between the pitch lines. After some trial
        // and error, I have found the following ratio to work quite well
        // for the proportions. This might change if the actual asset file
        // for the clef symbols are modified so tread carefully.
        const clefGapRatio = 1 / 27;
        const clefScale = clefGapRatio * this.config.pitchLineGap;
        trebleClefSymbol.setScale(clefScale);
        trebleClefSymbol.setRotation(-0.05);
        bassClefSymbol = this.scene.add.image(0, 0, 'bass_clef');
        bassClefSymbol.setScale(clefScale);
        // It is important to draw both clef's since we'll be taking the
        // widest of the two to compute the necessary gap dynamically.
        const clefSymbolWidth = Math.max(
          trebleClefSymbol.displayWidth,
          bassClefSymbol.displayWidth
        );
        gap =
          clefSymbolWidth +
          this.config.firstNoteGap +
          this.config.clefSymbolMargin;
        // We have to repeat the process for the timeSignature symbols as well
        if (data.timeSignature) {
          const sig = data.timeSignature;
          const timeSigNumText = [0, 1].map(_ => this.scene.add.image(
            0,
            0,
            `time_sig_${sig.numerator}`
          ));
          const timeSigDenText = [0, 1].map(_ => this.scene.add.image(
            0,
            0,
            `time_sig_${sig.denominator}`
          ));
          // The time signature numbers should have heights that equal twice the gap between two pitch lines
          const sc1 =
            timeSigNumText[0].displayHeight / (2 * this.config.pitchLineGap);
          const sc2 =
            timeSigDenText[0].displayHeight / (2 * this.config.pitchLineGap);
          timeSigNumText.forEach(text => text.setScale(1 / sc1));
          timeSigDenText.forEach(text => text.setScale(1 / sc2));
          timeSig = [[timeSigNumText[0], timeSigDenText[0]], [timeSigNumText[1], timeSigDenText[1]]];
          const w = Math.max(
            timeSigNumText[0].displayWidth,
            timeSigDenText[0].displayWidth
          );
          gap += w + this.config.clefSymbolMargin;
        }
      }
      const newWidth: number = width * globalScaling + gap;
      acc += newWidth;
      return {
        left: acc - newWidth,
        width: newWidth,
        firstNoteRelativeOffset: gap,
      };
    });
    const fullWidth = data.measureData
      .map(({ width }) => width)
      .reduce((prev, val) => prev + val, 0);
    this.config.phraseWidth = fullWidth;
    this.bassClef = new Clef(
      this.scene,
      this.config,
      data.bassClef,
      data.measureData,
      bassClefSymbol,
      timeSig ? timeSig[0] : undefined
    );
    this.trebleClef = new Clef(
      this.scene,
      this.config,
      data.trebleClef,
      data.measureData,
      trebleClefSymbol,
      timeSig ? timeSig[1] : undefined
    );
    this.bassClef.notes.forEach((note) => this.notes.set(note.id, note));
    this.trebleClef.notes.forEach((note) => this.notes.set(note.id, note));
    this.add(this.bassClef);
    this.add(this.trebleClef);
    const grandStaffHeight =
      this.config.pitchLineGap * 8 +
      this.config.pitchLineWidth * 2 +
      this.config.staffSpacingVertical;
    const strokeSize = this.config.measureLineWidth;
    let totalWidth = 0;
    this.measureLines = data.measureData.map(({ left, width }) => {
      totalWidth += width;
      return this.scene.add.rectangle(
        left,
        0,
        strokeSize,
        grandStaffHeight,
        0x0,
        1
      );
    });
    this.measureLines.push(
      this.scene.add.rectangle(
        totalWidth - (data.endMeasure ? 20 : 0),
        0,
        strokeSize,
        grandStaffHeight - this.config.pitchLineWidth,
        0x0,
        1
      )
    );
    if (data.endMeasure) {
      this.measureLines.push(
        this.scene.add.rectangle(
          totalWidth,
          0,
          strokeSize * 5,
          grandStaffHeight - this.config.pitchLineWidth,
          0x0,
          1
        )
      );
    }
    this.measureLines.forEach((line) => {
      line.setOrigin(0, 0);
      this.add(line);
    });
    this.totalWidth = totalWidth + (this.brace?.displayWidth ?? 0);
    if (centreAlign) {
      this.x = this.scene.cameras.main.width / 2 - this.totalWidth / 2;
      this.y = this.scene.cameras.main.height / 2 - this.getHeight() / 2;
    } else {
      this.x = xPos;
    }
  }
}

export class PhraseGraphic extends PhraseGraphicBase<PhraseData> { }

export class PhraseDataUntimed implements BaseData {
  bassClef: ClefData;
  trebleClef: ClefData;
  measureData: MeasureProps[];
  timeSignature?: TimeSignature;
  showBrace = true;
  endMeasure = true;

  constructor(bassClef: ClefData, trebleClef: ClefData, width: number) {
    this.bassClef = bassClef;
    this.trebleClef = trebleClef;
    this.measureData = [{
      firstNoteRelativeOffset: 0,
      width,
      left: 0
    }];
  }
}

export class PhraseGraphicUntimed extends PhraseGraphicBase<PhraseDataUntimed> {

  destroy() {
    this.bassClef?.destroy();
    this.trebleClef?.destroy();
    this.brace?.destroy();
    super.destroy();
  }

  getHeight() {
    let { pitchLineGap, staffSpacingVertical } = this.config;
    return (pitchLineGap * 8 + staffSpacingVertical) * this.scale;
  }

  getFirstNotePosition() {
    return (
      Math.min(
        this.trebleClef!.getFirstNotePosition(),
        this.bassClef!.getFirstNotePosition()
      ) + this.x
    );
  }

  getPhraseEnd() {
    return (this.measureLines.last().x * this.scale) + this.x;
  }

  getTotalWidth() {
    return this.config.phraseWidth;
  }
}
