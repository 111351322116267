import Phaser from "phaser";
import { colors } from "../config";
import RoundedRect from "./RoundedRect";
import TimeSignature from "../../Models/TimeSignature";
import { TimingOffsets, TimingOffsetsConfig } from "Models/EventStream";
import { ProgressState } from "./Progress";
import IPlayable from "./Playable";

export type StartTiming = "early" | "perfect" | "late";

export type StaffNote = {
  position: { x: number; y: number };
  durationInMs: number;
  // duration relative to a whole note, i.e, 1/4 for quarter notes,
  // 1/2 for half notes and so on
  durationRelative: number;
  playable?: IPlayable;
  clef?: "treble" | "bass";
};

export type NoteEvent = {
  note: StaffNote;
  timestamp: number;
  offsetTime: TimingOffsets;
  onsetTime: TimingOffsets;
};

export type NoteStream = StaffNote[];

/// Describes the cursor game object and all related logic for moving it around the track.
export default class Cursor extends RoundedRect {
  /// Handle to the tween that is spawned for moving the cursor across the screen.
  clickHandle: Phaser.Tweens.Tween | null = null;
  /// Metronome handle
  //clickHandle: Phaser.Time.TimerEvent | null = null;
  click: Phaser.Sound.BaseSound;
  exercise: Phaser.Scene;
  tempo: number;
  started: boolean = false;
  isMoving = false;

  nextTick: number = 0;
  lastTick: number = 0;

  msPerTick: number;
  timeSignature: TimeSignature;
  lastTimeUpdate: number = 0;
  lastNoteTime: number = 0;

  paused = false;
  wasPausedLastFrame = false;
  resumedThisFrame = false;
  cursorData: NoteStream;
  currentNoteIndex: number = -1;

  onDone?: () => void;
  onDoneCbScope: any;

  audioPaused: boolean = false;

  runTime: number = 0;

  offsetsConfig: TimingOffsetsConfig;
  notesRemaining: NoteEvent[] = [];
  notesPlaying: NoteEvent[] = [];
  notesPassed: NoteEvent[] = [];

  firstStart = false;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    width: number,
    height: number,
    radius: number,
    timeSig: TimeSignature,
    bpm: number,
    cursorData: NoteStream,
  ) {
    super(scene, x, y, width, height, radius, colors.cursor, 0);
    this.offsetsConfig = new TimingOffsetsConfig();
    this.exercise = scene;
    this.alpha = 0;
    this.tempo = bpm;
    //this.draw();
    this.click = this.scene.sound.add("click");
    this.msPerTick = (60 / this.tempo) * 1000;
    scene.sound.pauseOnBlur = true;
    this.cursorData = cursorData;
    this.timeSignature = timeSig;
    this.addNoteEvents(cursorData);
  }

  addNoteEvents(cursorData: NoteStream) {
    // the sequence of notes passed to cursor data are assumed
    // to arranged in increasing order of their timestamps
    let t = 0;
    const events = [];
    for (const note of cursorData) {
      const noteDuration = note.durationRelative;
      const onsetTimestamp = t;
      const offsetTimestamp = onsetTimestamp + note.durationRelative;
      const correctNoteOffsetStartTimestamp =
        onsetTimestamp + this.calculateCorrectNoteOffMin(noteDuration);
      const startAllowance = 0.4;
      const endAllowance = 0.25;
      const noteOnTimestamps = new TimingOffsets(
        onsetTimestamp === 0
          ? -0.2
          : onsetTimestamp -
          startAllowance * this.offsetsConfig.correctNoteOnsetStartPct,
        onsetTimestamp +
        endAllowance * this.offsetsConfig.correctNoteOnsetEndPct,
        onsetTimestamp === 0
          ? -0.2
          : onsetTimestamp -
          startAllowance * this.offsetsConfig.mistimedNoteOnsetStartPct,
        onsetTimestamp +
        endAllowance * this.offsetsConfig.mistimedNoteOnsetEndPct,
      );
      const noteOffTimestamps = new TimingOffsets(
        correctNoteOffsetStartTimestamp,
        offsetTimestamp +
        endAllowance * this.offsetsConfig.correctNoteOffsetEndPct,
        correctNoteOffsetStartTimestamp - noteDuration * 0.2,
        offsetTimestamp +
        endAllowance * this.offsetsConfig.mistimedNoteOffsetEndPct,
      );
      events.push({
        note,
        timestamp: onsetTimestamp,
        offsetTime: noteOffTimestamps,
        onsetTime: noteOnTimestamps,
      });
      t += note.durationRelative;
    }
    this.notesRemaining = events.sort(
      (a, b) =>
        a.onsetTime.mistimedStartTimestamp - b.onsetTime.mistimedStartTimestamp,
    );
  }

  pauseAudio() {
    this.audioPaused = true;
    if (this.click) {
      (this.click as any).setMute(true);
    }
  }

  resumeAudio() {
    this.audioPaused = false;
    (this.click as any).setMute(false);
  }

  /// Moves the cursor back to the starting position and stops movement
  resetHead() {
    this.currentNoteIndex = -1;
    this.isMoving = false;
    this.setX(this.cursorData[0].position.x);
    this.runTime = 0;
    this.draw();
  }

  pause(): void {
    this.wasPausedLastFrame = true;
    this.paused = true;
  }

  resume(): void {
    this.resumedThisFrame = true;
    this.paused = false;
  }

  /* Start moving the cursor. Calls `cb` once the cursor reaches the end. `cb` is bound
   * to the provided `cbScope` object.
   */
  tryStart(cb?: () => void, cbScope?: object): [StartTiming, number] {
    this.onDone = cb;
    this.onDoneCbScope = cbScope;
    const timeDiff = this.lastTimeUpdate + 16.6 - this.lastTick;
    const relTimeDiff = timeDiff / this.msPerTick;
    if (relTimeDiff > 0.2 && relTimeDiff < 0.9) {
      if (relTimeDiff > 0.8) return ["early", timeDiff];
      else return ["late", timeDiff];
    }
    this.lastNoteTime = this.lastTimeUpdate;
    this.resetHead();
    this.isMoving = true;
    this.runTime = 0;
    this.setAlpha(colors.cursorAlpha);
    this.next();
    return ["perfect", timeDiff];
  }

  /// Stops the cursor's movement. This does not reset the cursor back to the start.
  stop() {
    this.started = false;
    this.lastTick = 0;
    this.wasPausedLastFrame = false;
    this.resumedThisFrame = false;
  }

  tick(): void {
    if (
      !this.audioPaused
      //&& this.currentNoteIndex < this.cursorData.length - 2
    ) {
      this.click.play();
    }
    this.setAlpha(colors.cursorAlpha);
  }

  getRelativeTimestamp(): number {
    const durationPerMeasure = (60 / this.tempo) * 4;
    return this.runTime / 1000 / durationPerMeasure;
  }

  checkTiming(_data?: any): StartTiming {
    const relTimeDiff =
      (this.lastTimeUpdate + 16.6 - this.lastTick) / this.msPerTick;
    if (relTimeDiff > 0.15 && relTimeDiff < 0.85) {
      if (relTimeDiff > 0.6) return "early";
      else return "late";
    } else return "perfect";
  }

  onHoldNote(_data?: any) {
    if (!this.wasPausedLastFrame && this.notesRemaining.length > 0) {
      const nextNote = this.notesRemaining[0];
      const currentTimestamp = this.getRelativeTimestamp();
      let state: ProgressState | undefined = undefined;
      if (currentTimestamp < nextNote.onsetTime.mistimedStartTimestamp) {
        // the note was played before the yellow zone, so we mark it as a misplayed note
        state = ProgressState.Missed;
      } else if (currentTimestamp < nextNote.onsetTime.correctStartTimestamp) {
        // the note was played a bit early (in the yellow zone) so we mark
        // it as mistimed
        // LateStart is the same as early start (cause they're functionally the same,
        // atleast for now )
        state = ProgressState.LateStart;
      } else if (currentTimestamp <= nextNote.onsetTime.correctEndTimestamp) {
        // the note was played at the correct onset time (in the green zone), so we mark
        // it as a success
        state = ProgressState.TimelyStart;
      } else if (currentTimestamp <= nextNote.onsetTime.mistimedEndTimestamp) {
        // the note was played too late (in the yellow zone)
        // so we mark it as mistimed
        state = ProgressState.LateStart;
      } else {
      }
      // It is possible that the note is not in focus at the time of holding it (early onset), so we
      // have to focus it manually
      if (!nextNote.note.playable?.isFocused)
        nextNote.note.playable?.setFocus(true);
      if (state) nextNote.note.playable?.setTimingState(state);
      this.notesRemaining = this.notesRemaining.filter((x) => x !== nextNote);
      this.notesPlaying.push(nextNote);
    }
  }

  onReleaseNote(_data?: any) {
    if (!this.wasPausedLastFrame && this.notesPlaying.length > 0) {
      const currentTimestamp = this.getRelativeTimestamp();
      const currentNote = this.notesPlaying[0];
      const timingOffsets = currentNote.offsetTime;
      if (
        currentTimestamp >= timingOffsets.correctStartTimestamp &&
        currentTimestamp <= timingOffsets.correctEndTimestamp
      ) {
        // Note was released at the right time, mark sucess
        currentNote.note.playable?.setTimingState(ProgressState.Ended);
      } else {
        // Note was not released at the right time, mark mistimed
        currentNote.note.playable?.setTimingState(
          ProgressState.MistimedRelease,
        );
      }
      this.notesPassed.push(currentNote);
      this.notesPlaying = this.notesPlaying.filter((x) => x !== currentNote);
    }
  }

  lerp = (a: number, b: number, t: number): number => a * (1 - t) + b * t;

  clamp = (a: number, min: number, max: number): number => {
    if (a < min) return min;
    else if (a > max) return max;
    return a;
  };

  next() {
    if (this.currentNoteIndex === this.cursorData.length - 2) {
      this.resetHead();
      this.resetEvents();
      this.onDone?.call(this.onDoneCbScope);
    } else {
      this.currentNoteIndex++;
    }
  }

  // This function is absolute madness right now
  // I will refactor it later TM
  update(time: number): void {
    if (!this.started) return;
    let pauseDuration = 0;
    if (this.wasPausedLastFrame && this.resumedThisFrame) {
      pauseDuration = time - this.lastTimeUpdate;
      this.lastTick += pauseDuration;
      this.lastNoteTime += pauseDuration;
      this.wasPausedLastFrame = false;
      this.resumedThisFrame = false;
    }
    if (this.lastTick === 0) this.lastTick = time;
    const diff = time - this.lastTick;
    if (!this.paused && this.isMoving) {
      this.runTime += time - this.lastTimeUpdate - pauseDuration;
      const currentTimestamp = this.getRelativeTimestamp();
      const nextNote = this.notesRemaining[0];
      if (
        nextNote &&
        currentTimestamp > nextNote.timestamp &&
        !nextNote.note.playable?.isFocused
      )
        nextNote.note.playable?.setFocus(true);
      const remainingNotesDiscardList: NoteEvent[] = [];
      if (
        nextNote &&
        currentTimestamp > nextNote.onsetTime.mistimedEndTimestamp
      ) {
        // The user has missed the next note that needed to be played
        // TODO set note state to missed
        //this.notesRemaining.splice(0, 1);
        nextNote.note.playable?.setTimingState(ProgressState.Missed);
        remainingNotesDiscardList.push(nextNote);
        this.notesPassed.push(nextNote);
      } else {
        //this.exercise.debugLog(`endTimestamp: ${JSON.stringify(nextNote?.onsetTime || {}, null, 2)}}\ncurrentTime: ${currentTimestamp}`);
      }
      this.notesRemaining = this.notesRemaining.filter(
        (note) =>
          remainingNotesDiscardList.find((x) => note === x) == undefined,
      );
      const playingNotesDiscardList: NoteEvent[] = [];
      for (const note of this.notesPlaying) {
        const currentNote = note;
        if (currentTimestamp > currentNote.offsetTime.correctEndTimestamp) {
          // The user has held the currently playing note for too long,
          // mark it as mistimed
          // earlyrelease is functionally the same as late release
          currentNote.note.playable?.setTimingState(
            ProgressState.MistimedRelease,
          );
          this.notesPassed.push(currentNote);
          playingNotesDiscardList.push(currentNote);
        }
      }
      this.notesPlaying = this.notesPlaying.filter(
        (note) => playingNotesDiscardList.find((x) => note === x) == undefined,
      );
    }
    if (diff >= this.msPerTick) {
      this.tick();
      const delay = diff - this.msPerTick;
      this.lastTick = time - delay;
    }
    const prog = diff / this.msPerTick;
    if (!this.isMoving)
      this.setAlpha(this.lerp(colors.cursorAlpha, 0, this.clamp(prog, 0, 1)));
    this.lastTimeUpdate = time;
    if (this.isMoving) {
      const timeSinceLastNote = time - this.lastNoteTime;
      const target = this.cursorData[this.currentNoteIndex + 1];
      if (this.currentNoteIndex < 0) {
        throw new Error("current not index is 0");
      }
      const prev = this.cursorData[this.currentNoteIndex];
      const t = timeSinceLastNote / prev.durationInMs;
      const pos = this.lerp(
        prev.position.x,
        target.position.x,
        this.clamp(t, 0, 1),
      );
      this.cursorData[this.currentNoteIndex].playable?.update(
        pos + this.width / 2,
      );
      this.setX(pos);
      if (t >= 1) {
        this.lastNoteTime = time;
        this.next();
      }
    }
  }

  waitForInput(): void {
    if (this.firstStart)
      this.tick();
    this.started = true;
    this.firstStart = false;
  }

  resetEvents() {
    this.notesRemaining.clear();
    this.notesPassed.clear();
    this.notesPlaying.clear();
    this.addNoteEvents(this.cursorData);
  }

  /// Resets the cursor. Moves it all the way back to start. And clears all tweens in charge of moving the cursor.
  reset() {
    this.resetEvents();
    this.resetHead();
  }

  destroy(): void {
    this.cursorData.clear();
    this.isMoving = false;
    this.currentNoteIndex = -1;
    super.destroy();
  }

  isActive = (): boolean => this.started;

  // lifted from /Models/EventStream.ts
  private calculateCorrectNoteOffMin(noteDuration: number) {
    if (noteDuration <= 1.0 / 4.0) {
      return noteDuration * 0.5;
    }
    // If duration is between a quarter note and half note, use this block.
    else if (noteDuration > 1.0 / 4.0 && noteDuration < 2.0 / 4.0) {
      const nRange = 2.0 / 4.0 - 1.0 / 4.0;
      // Scale from 0.5 to 0.625 of the note, on a nearly linear curve
      let nScale = 1.0 - (2.0 / 4.0 - noteDuration) / nRange;
      // nScale is normalized from 0..1; putting that on a curve nScale^0.6
      // lets a noteDuration=1.5 have minimum offset of just under 1 beat.
      // Adjust the following line to adjust how "aggressive" scaling between 1 and 2 is.
      // Use graphtoy.com to visualize the curve you're adjusting.
      nScale = Math.pow(nScale, 0.6);
      return 1.0 / 8.0 + 0.1875 * nScale;
    } else {
      return noteDuration - 1.0 / 4.0 + 1.0 / 16.0;
    }
  }
}
