import { ChapterActionTypes, CompletionState, CompletionData } from 'Types/ChapterTypes';

export const loadCompletionState = (payload: CompletionState) => ({
  type: ChapterActionTypes.LOAD_COMPLETION_STATE,
  payload
});

export const markChapterAsCompleted = (payload: CompletionData) => ({
  type: ChapterActionTypes.MARK_CHAPTER_COMPLETE,
  payload
});

export const setLastChapter = (payload: CompletionData) => ({
  type: ChapterActionTypes.SET_LAST_CHAPTER,
  payload
});
