import React, { useCallback } from 'react';
import { MIDIVal, MIDIValInput, NoteMessage,IMIDIAccess, IMIDIInput } from '@midival/core';
import { midiPitchToFrequency } from 'Utils';
import { useEffect, useState, useRef } from 'react';
import * as eventAction from 'Actions/events';
import * as Tone from 'tone';
import { UseStateError } from 'Utils/Errors';
import { useSelector, useDispatch } from 'react-redux';
import { MainAppReducer } from 'Types';
import { AuthReducer } from 'Types/AuthTypes';
import { awsRum } from 'Utils/AwsRum';


/// Change the input on and off events to be react callbacks
// so that a change in state playToneSound will be reflected
// Will need to add methods to change playToneSound similar to
// functions defined in Authentication.tsx



export type MidiProviderProps = {
  children?: React.ReactNode;
  location?: any
  navigate?: any,
  sampler?: any
};

export type MidiStateType = {};

export type MidiFunctions = {
  // initializeMidiInput: () => void,
  addListener: (listener: Listener) => void
  removeListener: (listener: Listener) => void
  clearListeners: () => void
  isConnected: () => boolean
}

export type Listener = {
  name: string,
  noteOn: (ev: NoteMessage)=>void
  noteOff: (ev: NoteMessage)=>void
}

export const MidiContext = React.createContext<(MidiStateType & MidiFunctions) | null>(null);

export const useMidiContext: () => MidiStateType & MidiFunctions = () => {
  const state = React.useContext(MidiContext);
  if (!state) {
    throw new UseStateError(useMidiContext, MidiProvider);
  }
  return {
    ...state
  };
};

type midiVapMap = {[key: string]: MIDIValInput}

export const MidiProvider: React.FC<MidiProviderProps> = ({
  children, location, navigate, sampler
}: MidiProviderProps) => {

  const [midiInitialized, setMidiInitialized] = useState(false)

  const [devices, setDevices] = useState<midiVapMap>({})

  const [midiAccess, setMidiAccess] = useState<IMIDIAccess>()
  const data = useSelector((state: MainAppReducer) => state.mainAppReducer)
  const auth = useSelector((state: AuthReducer) => state.authReducer)
  const listeners = React.useRef<Map<string,Listener>>(new Map());
  const dispatch = useDispatch()
  const midiValInputs = React.useRef<MIDIValInput[]>([])
  const [successEventSent, setSuccessEventSent] = React.useState(false);
  const restrict_midi = process.env.REACT_APP_REQUIRE_MIDI === 'true'
  const previousLocation = sessionStorage.getItem('previousLocation') || ''
  const [ initialized, setInitialized] = useState(false)

  const userData = data.userData
  // To determine nav back & later option
  let inWelcomeFlow = !userData?.subscription_status

  const navConnect = useCallback(()=> {
    console.log("nav connect", location.pathname)
    if( restrict_midi && location.pathname == '/lesson' || location.pathname == '/repertoire-play' || location.pathname == '/tutorial') {
      dispatch(eventAction.midiConnectionFailureEventAction())
      console.log("navving connect midi")
      navigate(`/connect-midi`)
    }
  }, [location.pathname, dispatch, navigate, restrict_midi])


  useEffect(()=>{
      // if(midiAccess) {
      if(!initialized) {
        try{
          console.log("trying connect")
          MIDIVal.connect().then(midiAccess => {
            const connectMap: any = {}
            console.log("in connect")
            const connectDevice = (access: IMIDIAccess, input: IMIDIInput) => {
              if(!Object.keys(connectMap).contains(input.id)) {
                console.log("ADDING DEVICE", input)
                const newInput = new MIDIValInput(input);
                (newInput as any).onAllNoteOn((ev: NoteMessage) => noteOn(ev));
                (newInput as any).onAllNoteOff((ev: NoteMessage) => noteOff(ev))
                connectMap[input.id] = newInput
                // console.
                setDevices({
                  ...devices,
                  [input.id]: newInput
                })
                if(location.pathname === '/connect-midi'){
                  navForward()
                }
              }
            }
            midiAccess.inputs.forEach(input => connectDevice(midiAccess, input));
            MIDIVal?.onInputDeviceConnected((device) => {
              console.log("===========DEVICE CONNECT===============")
              connectDevice(midiAccess, device)
              if(location.pathname === '/connect-midi'){
                navForward()
              }
              dispatch(eventAction.midiConnectionSuccessEventAction())
            }, false)
            MIDIVal?.onInputDeviceDisconnected((device) => {
              console.log("===========DEVICE DISCONNECT===============")
              if(connectMap[device.id]) {
                connectMap[device.id]?.disconnect()
              }
              delete devices[device.id]
              delete connectMap[device.id]
              setDevices({
                ...devices,
              })
              console.log("devices?", devices)
              if(Object.keys(devices).length < 1) {
                console.log("location.pathname?", location.pathname)
                navConnect()
              }
            });
            if(!midiAccess?.inputs?.length || midiAccess?.inputs?.length < 1) {
              navConnect()
            }
          })
          .catch((e)=>{
            console.error(e)
            navConnect()
          })
        } catch(e){
          console.error(e)
          console.log("connect failed")
          navConnect()
        } finally {
          setInitialized(true)
        }
      }
     
       
       
      // }
  },[location.pathname, initialized])

  useEffect(()=>{
    console.log("1234", devices, initialized )
    if(Object.keys(devices).length < 1 && initialized) {
      navConnect()
    }
  },[devices, navConnect])

  const navForward = () => {
    console.log("previousLocation", previousLocation)
    if (inWelcomeFlow || previousLocation == '/welcome') { // for trial welcome flow!
      navigate(`/trial-welcome`)
    } else if(previousLocation == '/roadmap') {
      // doesn't look like the unmount hook is firing fast enough on the tutorial page so,
      // if the last page is roadmap, go to tutorial. This only makes sense if the roadmap page itself
      // is not a midi connect redirect page.
      navigate('/roadmap')
    } else if(previousLocation === '') {
      navigate('/roadmap')
    } else if(previousLocation == '/repertoire-play') {
      navigate('/repertoire-play')
    } else if(previousLocation == '/repertoire') {
      navigate('/repertoire-play')
    } else {
      navigate(previousLocation)
    }
  }

  const handleBack = () => {
    console.log("previousLocation", previousLocation)
    if (inWelcomeFlow || previousLocation == '/welcome') {
      navigate(`/welcome`)
    } else if(previousLocation == '/tutorial') {
      navigate(`/roadmap`)
    } else if (previousLocation == '/lesson' || previousLocation == '/introduction') {
      navigate('/tutorial')
    } else if(previousLocation == '/repertoire-play') {
      navigate('/repertoire')
    } else if(previousLocation == '/repertoire') {
      navigate('/repertoire')
    }else { // otherwise just navigate back (not coming from redirect)
      navigate(-1);
    }
  }

  // useEffect(() => {
  //   if((Object.keys(devices).length < 1) && initialized && restrict_midi) {
  //     console.log("devices",devices)
  //     if(location.pathname == '/lesson' || location.pathname == '/repertoire-play' || location.pathname == '/tutorial') {
  //       dispatch(eventAction.midiConnectionFailureEventAction())
  //       console.log("navving connect midi")
  //       navigate(`/connect-midi`)
  //     }
  //   } else if(location.pathname === '/connect-midi'){
  //     navForward()
  //   }
  // }, [initialized, devices])
  
  useEffect(() => {
      setMidiInitialized(false)
  }, [data?.userData?.audio_on])

  useEffect(()=> {
    if(midiInitialized == true && !successEventSent) {
      dispatch(eventAction.midiConnectionSuccessEventAction())
      setSuccessEventSent(true)
    }
  }, [midiInitialized, successEventSent])


  const addListener = React.useCallback((listener: Listener) => {
    console.log("adding listener",listener)
    if(listeners.current.has(listener.name)) {
    }
    listeners.current.delete(listener.name);
    listeners.current.set(listener.name, listener);
  }, [listeners])

  const removeListener = React.useCallback( (listener: Listener) => {
    listeners.current.delete(listener.name);
  }, [listeners])
  const noteOff = React.useCallback((ev: NoteMessage) => {
    if (data?.userData?.audio_on) {
      sampler.triggerRelease(midiPitchToFrequency(ev.note))
    }
    for (let [key, listener] of listeners.current) {
      listener.noteOff(ev)
    }
  }, [sampler,data?.userData?.audio_on, listeners])

  const noteOn = React.useCallback((ev: NoteMessage) => {
    // sometimes midi keyboards will send note-on event with 0 velocity
      // we must treat this like a not off event instead
      // 254 is "active sensing" and can be ignored 
      if (ev.velocity !== 0 && ev.note !== 254) {
        const maxVolume = 1.0
        // console.log("data?.userData?.audio_on is : " + data?.userData?.audio_on)
        if (data?.userData?.audio_on) {
          sampler.triggerAttack(midiPitchToFrequency(ev.note), Tone.immediate(), ev.velocity / 128 * maxVolume)
        }
        for (let [key, listener] of listeners.current) {
          listener.noteOn(ev)
        }
      } else if(ev.note !== 254){
        noteOff(ev) // call the note off logic becasue velocity is 0.
      }
  }, [data?.userData?.audio_on, sampler, noteOff, listeners])

  const initializeMidiInput = React.useCallback(async (hard = false) => {
    let access;
    try {
      if (!midiAccess) {
        access = await MIDIVal.connect()
        console.log("access found")
        console.log(access)
        setMidiAccess(access)
      } 
    } catch(e) {
      console.error(e)
    }

  }, [setMidiInitialized, midiAccess, midiInitialized, midiValInputs.current, noteOff, noteOn, sampler, location, data?.userData?.audio_on])

  const setup = useCallback(()=> {

    // initializeMidiInput()
    // .finally(()=>setMidiInitialized(true))
    // .catch(console.error)
  },[setMidiInitialized, initializeMidiInput])


  useEffect(()=>{
    setup()
  }, [])


  const clearListeners = React.useCallback(() => {
    console.log("clearing listeners")
    for (let [key] of listeners.current) {
      listeners.current.delete(key)
    }
  }, [listeners.current])

  const isConnected = React.useCallback(()=>{
    return midiInitialized
  }, [midiInitialized])

  return (
    <MidiContext.Provider 
      value={{
        addListener,
        removeListener,
        clearListeners,
        isConnected
      }}
    >
        {children}
    </MidiContext.Provider>
  );

}


