import { Box } from "@mui/system";
import { useCallback, useEffect } from "react";
import * as eventActions from 'Actions/events';
import * as lessonActions from 'Actions/lesson';
import { useDispatch, useSelector } from "react-redux";
import { LessonReducer } from "Reducers/lessonReducer";
import { useNavigate } from "react-router-dom";
import { CustomCircularProgress } from "Components/StyledComponents";



const ConfirmBack = (props: {goBackToLesson: ()=>void}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { 
    tierSummaryRequestError,
    lessonProcessPending
  } = useSelector((state: LessonReducer) => state.lessonReducer)
  

  const endSession = useCallback(()=>{
      dispatch(lessonActions.setLessonProcessPending(true))
      dispatch(eventActions.dispatchEvents())
      setTimeout(()=>{
        dispatch(lessonActions.initiateWrapUp())
      }, 500)
  },[])

  useEffect(()=>{
    if(tierSummaryRequestError) {
      navigate('/roadmap')
    }
  },[tierSummaryRequestError])
  return (
    <>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems:'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
         { lessonProcessPending && 
          <Box
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <CustomCircularProgress />
          </Box>}

        { !lessonProcessPending && 
          (
            <>
              <Box
            sx={{
              fontSize: '24px',
              padding: '20px',
              color: '#fff'
            }}
          >
          Finished playing?

          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems:'center',
              justifyContent: 'center',
              flexDirection: 'row',
            }}
          >
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '8px 8px',
                gap: '8px',
                width: '220px',
                height: '28px',
                background: '#CA7C62',
                borderRadius: '40px',
                margin: "10px",
                '&:hover': {
                  cursor: 'pointer'
                },
              }}
              onClick={endSession}
              >
                <Box sx={{
                  fontStyle: 'normal',
                  fontWeight: '600',
                  fontSize: '14px',
                  lineHeight: '19px',
                  textAlign: 'center',
                  letterSpacing: '2.5px',
                  textTransform: 'uppercase',
                  color: '#050B13',
                  width: 'auto',
                  padding: '16px',
                }}>
                  YES, TAKE ME BACK
                </Box>
            </Box>


            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '8px 8px',
                gap: '8px',
                width: '220px',
                height: '28px',
                background: '#CA7C62',
                borderRadius: '40px',
                margin: "10px",
                '&:hover': {
                  cursor: 'pointer'
                },
              }}
              onClick={props.goBackToLesson}
              >
                <Box sx={{
                  fontStyle: 'normal',
                  fontWeight: '600',
                  fontSize: '14px',
                  lineHeight: '19px',
                  textAlign: 'center',
                  letterSpacing: '2.5px',
                  textTransform: 'uppercase',
                  color: '#050B13',
                  width: 'auto',
                  padding: '16px'
                }}>
                  NO, KEEP PLAYING
                </Box>

            </Box>
            </Box>

            </>
          )
        }
       
      </Box>

    </>
  )

}
export default ConfirmBack
