import RoundedRect from 'Phaser/GameObjects/RoundedRect';
import Phaser from 'phaser';
import { colors } from 'Phaser/config';
import {
  ListenForReactEvent,
  ListenPauseExercise,
  ListenResumeExercise,
  NotifyPhaser,
  PulseNote,
  ReactToExerciseEventType,
  RemoveListener,
  SetNoteState,
} from 'Phaser/EventBus';
import {
  MidiEventState,
  PrerenderedGraphics,
  PrerenderedPhraseData,
} from 'Types';
import EventStream, {
  NoteEvent, RestEvent,
} from 'Models/EventStream';
import { INoteGraphicsDatabase } from 'Phaser/Scenes/StaffExercise';
import Phrase, { Iterator } from 'Models/Phrase';
import { NoteType } from 'opensheetmusicdisplay';
import { ProgressState } from './Progress';

const getPhraseDuration = (cursorData: PrerenderedPhraseData[]): number => {
  if (cursorData.length === 0) {
    return 0;
  }
  let last: PrerenderedPhraseData = cursorData[0];
  for (const slice of cursorData) {
    if (last === null || slice.currentTimestamp > last.currentTimestamp) {
      last = slice;
    }
  }
  const lastOffset = Math.max(
    ...last!.currentVoiceEntries.map(({ Notes }) =>
      Math.max(...Notes.map(({ length }) => length))
    )
  );
  return last!.currentTimestamp + lastOffset;
};

// This will not work when used outside a phaser context
export class IteratorExtensible extends Iterator {
  totalLength: number;
  scene: INoteGraphicsDatabase;

  constructor(
    scene: INoteGraphicsDatabase,
    cursorData: PrerenderedPhraseData[],
    phrase: Phrase
  ) {
    super(cursorData, phrase);
    this.scene = scene;
    this.totalLength = getPhraseDuration(cursorData);
  }

  addPhrase(cursorData: PrerenderedPhraseData[]) {
    this.cursorData.push(
      ...cursorData
        .map((item) => ({
          ...item,
          currentVoiceEntries: item.currentVoiceEntries.filter(
            (entry) => entry.Notes.filter((note) => note.printObject).length > 0
          ),
          currentTimestamp: item.currentTimestamp + this.totalLength,
        }))
        .filter(({ currentVoiceEntries }) => currentVoiceEntries.length > 0)
    );
    this.totalLength += getPhraseDuration(cursorData);
    this.endReached = false;
    console.log(this.cursorData.length);
  }

  getPhraseEndPosition(): number | undefined {
    return this.scene.getPhraseEndPosition();
  }

  getGraphicsLeft(currentNoteId: string): number | undefined {
    return this.scene.getNotePosition(currentNoteId) ?? undefined;
  }

  totalDuration() {
    return this.totalLength;
  }

  left(): number {
    try {
      if (this.EndReached) {
        return this.getPhraseEndPosition()!;
      } else {
        const positions = this.CurrentVoiceEntries.flatMap((entry) =>
          entry.Notes.map(
            (note) => this.getGraphicsLeft(note.graphics[0].staveNote.id)!
          )
        );
        return Math.min(...positions);
      }
    } catch (error) {
      console.error(
        'Error when getting the x-position of the next note to play.',
        error
      );
      return 0;
    }
  }

  getNextTimestamp(): number {
    if (this.EndReached) {
      return this.totalLength;
    } else return this.currentTimeStamp;
  }

  next() {
    if (this.indx >= this.cursorData.length) {
      console.log('length:', this.cursorData.length, this.indx);
    }
    super.next();
    console.log('length', this.indx, this.cursorData.length, this.EndReached);
  }
}

export class EventStreamPhaser2 extends EventStream {

  pulseMatchedNote(note: NoteEvent, _colorClass: string) {
    NotifyPhaser(PulseNote(note.graphics[0].staveNote.id));
  }

  makeNoteSemiError = (graphics: PrerenderedGraphics[]) => {
    for (const graphic of graphics) {
      NotifyPhaser(SetNoteState(graphic.staveNote.id, ProgressState.LateStart));
    }
  }

  makeNoteCorrect = (graphics: PrerenderedGraphics[]) => {
    for (const graphic of graphics) {
      NotifyPhaser(SetNoteState(graphic.staveNote.id, ProgressState.Ended));
    }
  }

  makeNoteError = (graphics: PrerenderedGraphics[]) => {
    for (const graphic of graphics) {
      NotifyPhaser(SetNoteState(graphic.staveNote.id, ProgressState.Missed));
    }
  }
}

const lerp = (a: number, b: number, t: number) => a + (b - a) * t;

const cursorPaddingLeft = 200 * 1;

export default class CursorMobile extends RoundedRect {
  private lastExpectedOnset: number = -1;
  private relPos: number = 0;
  private lastNotePos: number = 0;
  private midiStream: EventStreamPhaser2;
  private tempo: number = 0;
  private iterator: IteratorExtensible;
  private cameraControl: boolean = false;

  private running: boolean = false;

  private blinkTween?: Phaser.Tweens.Tween;
  private origAlpha: number;

  private onFinishPlaying?: () => void;
  private onFinishCallbackScope?: any;

  constructor(
    scene: INoteGraphicsDatabase & Phaser.Scene,
    x: number,
    y: number,
    width: number,
    height: number,
    midiStream: EventStreamPhaser2,
    iterator: IteratorExtensible,
    cameraControl?: boolean,
    onFinishPlaying?: () => void,
    cbScope?: any
  ) {
    super(
      scene,
      x,
      y,
      width,
      height,
      width / 2,
      colors.cursor,
      colors.cursorAlpha
    );
    this.lastNotePos = (x - width / 2);
    this.origAlpha = colors.cursorAlpha;
    this.iterator = iterator;
    this.midiStream = midiStream;
    this.tempo = midiStream.timeKeeper.getBpm();
    this.scene = scene;
    this.onFinishPlaying = onFinishPlaying;
    this.onFinishCallbackScope = cbScope;
    // The code for this cursor will only be used in a "Lesson" context
    // and will therefore not have an "Exercise" associated with it.
    // Hence, we handle all pause / resume related events directly in
    // the context of the cursor itself as it is the only scope where
    // gameplay related logic is implemented within the parent phaser
    // scene.
    this.cameraControl = cameraControl ?? false;
    if (this.cameraControl) {
      this.scene.cameras.main.scrollX = -cursorPaddingLeft;
      this.scene.cameras.main.scrollY = -300;
    }
    ListenForReactEvent(ListenPauseExercise(this.pause), this);
    ListenForReactEvent(ListenResumeExercise(this.unpause), this);
    this.setX(x - width / 2);
  }

  calcPos() {
    const nextXPosition = this.iterator.left() - (this.width / 2);
    const nextTimestamp = this.iterator.getNextTimestamp();
    const timeDiff = nextTimestamp - this.lastExpectedOnset;
    const currentTimestamp =
      this.midiStream.timeKeeper.audioTimeToMeasureTimestamp();
    const prog = currentTimestamp - this.lastExpectedOnset;
    const t = prog / timeDiff;
    return lerp(this.lastNotePos, nextXPosition, t);
  }

  update() {
    let currentTimestamp =
      this.midiStream.timeKeeper.audioTimeToMeasureTimestamp();
    (this.scene as any).debugLog(`timestamp: ${currentTimestamp}`);
    if (currentTimestamp >= this.iterator.totalDuration()) {
      this.running = false;
      this.onFinishPlaying?.call(this.onFinishCallbackScope);
      return;
    }
    if (
      !this.running ||
      this.midiStream.timeKeeper.isPaused() ||
      this.midiStream.timeKeeper.audioTimeToMeasureTimestamp() >=
      this.iterator.totalDuration()
    )
      return;
    const bpm = this.midiStream.timeKeeper.getBpm();
    if (this.tempo !== bpm) {
      this.tempo = bpm;
    }
    this.calcPos();
    this.relPos = this.calcPos();
    this.moveTo(this.relPos);
    // Move on to the next slice when we've reached our target
    //if (this.relPos >= this.iterator.left()) {
    const comp = this.iterator.EndReached
      ? this.iterator.totalDuration()
      : this.iterator.currentTimeStamp;
    if (currentTimestamp >= comp) {
      console.log('reached ', currentTimestamp, this.iterator.currentTimeStamp);
      this.lastExpectedOnset = this.iterator.currentTimeStamp;
      this.lastNotePos = this.relPos;
      this.iterator.next();
    }
  }

  moveTo(pos: number) {
    //console.log(pos);
    //this.scene.cameras.main.setPosition(-pos, this.scene.cameras.main.y);
    if (this.cameraControl)
      this.scene.cameras.main.scrollX = pos - cursorPaddingLeft;
    this.setX(pos - this.width / 2);
  }

  addPhrase(cursorData: PrerenderedPhraseData[]) {
    this.iterator.addPhrase(cursorData);
  }

  unpause() {
    this.clearBlink();
    this.calcPos();
    this.running = true;
  }

  pause() {
    this.running = false;
  }

  blink(duration: number) {
    this.clearBlink();
    this.blinkTween = this.scene.tweens.add({
      targets: this,
      alpha: 0,
      duration,
      onUpdate: this.draw,
      callbackScope: this,
    });
  }

  clearBlink() {
    this.blinkTween?.stop();
    this.setAlpha(this.origAlpha);
  }

  destroy(removeListeners?: boolean) {
    super.destroy();
    if (removeListeners) {
      RemoveListener(ReactToExerciseEventType.PauseExercise);
      RemoveListener(ReactToExerciseEventType.ResumeExercise);
    }
  }

  tryStart() { }

  waitForInput(tempo: number) {
    this.midiStream.timeKeeper.unpause(tempo, 0, 0.1, NoteType._32nd);
    this.running = true;
  }
}
