import { Box } from '@mui/material';
import pianoBackground from '../../assets/images/pianoBackground.png';
import KeyboardConnectMidi from '../../assets/images/KeyboardConnectMidi.png';
import Logo from 'Components/Logo'

const MaintenancePage = () => {
  

  return (
    <Box sx={{
      backgroundImage: `url(${pianoBackground})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      height: '100vh',
    }}>
    <Box sx={{
      width: '100%',
      height: '100%',
      backdropFilter: 'brightness(15%)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
    <Box sx={{position: 'absolute', animation: 'error-page-rotation 6s infinite linear'}}>
      <img style={{maxWidth: '1000px', width: '75vw', opacity: 0.3}} src={KeyboardConnectMidi} alt="error-page-rotation"></img>
    </Box>
    <Box sx={{
        backgroundColor: "black",
        //width: 300,
        maxWidth: '80%',
        maxHeight: '80%',
        //maxWidth: '80%',
        borderRadius: '12px',
        marginTop: '20px',
        //margin: '100px auto 10px, 10px',
        paddingBottom: '50px',
        
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        zIndex: 10,
    }}>
      <Logo/>
      <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Box sx={{
          ////fontFamily: 'Lato',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '34px',
          letterSpacing: '0.01em',
          color: '#CED0D4',
          padding: '80px 120px 40px 120px',
        }}>
          Down for maintenance, be back soon!
        </Box>
      </Box>
    </Box>
    </Box>
    </Box>      
  )
}

export default MaintenancePage;
