import { ReactToExerciseEvent } from "Phaser/EventBus";
import * as PianoScene from "Phaser/Scenes/Piano";
import * as NoteSequenceExercise from "Phaser/Scenes/NoteSequenceExercise";
import * as ExerciseTempoPractice from "Phaser/Scenes/ExerciseTempoPractice";
import { PrerenderedPhraseData } from "Types";
import * as NoTimeExercise from "Phaser/Scenes/NoTime";
import * as StaffExercise from "Phaser/Scenes/StaffExercise";

export type Timestamp = `${number}:${number}`;

export interface GrandStaff {
  svg: string;
  id: string;
  x: number;
  y: number;
}

export interface StaffNote {
  timestamp: number;
  length: number;
  id: string;
  svg: string;
  x: number;
  y: number;
  isRest: boolean;
}

//type StaffPosition = 'top' | 'bottom';

/*interface StaveNote {
  id: string;
}

interface Pitch {
  accidental: number;
  octave: number;
  fundamentalNote: number;
  frequency: number;
  halfTone: number;
}

interface NoteInner {
  length: number;
  isRest: boolean;
  pitch?: Pitch;
  graphics: { staveNote: StaveNote }[];
  staffPosition: StaffPosition;
}

interface VoiceEntry {
  Notes: NoteInner[];
}

interface Note {
  currentTimestamp: number;
  currentVoiceEntries: VoiceEntry[];
  currentMeasureIndex: number;
}*/

export interface PhraseGraphicData {
  uuid: string;
  notes: PrerenderedPhraseData[];
}

export enum ChapterType {
  Lecture = "Lecture",
  Exercise = "Exercise",
}

export enum ExerciseScene {
  PianoScene = "PianoScene",
  NoteSequenceExercise = "NoteSequenceExercise",
  ExerciseTempoPractice = "ExerciseTempoPractice",
  StaffExercise = "StaffExercise",
  NoTimeExercise = "NoTimeExercise",
}

export type ExerciseSceneConfig =
  | [ExerciseScene.PianoScene, PianoScene.Config]
  | [ExerciseScene.NoteSequenceExercise, NoteSequenceExercise.Config]
  | [ExerciseScene.ExerciseTempoPractice, ExerciseTempoPractice.Config]
  | [ExerciseScene.StaffExercise, StaffExercise.Config]
  | [ExerciseScene.NoTimeExercise, NoTimeExercise.Config];

export interface ExerciseData {
  scene: ExerciseSceneConfig;
  events: InteractiveEvent[];
}

export interface ChapterData {
  title: string;
  start: Timestamp;
  id?: number;
  data: [ChapterType.Exercise, ExerciseData] | [ChapterType.Lecture];
}

export type ChapterSection = {
  chapters: ChapterData[];
};

export interface InteractiveEvent {
  at: Timestamp;
  pause?: boolean;
  phaserEvent: ReactToExerciseEvent;
}

export default interface Tutorial {
  /// The timestamp at which the button to go to the actual level should be shown
  end: Timestamp;
  /// Level number
  level: number;
  /// List of chapters in this tutorial
  sections: ChapterSection[];
  videoUrl: string;
}
