import { UserLevelData } from "Types";
import { LessonActions, PlaySessionData, WrapUpModalPage, lessonActionTypes } from "Types/LessonTypes"
import { MIN_NUM_PHRASES } from "Utils/Constants";
import immutable from "immutable";
import { uuid } from "uuidv4";

export interface LessonReducer {
  lessonReducer: LessonState,
}


export type LessonState = {
  levelNumber: number | null,
  tierNumber: number,
  numberOfTiers: number | null,
  tierSummary: PlaySessionData | null,
  showWrapUp: boolean
  wrapUpIndex: number,
  lessonProcessPending: boolean,
  wrapUpPage: WrapUpModalPage | null,
  allowChevrons: boolean,
  currentUserLevelData: UserLevelData | null,
  resetLessonUuid: string | null,
  currentLevelTiers: number,
  lessonPlaying: boolean,
  phrasesTilNextTier: number,
  currentLevelCompletedAt: Date | null,
  tierSummaryRequestError: boolean
  // we need a local play session id
  // localPlaySessionId: string,
}

export const defaultState: LessonState = {
  levelNumber: null,
  tierNumber: 1,
  numberOfTiers: null,
  tierSummary: null,
  showWrapUp: false,
  wrapUpIndex: 0,
  lessonProcessPending: false,
  wrapUpPage: null,
  allowChevrons: false,
  currentUserLevelData: null,
  lessonPlaying: false,
  resetLessonUuid: null,
  phrasesTilNextTier: MIN_NUM_PHRASES,
  currentLevelTiers: 1,
  currentLevelCompletedAt: null, // used to determine whether a level has been completed for the first
                                 // time when compared to the db value in currentUserLevelData 
  tierSummaryRequestError: false
}

export const lessonReducer = (state = defaultState, action: LessonActions): LessonState => {
  switch (action.type) {

    case lessonActionTypes.SET_LEVEL_NUMBER:
      return immutable.merge(state, {
        levelNumber: action.payload,
      });

    case lessonActionTypes.SET_TIER_NUMBER:
      return immutable.merge(state, {
        tierNumber: action.payload,
      });

    case lessonActionTypes.SET_NUMBER_OF_TIERS:
      return immutable.merge(state, {
        numberOfTiers: action.payload,
      });

    case lessonActionTypes.SET_TIER_SUMMARY:
      return immutable.merge(state, {
        tierSummary: action.payload,
      });
    case lessonActionTypes.SET_WRAP_UP_INDEX:
      return immutable.merge(state, {
        wrapUpIndex: action.payload,
      });
    case lessonActionTypes.SET_SHOW_WRAP_UP:
      return immutable.merge(state, {
        showWrapUp: action.payload,
      });

    case lessonActionTypes.SET_LESSON_PROCESS_PENDING:
      return immutable.merge(state, {
        lessonProcessPending: action.payload,
      });

    case lessonActionTypes.SET_WRAP_UP_MODAL_PAGE:
      return immutable.merge(state, {
        wrapUpPage: action.payload,
      });
    case lessonActionTypes.SET_ALLOW_CHEVRONS:
      return immutable.merge(state, {
        allowChevrons: action.payload,
      });
    case lessonActionTypes.RESET_LESSON:
      return {
        ...state,
        resetLessonUuid: uuid()
      }
    case lessonActionTypes.SET_LESSON_PLAYING:
      console.log("setting lesson playing in reducer", action.payload.lessonPlaying)
      return {
          ...state,
          lessonPlaying: action.payload.lessonPlaying
      }
    case lessonActionTypes.SET_TIER_SUMMARY_REQUEST_ERROR:
      return {
          ...state,
          tierSummaryRequestError: action.payload
      }

    case lessonActionTypes.UPDATE_CURRENT_ULP:
      return {
        ...state
      }
    case lessonActionTypes.UPDATE_CURRENT_ULP_SUCCESS:
      return {
        ...state,
        currentUserLevelData: action.payload.next
      }
  
    case lessonActionTypes.UPDATE_PHRASES_TIL_NEXT_TIER:
      return {
        ...state,
        phrasesTilNextTier: action.payload
      }
  
    case lessonActionTypes.UPDATE_CURRENT_LEVEL_TIERS:
      return {
        ...state,
        currentLevelTiers: action.payload
      }
    case lessonActionTypes.SET_CURRENT_COMPLETED_AT:
      return {
        ...state,
        currentLevelCompletedAt: action.payload
      }

    default:
      return state;
  }
};