import { RepertoireActions, RepertoireState, RepertoireActionTypes } from 'Types/RepertoireTypes'

export const defaultState: RepertoireState = {
  repertoireData: [],
  userRepertoireProgresses: [],
  repertoireIdSelect: 1,
  repertoirePlaying: false,
  repertoireEnded: false,
  cursorVisible: true,
  playSessionId: null
}

export const repertoireReducer = (state = defaultState, action: RepertoireActions): RepertoireState => {
  switch (action.type) {
    
    case RepertoireActionTypes.SET_CURSOR_VISIBLE:
      return {
        ...state,
        cursorVisible: action.payload
      }

    case RepertoireActionTypes.LOAD_REPERTOIRE_STATE:
      return {
        ...state,
        ...action.payload
      }
    
    case RepertoireActionTypes.FETCH_REPERTOIRE_DATA_SUCCESS:
      return {
        ...state,
        repertoireData: action.payload
      }

    case RepertoireActionTypes.SET_REPERTOIRE_ENDED:
      return {
        ...state,
        repertoireEnded: action.payload
      }
    
    case RepertoireActionTypes.SET_REPERTOIRE_SELECT:
      return {
        ...state,
        repertoireIdSelect: action.payload.repertoireIdSelect,
        // currentUserLevelData: find(state.userLevelData, uld => uld.level.level_number === state.levelData[action.payload.levelSelect].level_number),
        // currentLevelTiers: find(state.tiersByLevels, tbl => tbl.level_number === state.levelData[action.payload.levelSelect].level_number)?.tiers
      }

    case RepertoireActionTypes.FETCH_URP_SUCCESS:
      let current_urp = state.userRepertoireProgresses;
      let updatedArray = current_urp.slice();
      // This is used to "merge" the single-value array that gets passed with the put
      action.payload.forEach(payload_urp => {
        const index = updatedArray.findIndex(state_urp => state_urp.id === payload_urp.id);
        if (index !== -1) {
          updatedArray[index] = payload_urp;
        } else {
          updatedArray.push(payload_urp);
        }
      });

      return {
        ...state,
        userRepertoireProgresses: updatedArray
      }
    case RepertoireActionTypes.SET_REPERTOIRE_PLAYING:
      return {
          ...state,
          repertoirePlaying: action.payload.repertoirePlaying
      }

    case RepertoireActionTypes.SET_PLAY_SESSION_ID:
      return {
          ...state,
          playSessionId: action.payload
      }
    
    case RepertoireActionTypes.UPDATE_REPERTOIRE_DATA_LOCAL:    
      let urp = state.userRepertoireProgresses;
      if(urp) {
        let updatedArr = urp.slice();
        // This is used to "merge" the single-value array that gets passed with the put
        const payload_urp = action.payload
        const index = updatedArr.findIndex(state_urp => state_urp.id === payload_urp.id);
        if (index !== -1) {
          updatedArr[index] = {...updatedArr[index], last_tempo: payload_urp.last_tempo};
        }
        return {
          ...state,
          userRepertoireProgresses: updatedArr,
        }
      } else {
        return state
      }

  default: return state
  }
}
