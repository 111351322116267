export const planPricing = {
  monthly: {
    regular: 24.99,
    withCode: 12.49,
    with84: 4.0,
    with69: 7.75,
    with30: 17.49,
  },
  threeMonths: {
    regular: 49.99,
    withCode: 24.99,
    with84: 8.0,
    with69: 15.50,
    with30: 34.99,
  },
  annually: {
    regular: 189.99,
    withCode: 94.99,
    with84: 30.40,
    with69: 58.90,
    with30: 132.99,
  },
};

export const numTrialDays = 7

export const discountPercentages = {
  monthly: {
    regular: null,
    withCode: 50,
    with84: 84,
    with69: 69,
    with30: 30,
  },
  threeMonths: {
    regular: 33,
    withCode: 66,
    with84: 89,
    with69: 79,
    with30: 53,
  },
  annually: {
    regular: 36,
    withCode: 68,
    with84: 90,
    with69: 80,
    with30: 55,
  },
}

// From stripe; should pull from backend endpoint...
export const stripePriceIds = (() => {
  if (process.env.REACT_APP_NODE_ENV == "prd") {
    // These are the LIVE ones:
    return {
      monthly: 'price_1QKUlxJb5DvLdpQFAHfvAEmW', 
      threeMonths: 'price_1QKUmcJb5DvLdpQF8K2xN61H', 
      annually: 'price_1QKUnIJb5DvLdpQF2Tfh2cVi', 
      free: 'price_1QnqooJb5DvLdpQFe9ZjSXTO',
    };
  } else {
    // These are the TEST ones:
    return {
      monthly: 'price_1QKUlmJb5DvLdpQFv7QTQPpr', 
      threeMonths: 'price_1QKUmSJb5DvLdpQF3FlI7NPe', 
      annually: 'price_1QKUn9Jb5DvLdpQFThZXDzxV',
      free: 'price_1Qnqp7Jb5DvLdpQFdinGZmlp',
    }
  }
})()
