import { StartExercise, UpdateObjective } from "Phaser/EventBus";
import { Event, Exercise, Lecture, PianoScene, Section, StaffExercise, Tutorial } from "Utils/TutorialConfigHelper";

const Level1 = Tutorial(1,
  '3:41',
  [
    Section([
      Lecture('Keys on the Piano', '0:0'),
      Lecture('The White Keys', '0:20'),
      Lecture('Middle C on the Keyboard', '1:04'),
      Exercise('Find Middle C', '1:17',
        PianoScene(
          {
            transition: 'Dissolve',
            objectives: [
              {
                text: 'Play Middle C on your keyboard.',
                target: 'C4',
                videoCompleteDestination: '1:20',
              },
            ],
          },
        ),
        [
          Event('1:18', UpdateObjective()),
          Event('1:19.5', StartExercise()),
        ]
      ),
    ]),
    Section([
      Lecture('Reading Middle C', '1:22'),
      Lecture('Alternating Thumbs', '2:11.5'),
      Exercise(
        'Middle C Practice',
        '3:08',
        StaffExercise(
          {
            objectives: [
              {
                tempo: 72,
                videoCompleteDestination: '3:16.5',
                videoSkipDestination: '3:18',
              },
              {
                tempo: 72,
                videoCompleteDestination: '3:27.5',
                videoSkipDestination: '3:29',
              },
              {
                tempo: 72,
                videoCompleteDestination: '3:34.5',
                videoSkipDestination: '3:36',
              },
            ],
          }),
        [
          Event('3:11', UpdateObjective()),
          Event('3:15.5', StartExercise()),
          Event('3:18', UpdateObjective()),
          Event('3:26.5', StartExercise()),
          Event('3:29', UpdateObjective()),
          Event('3:33.5', StartExercise())
        ],
      )
    ])
  ]
);

export default Level1;
