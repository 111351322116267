import Phaser from 'phaser';
import { colors } from 'Phaser/config';
import {
  NotifyReact,
  ListenForReactEvent,
  RemoveListener,
  ListenStartExercise,
  ListenPauseExercise,
  ExerciseResumed,
  ListenMidiOff,
  ListenMidiOn,
  ReactToExerciseEventType,
  HideExercise,
  ObjectiveComplete,
  ObjectiveSkipped,
  SkipButtonRequest,
  ListenSkipExercise,
  ExercisePassed,
  ListenUnloadExercise,
} from 'Phaser/EventBus';
import Instruction, {
  InstructionPosition,
} from 'Phaser/GameObjects/Instruction';
import { Timestamp } from 'Types/ExerciseData';

export interface IObjective {
  text?: string;
  instructionWaitTime?: number;
  videoSkipDestination?: Timestamp;
  videoCompleteDestination?: Timestamp;
}

export interface IConfig<Objective extends IObjective> {
  objectives: Objective[];
  firstObjectiveAppearDelay?: number;
}

export default abstract class ExerciseBase<
  Objective extends IObjective,
  Config extends IConfig<Objective>,
> extends Phaser.Scene {
  active: boolean = false;
  // The instruction to be shown to the user
  instruction: Instruction | null = null;
  numFailedAttempts: number = 0;
  failMessage: Phaser.GameObjects.Text | null = null;
  toastMessage: Phaser.GameObjects.Text | null = null;
  debugText: Phaser.GameObjects.Text | null = null;
  paused: boolean = false;
  config?: Config;
  currentObjective?: number;
  waitToast: boolean = false;

  registeredKeys: Map<string, Phaser.Input.Keyboard.Key> = new Map();
  skipped: boolean = false;

  showInstruction(
    instruction: string,
    position: InstructionPosition = 'Top'
  ): void {
    this.instruction = new Instruction(this, instruction, position);
  }

  debugLog(text: string): void {
    if (!this.debugText)
      this.debugText = this.add.text(200, 100, text, {
        color: '#000',
        fontSize: 22,
      });
    else this.debugText.setText(text);
  }

  // Initialise objects and perform any scene switch transitions here.
  init(config: Config): void {
    this.events.once('shutdown', this.unload, this);
    ListenForReactEvent(ListenStartExercise(this.startExercise), this);
    ListenForReactEvent(ListenPauseExercise(this.pauseExercise), this);
    ListenForReactEvent(ListenMidiOn(this.midiOn), this);
    ListenForReactEvent(ListenMidiOff(this.midiOff), this);
    ListenForReactEvent(ListenSkipExercise(this.skipExercise), this);
    ListenForReactEvent(ListenUnloadExercise(this.stop), this);
    NotifyReact(ExerciseResumed());
    this.config = config;
    this.active = true;
  }

  transition(skip?: boolean, last?: boolean): void {
    if (last) {
      if (!this.skipped) {
        NotifyReact(ExercisePassed());
      }
      this.onExercisePass();
      this.onExerciseOver();
    }
    if (skip) {
      this.onObjectiveSkipped();
      NotifyReact(
        ObjectiveSkipped(
          this.config!.objectives[this.currentObjective!].videoSkipDestination!
        )
      );
    } else {
      this.onObjectivePassed();
      NotifyReact(
        ObjectiveComplete(
          this.config!.objectives[this.currentObjective!]
            .videoCompleteDestination!
        )
      );
    }
  }

  midiOn(_data?: { note: number; velocity: number }): void { }

  midiOff(_data?: { note: number }): void { }

  /// Remove all events / timers / event listeners here,
  /// all sub classes should call super.clearEvents() if overridden
  clearEvents(): void {
    RemoveListener(ReactToExerciseEventType.MidiOn);
    RemoveListener(ReactToExerciseEventType.MidiOff);
    RemoveListener(ReactToExerciseEventType.PauseExercise);
    RemoveListener(ReactToExerciseEventType.UpdateObjective);
    RemoveListener(ReactToExerciseEventType.SkipExercise);
    RemoveListener(ReactToExerciseEventType.UnloadExercise);
  }

  /// Should be called before loading the next scene.
  unload(): void {
    //NotifyReact("exercise-paused");
    this.currentObjective = undefined;
    console.debug('unloading exercise');
    NotifyReact(HideExercise());
    RemoveListener(ReactToExerciseEventType.StartExercise);
    this.debugText?.destroy();
    this.debugText = null;
    this.active = false;
    this.failMessage?.destroy();
    this.failMessage = null;
    this.toastMessage?.destroy();
    this.toastMessage = null;
    this.instruction?.destroy();
    this.instruction = null;
    this.registeredKeys.clear();
    this.numFailedAttempts = 0;
    this.clearEvents();
    this.time.removeAllEvents();
    this.tweens.killAll();
    if (this.paused) this.scene.stop('PauseMenu');
    //this.scene.start("Empty");
  }

  changeScene(name: string, args: any): void {
    this.scene.start(name, args);
  }

  startExercise(): void { }

  pauseExercise(): void {
    //EventBus.emit("exercise-paused");
    this.paused = true;
    this.scene.pause();
    this.scene.launch('PauseMenu', { other: this.scene.key });
  }

  hideInstruction(): void {
    this.instruction?.destroy();
  }

  onObjectivePassed(): void { }
  onObjectiveSkipped(): void { }

  passObjective(last?: boolean): void {
    this.instruction?.showResult(true, () => {
      this.transition(false, last);
    });
    //if (last) this.onExercisePass();
  }

  skipExercise(): void {
    this.skipped = true;
    this.instruction?.destroy();
    this.transition(
      true,
      this.currentObjective === this.config!.objectives.length - 1
    );
  }

  /// Override this for custom behaviour when a user passes the exercise
  onExercisePass(): void { }

  restart(): void { }

  /// Override this for custom behaviour when a user fails the exercise
  onExerciseFail(): void {
    this.restart();
  }

  onExerciseOver(delay: number = 500): void {
    this.time.delayedCall(delay, this.stop, [], this);
  }

  abstract stop(): void;
  abstract create(): void;

  showToast(text: string): void {
    if (this.waitToast) return;
    this.waitToast = true;
    const dpr = 1;
    this.toastMessage?.destroy();
    this.toastMessage = this.add.text(100, 100, text, {
      fontFamily: 'Lato',
      fontSize: `${32 * dpr}px`,
      color: '#000000',
      align: 'center',
    });
    this.toastMessage.setAlpha(0);
    this.toastMessage.setPosition(
      this.cameras.main.width / 2 - this.toastMessage.width / 2,
      this.cameras.main.height - 75 * dpr
    );
    this.tweens.add({
      hold: 1000,
      targets: this.toastMessage,
      alpha: 1,
      duration: 500,
      yoyo: true,
      ease: 'Power1',
      onComplete: () => {
        //bg.destroy();
        this.toastMessage?.destroy();
        this.waitToast = false;
      },
    });
  }

  resume(): void {
    this.paused = false;
  }

  failExercise(text?: string): void {
    this.numFailedAttempts++;
    if (this.numFailedAttempts >= 1) {
      const cam = this.cameras.main;
      const dpr = 1;
      this.failMessage = this.add.text(
        cam.width / 2,
        cam.height + 100 * dpr,
        text ?? 'Good effort! Try again.',
        {
          fontFamily: 'Lato',
          fontSize: 32 * dpr,
          fontStyle: 'bold',
          color: colors.textFg,
        }
      );
      this.tweens.add({
        targets: this.failMessage,
        y: cam.height - 120 * dpr,
        duration: 500,
        ease: 'Power2',
        yoyo: true,
        hold: 2000,
      });
      this.failMessage.setX(cam.width / 2 - this.failMessage.displayWidth / 2);
      NotifyReact(SkipButtonRequest());
    }
    this.onExerciseFail();
    /*this.failMenu?.destroy();
    this.failMenu = new FailMenu(
      this,
      () => {
        this.failMenu?.hide();
        this.passExercise();
      },
      () => {
        this.failMenu?.hide();
        this.restart();
      }
    );*/
  }

  /// Add key press / release listeners for the duration of this exercise
  addKey(keyname: string, onDown: () => void, onUp?: () => void, ctx?: object) {
    const key =
      this.registeredKeys.get(keyname) ?? this.input.keyboard!.addKey(keyname);
    key.on('down', onDown, ctx);
    //this.input.keyboard!.on(`keydown-${key.toUpperCase()}`, onDown, ctx);
    if (onUp) {
      //this.input.keyboard!.on(`keyup-${key.toUpperCase()}`, onUp, ctx);
      key.on('up', onUp, ctx);
    }
    this.registeredKeys.set(keyname, key);
  }

  /// Remove any registered key listeners with the passed callbacks
  removeKey(keyname: string, onDown: () => void, onUp?: () => void) {
    this.registeredKeys.get(keyname)?.off('down', onDown).off('up', onUp);
  }

  centreHeight(objectHeight: number) {
    return (this.cameras.main.height / 2) - (objectHeight / 2);
  }

  centreWidth(objectWidth: number) {
    return (this.cameras.main.width / 2) - (objectWidth / 2);
  }

  sectionComplete(): void { }
}
