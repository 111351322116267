import { ReactToExerciseEventType } from 'Phaser/EventBus';
import Tutorial, { ChapterType, ExerciseScene } from '../Types/ExerciseData';
import Level0 from './TutorialLevels/Level0';
import Level1 from './TutorialLevels/Level1';
import Level10 from './TutorialLevels/Level10';
import Level2 from './TutorialLevels/Level2';
import Level3 from './TutorialLevels/Level3';

const TutorialData: Tutorial[] = [
  Level0,
  Level1,
  Level2,
  Level3,
  {
    end: '1:49',
    level: 4,
    videoUrl: 'level-4.mp4',
    sections: [
      {
        chapters: [
          {
            title: 'E in the Treble Clef',
            start: '0:0',
            data: [ChapterType.Lecture]
          },
          {
            title: 'E on the Keyboard',
            start: '0:13',
            data: [ChapterType.Lecture]
          },
          {
            title: 'Find E',
            start: '0:29',
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.PianoScene,
                  {
                    start: 'A0',
                    end: 'C8',
                    transition: 'Dissolve',
                    textPromptPosition: 'Top',
                    objectives: [
                      {
                        text: 'Play E on your keyboard.',
                        target: 'E4',
                        videoCompleteDestination: '0:33.5',
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: '0:30.5',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '0:32',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                ],
              },
            ],
          }
        ]
      },
      {
        chapters: [
          {
            title: 'Playing with the 3rd Finger',
            start: '0:36',
            data: [ChapterType.Lecture]
          },
          {
            title: 'Playing E',
            start: '0:42',
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.NoTimeExercise,
                  {
                    centre_align_horizontal: false,
                    padding_x: -700,
                    firstObjectiveAppearDelay: 1500,
                    objectives: [
                      {
                        text: 'Play these notes at your own pace.',
                        videoSkipDestination: '0:49',
                        videoCompleteDestination: '0:47.5',
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: '0:44',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '0:46',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                ],
              }
            ]
          },
          {
            title: 'Using the First 3 Fingers',
            start: '0:49',
            data: [ChapterType.Lecture]
          },
          {
            title: 'Alternating Fingers',
            start: '1:08',
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.NoTimeExercise,
                  {
                    centre_align_horizontal: true,
                    padding_x: 0,
                    firstObjectiveAppearDelay: 1500,
                    objectives: [
                      {
                        text: 'Play these notes at your own pace.',
                        videoSkipDestination: '1:13.5',
                        videoCompleteDestination: '1:14.5',
                      },
                      {
                        text: 'Play these notes at your own pace.',
                        videoSkipDestination: '1:22',
                        videoCompleteDestination: '1:20.5',
                      },
                      {
                        text: 'Play these notes at your own pace.',
                        videoSkipDestination: '1:29',
                        videoCompleteDestination: '1:27.5',
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: '1:10',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '1:12.5',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                  {
                    at: '1:15',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '1:19.5',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                  {
                    at: '1:22.5',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '1:27',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                ],
              }
            ]
          },
          {
            title: 'Playing in Time',
            start: '1:30',
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.StaffExercise,
                  {
                    firstObjectiveAppearDelay: 500,
                    objectives: [
                      {
                        tempo: 72,
                        text: 'Play this line of music in sync with the metronome.',
                        paddingHorizontal: 20,
                        phrases: [],
                        videoCompleteDestination: '1:36.5',
                        videoSkipDestination: '1:37.5',
                      },
                      {
                        tempo: 72,
                        text: 'Play this line of music in sync with the metronome.',
                        paddingHorizontal: 20,
                        phrases: [],
                        videoCompleteDestination: '1:40',
                        videoSkipDestination: '1:42.5',
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: '1:32.1',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '1:35.5',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                  {
                    at: '1:38.5',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '1:40',
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                    pause: true,
                  },
                ],
              }
            ]
          }
        ]
      }
    ]
  },
  {
    end: '2:22',
    level: 5,
    videoUrl: 'level-5.mp4',
    sections: [
      {
        chapters: [
          {
            title: 'A in the Bass Clef',
            start: '0:0',
            data: [ChapterType.Lecture]
          },
          {
            title: 'A on the Keyboard',
            start: '0:12',
            data: [ChapterType.Lecture]
          },
          {
            title: 'Find A',
            start: '0:25',
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.PianoScene,
                  {
                    start: 'A0',
                    end: 'C8',
                    transition: 'Dissolve',
                    textPromptPosition: 'Top',
                    objectives: [
                      {
                        text: 'Play A on your keyboard.',
                        target: 'A3',
                        videoCompleteDestination: '0:29',
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: '0:26',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '0:28',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                ],
              },
            ],
          },
        ]
      },
      {
        chapters: [
          {
            title: 'Playing with the 3rd Finger',
            start: '0:36',
            data: [ChapterType.Lecture]
          },
          {
            title: 'Playing A',
            start: '0:36',
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.NoTimeExercise,
                  {
                    centre_align_horizontal: false,
                    padding_x: 700,
                    firstObjectiveAppearDelay: 1500,
                    objectives: [
                      {
                        text: 'Play these notes at your own pace.',
                        videoSkipDestination: '0:42.5',
                        videoCompleteDestination: '0:41.5',
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: '0:37.5',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '0:41',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                ],
              }
            ]
          },
          {
            title: 'Using the First 3 Fingers',
            start: '0:44',
            data: [ChapterType.Lecture]
          },
          {
            title: 'Alternating Fingers',
            start: '1:04',
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.NoTimeExercise,
                  {
                    centre_align_horizontal: false,
                    padding_x: 700,
                    firstObjectiveAppearDelay: 1500,
                    objectives: [
                      {
                        text: 'Play these notes at your own pace.',
                        videoSkipDestination: '1:11',
                        videoCompleteDestination: '1:09',
                      },
                      {
                        text: 'Play these notes at your own pace.',
                        videoSkipDestination: '1:18',
                        videoCompleteDestination: '1:16',
                      },
                      {
                        text: 'Play these notes at your own pace.',
                        videoSkipDestination: '1:25',
                        videoCompleteDestination: '1:22.5',
                      }
                    ],
                  },
                ],
                events: [
                  {
                    at: '1:06',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '1:08.5',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                  {
                    at: '1:12.5',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '1:15.5',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                  {
                    at: '1:19.5',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '1:22',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                ],
              }
            ]
          },
          {
            title: 'Playing in Time',
            start: '1:25',
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.StaffExercise,
                  {
                    firstObjectiveAppearDelay: 500,
                    objectives: [
                      {
                        tempo: 72,
                        text: 'Play this line of music in sync with the metronome.',
                        paddingHorizontal: 20,
                        phrases: [],
                        videoCompleteDestination: '1:31.5',
                        videoSkipDestination: '1:32.5',
                      },
                      {
                        tempo: 72,
                        text: 'Play this line of music in sync with the metronome.',
                        paddingHorizontal: 20,
                        phrases: [],
                        videoCompleteDestination: '1:35.5',
                        videoSkipDestination: '1:37',
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: '1:28',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '1:31',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                  {
                    at: '1:33.5',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '1:34.5',
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                    pause: true,
                  },
                ],
              }
            ]
          },
        ]
      },
      {
        chapters: [
          {
            title: 'Using Both Hands',
            start: '1:38',
            data: [ChapterType.Lecture]
          },
          {
            title: 'Playing in Time',
            start: '2:07',
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.StaffExercise,
                  {
                    firstObjectiveAppearDelay: 500,
                    objectives: [
                      {
                        tempo: 72,
                        text: 'Play this line of music in sync with the metronome.',
                        paddingHorizontal: 20,
                        phrases: [],
                        videoCompleteDestination: '2:09.5',
                        videoSkipDestination: '2:11.5',
                      },
                      {
                        tempo: 72,
                        text: 'Play this line of music in sync with the metronome.',
                        paddingHorizontal: 20,
                        phrases: [],
                        videoCompleteDestination: '2:13',
                        videoSkipDestination: '2:15',
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: '2:08.5',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '2:09',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                  {
                    at: '2:11.5',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '2:12.5',
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                    pause: true,
                  },
                ],
              }
            ]
          }
        ]
      }
    ]
  },
  {
    end: '1:52',
    level: 6,
    videoUrl: 'level-6.mp4',
    sections: [
      {
        chapters: [
          {
            title: 'Reviewing Time Signatures',
            start: '0:0',
            data: [ChapterType.Lecture]
          },
          {
            title: '3/4 Time',
            start: '0:32',
            data: [ChapterType.Lecture]
          },
          {
            title: 'Dotted Half Notes',
            start: '0:45',
            data: [ChapterType.Lecture]
          },
          {
            title: 'Dotted Half Notes',
            start: '1:10',
            data: [ChapterType.Lecture]
          },
          {
            title: 'Playing on the Staff',
            start: '1:20',
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.StaffExercise,
                  {
                    firstObjectiveAppearDelay: 500,
                    objectives: [
                      {
                        tempo: 72,
                        text: 'Play this line of music in sync with the metronome.',
                        paddingHorizontal: 20,
                        phrases: [],
                        videoCompleteDestination: '1:25',
                        videoSkipDestination: '1:26.5'
                      },
                      {
                        tempo: 72,
                        text: 'Play this line of music in sync with the metronome.',
                        paddingHorizontal: 20,
                        phrases: [],
                        videoCompleteDestination: '1:28.5',
                        videoSkipDestination: '1:30'
                      },
                    ]
                  }
                ],
                events: [
                  {
                    at: '1:21.1',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '1:24',
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                    pause: true
                  },
                  {
                    at: '1:27',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '1:28',
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                    pause: true
                  }
                ]
              },
            ],
          }
        ]
      },
      {
        chapters: [
          {
            title: 'Combining Rhythms',
            start: '1:31',
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.StaffExercise,
                  {
                    firstObjectiveAppearDelay: 0,
                    objectives: [
                      {
                        tempo: 72,
                        text: 'Play this line of music in sync with the metronome.',
                        paddingHorizontal: 20,
                        phrases: [],
                        videoCompleteDestination: '1:41',
                        videoSkipDestination: '1:42.5'
                      },
                      {
                        tempo: 72,
                        text: 'Play this line of music in sync with the metronome.',
                        paddingHorizontal: 20,
                        phrases: [],
                        videoCompleteDestination: '1:44.5',
                        videoSkipDestination: '1:46'
                      },
                    ]
                  }
                ],
                events: [
                  {
                    at: '1:37',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '1:39.8',
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                    pause: true
                  },
                  {
                    at: '1:42.5',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '1:43.5',
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                    pause: true
                  }
                ]
              },
            ]
          }
        ]
      }
    ]
  },
  {
    level: 7,
    end: '2:10',
    videoUrl: 'level-7.mp4',
    sections: [
      {
        chapters: [
          {
            title: 'G in the Bass Clef',
            start: '0:0',
            data: [ChapterType.Lecture]
          },
          {
            title: 'G on the Keyboard',
            start: '0:22',
            data: [ChapterType.Lecture]
          },
          {
            title: 'Find G',
            start: '0:32',
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.PianoScene,
                  {
                    start: 'A0',
                    end: 'C8',
                    transition: 'Dissolve',
                    textPromptPosition: 'Top',
                    objectives: [
                      {
                        text: 'Play G on your keyboard.',
                        target: 'G3',
                        videoCompleteDestination: '0:35.5',
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: '0:33',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '0:34',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                ],
              },
            ],
          }
        ]
      },
      {
        chapters: [
          {
            title: 'Playing with the 4th Finger',
            start: '0:38',
            data: [ChapterType.Lecture]
          },
          {
            title: 'Playing G',
            start: '0:42',
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.NoTimeExercise,
                  {
                    centre_align_horizontal: false,
                    padding_x: 700,
                    firstObjectiveAppearDelay: 1500,
                    objectives: [
                      {
                        text: 'Play these notes at your own pace.',
                        videoSkipDestination: '0:49.5',
                        videoCompleteDestination: '0:48',
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: '0:44',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '0:46.7',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                ],
              }
            ]
          },
          {
            title: '4th Finger Technique',
            start: '0:50',
            data: [ChapterType.Lecture]
          },
          {
            title: 'Alternating Fingers',
            start: '1:09',
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.NoTimeExercise,
                  {
                    centre_align_horizontal: true,
                    padding_x: 0,
                    firstObjectiveAppearDelay: 1500,
                    objectives: [
                      {
                        text: 'Play these notes at your own pace.',
                        videoSkipDestination: '1:15.5',
                        videoCompleteDestination: '1:14.5',
                      },
                      {
                        text: 'Play these notes at your own pace.',
                        videoSkipDestination: '1:18',
                        videoCompleteDestination: '1:21',
                      },
                      {
                        text: 'Play these notes at your own pace.',
                        videoSkipDestination: '1:32',
                        videoCompleteDestination: '1:30.5',
                      }
                    ],
                  },
                ],
                events: [
                  {
                    at: '1:11',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '1:13.5',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                  {
                    at: '1:15.5',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '1:20.5',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                  {
                    at: '1:23',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '1:29',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                ],
              }
            ]
          },
          {
            title: 'Playing in Time',
            start: '1:32',
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.StaffExercise,
                  {
                    firstObjectiveAppearDelay: 500,
                    objectives: [
                      {
                        tempo: 72,
                        text: 'Play this line of music in sync with the metronome.',
                        paddingHorizontal: 20,
                        phrases: [],
                        videoCompleteDestination: '1:42.5',
                        videoSkipDestination: '1:44.2',
                      },
                      {
                        tempo: 72,
                        text: 'Play this line of music in sync with the metronome.',
                        paddingHorizontal: 20,
                        phrases: [],
                        videoCompleteDestination: '1:46',
                        videoSkipDestination: '1:48',
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: '1:38',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '1:41.8',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                  {
                    at: '1:44.2',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '1:45.5',
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                    pause: true,
                  },
                ],
              }
            ]
          }
        ]
      },
      {
        chapters: [
          {
            title: 'Using Both Hands',
            start: '1:48',
            data: [ChapterType.Lecture]
          },
          {
            title: 'Alternating Hands',
            start: '1:56',
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.StaffExercise,
                  {
                    firstObjectiveAppearDelay: 500,
                    objectives: [
                      {
                        tempo: 72,
                        text: 'Play this line of music in sync with the metronome.',
                        paddingHorizontal: 20,
                        phrases: [],
                        videoCompleteDestination: '1:58.5',
                        videoSkipDestination: '2:00',
                      },
                      {
                        tempo: 72,
                        text: 'Play this line of music in sync with the metronome.',
                        paddingHorizontal: 20,
                        phrases: [],
                        videoCompleteDestination: '2:02',
                        videoSkipDestination: '2:03',
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: '1:57',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '1:57.8',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                  {
                    at: '2:00',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '2:01.5',
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                    pause: true,
                  },
                ],
              }
            ]
          }
        ]
      }
    ]
  },
  {
    level: 8,
    end: '2:04',
    videoUrl: 'level-8.mp4',
    sections: [
      {
        chapters: [
          {
            title: 'F in the Treble Clef',
            start: '0:0',
            data: [ChapterType.Lecture]
          },
          {
            title: 'F on the Keyboard',
            start: '0:13',
            data: [ChapterType.Lecture]
          },
          {
            title: 'Find F',
            start: '0:24',
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.PianoScene,
                  {
                    start: 'A0',
                    end: 'C8',
                    transition: 'Dissolve',
                    textPromptPosition: 'Top',
                    objectives: [
                      {
                        text: 'Play F on your keyboard.',
                        target: 'F4',
                        videoCompleteDestination: '0:28.2',
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: '0:25',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '0:27',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                ],
              },
            ],
          }
        ]
      },
      {
        chapters: [
          {
            title: 'Playing with the 4th Finger',
            start: '0:30',
            data: [ChapterType.Lecture]
          },
          {
            title: 'Playing F',
            start: '0:34',
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.NoTimeExercise,
                  {
                    centre_align_horizontal: false,
                    padding_x: 180,
                    firstObjectiveAppearDelay: 1500,
                    objectives: [
                      {
                        text: 'Play these notes at your own pace.',
                        videoSkipDestination: '0:41',
                        videoCompleteDestination: '0:40.1',
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: '0:36',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '0:38.8',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                ],
              }
            ]
          },
          {
            title: '4th Finger Technique',
            start: '0:42',
            data: [ChapterType.Lecture]
          },
          {
            title: 'Alternating Fingers',
            start: '1:07',
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.NoTimeExercise,
                  {
                    centre_align_horizontal: true,
                    padding_x: 700,
                    firstObjectiveAppearDelay: 1500,
                    objectives: [
                      {
                        text: 'Play these notes at your own pace.',
                        videoSkipDestination: '1:14',
                        videoCompleteDestination: '1:13.2',
                      },
                      {
                        text: 'Play these notes at your own pace.',
                        videoSkipDestination: '1:20',
                        videoCompleteDestination: '1:18.7',
                      },
                      {
                        text: 'Play these notes at your own pace.',
                        videoSkipDestination: '1:25.5',
                        videoCompleteDestination: '1:24.5',
                      }
                    ],
                  },
                ],
                events: [
                  {
                    at: '1:08',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '1:12',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                  {
                    at: '1:14.5',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '1:17.5',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                  {
                    at: '1:20',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '1:23.9',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                ],
              }
            ]
          },
          {
            title: 'Playing in Time',
            start: '1:26',
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.StaffExercise,
                  {
                    firstObjectiveAppearDelay: 500,
                    objectives: [
                      {
                        tempo: 72,
                        text: 'Play this line of music in sync with the metronome.',
                        paddingHorizontal: 20,
                        phrases: [],
                        videoCompleteDestination: '1:35.5',
                        videoSkipDestination: '1:36.5',
                      },
                      {
                        tempo: 72,
                        text: 'Play this line of music in sync with the metronome.',
                        paddingHorizontal: 20,
                        phrases: [],
                        videoCompleteDestination: '1:39.5',
                        videoSkipDestination: '1:40.5',
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: '1:28',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '1:34',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                  {
                    at: '1:36.5',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '1:38.5',
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                    pause: true,
                  },
                ],
              }
            ]
          }
        ]
      },
      {
        chapters: [
          {
            title: 'Using Both Hands',
            start: '1:42',
            data: [ChapterType.Lecture]
          },
          {
            title: 'Alternating Hands',
            start: '1:48',
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.StaffExercise,
                  {
                    firstObjectiveAppearDelay: 500,
                    objectives: [
                      {
                        tempo: 72,
                        text: 'Play this line of music in sync with the metronome.',
                        paddingHorizontal: 20,
                        phrases: [],
                        videoCompleteDestination: '1:52.5',
                        videoSkipDestination: '1:53.5',
                      },
                      {
                        tempo: 72,
                        text: 'Play this line of music in sync with the metronome.',
                        paddingHorizontal: 20,
                        phrases: [],
                        videoCompleteDestination: '1:55.5',
                        videoSkipDestination: '1:56',
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: '1:49',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '1:51.5',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                  {
                    at: '1:53.5',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '1:54.5',
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                    pause: true,
                  },
                ],
              }
            ]
          }
        ]
      }
    ]
  },
  {
    level: 9,
    end: '3:13',
    videoUrl: 'level-9.mp4',
    sections: [
      {
        chapters: [
          {
            title: 'Introduction to 2-Part Harmony',
            start: '0:00',
            data: [ChapterType.Lecture]
          },
        ]
      },
      {
        chapters: [
          {
            title: 'Oblique Motion',
            start: '0:49',
            data: [ChapterType.Lecture]
          },
          {
            title: 'Oblique Motion',
            start: '1:03',
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.NoTimeExercise,
                  {
                    centre_align_horizontal: false,
                    padding_x: 700,
                    firstObjectiveAppearDelay: 1500,
                    objectives: [
                      {
                        text: 'Play these notes at your own pace.',
                        videoSkipDestination: '1:11',
                        videoCompleteDestination: '1:08.5',
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: '1:05',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '1:07.5',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                ],
              }
            ]
          },
          {
            title: 'Parallel Motion',
            start: '1:11',
            data: [ChapterType.Lecture]
          },
          {
            title: 'Parallel Motion',
            start: '1:28',
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.NoTimeExercise,
                  {
                    centre_align_horizontal: false,
                    padding_x: 700,
                    firstObjectiveAppearDelay: 1500,
                    objectives: [
                      {
                        text: 'Play these notes at your own pace.',
                        videoSkipDestination: '1:32',
                        videoCompleteDestination: '1:30.5',
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: '1:26',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '1:29.8',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                ],
              }
            ]
          },
          {
            title: 'Contrary Motion',
            start: '1:32',
            data: [ChapterType.Lecture]
          },
          {
            title: 'Contrary Motion',
            start: '1:45',
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.NoTimeExercise,
                  {
                    centre_align_horizontal: false,
                    padding_x: 700,
                    firstObjectiveAppearDelay: 1500,
                    objectives: [
                      {
                        text: 'Play these notes at your own pace.',
                        videoSkipDestination: '1:49',
                        videoCompleteDestination: '1:47.5',
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: '1:44',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '1:46.5',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                ],
              }
            ]
          },
          {
            title: 'Combined Motion',
            start: '1:49',
            data: [ChapterType.Lecture]
          },
          {
            title: 'Combined Motion',
            start: '2:00',
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.NoTimeExercise,
                  {
                    centre_align_horizontal: false,
                    padding_x: 700,
                    firstObjectiveAppearDelay: 1500,
                    objectives: [
                      {
                        text: 'Play these notes at your own pace.',
                        videoSkipDestination: '2:05',
                        videoCompleteDestination: '2:03.5',
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: '2:01',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '2:02.5',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                ],
              }
            ]
          },
        ]
      },
      {
        chapters: [
          {
            title: 'Playing Whole Note Pairs',
            start: '2:05',
            data: [ChapterType.Lecture]
          },
          {
            title: 'Whole Note Pairs',
            start: '2:05',
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.StaffExercise,
                  {
                    firstObjectiveAppearDelay: 500,
                    objectives: [
                      {
                        tempo: 72,
                        text: 'Play this line of music in sync with the metronome.',
                        paddingHorizontal: 20,
                        phrases: [],
                        videoCompleteDestination: '2:19.5',
                        videoSkipDestination: '2:20.5',
                      },
                      {
                        tempo: 72,
                        text: 'Play this line of music in sync with the metronome.',
                        paddingHorizontal: 20,
                        phrases: [],
                        videoCompleteDestination: '2:23',
                        videoSkipDestination: '2:25',
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: '2:15.5',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '2:18.8',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                  {
                    at: '2:21',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '2:22',
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                    pause: true,
                  },
                ],
              }
            ]
          },
          {
            title: 'Playing Whole + Half Notes',
            start: '2:25',
            data: [ChapterType.Lecture]
          },
          {
            title: 'Whole + Half Notes',
            start: '2:57',
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.StaffExercise,
                  {
                    firstObjectiveAppearDelay: 500,
                    objectives: [
                      {
                        tempo: 72,
                        text: 'Play this line of music in sync with the metronome.',
                        paddingHorizontal: 20,
                        phrases: [],
                        videoCompleteDestination: '3:00',
                        videoSkipDestination: '3:01.5',
                      },
                      {
                        tempo: 72,
                        text: 'Play this line of music in sync with the metronome.',
                        paddingHorizontal: 20,
                        phrases: [],
                        videoCompleteDestination: '3:04.5',
                        videoSkipDestination: '3:06',
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: '2:58',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '2:59',
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                  {
                    at: '3:02',
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: '3:03.8',
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                    pause: true,
                  },
                ],
              }
            ]
          }
        ]
      }
    ]
  },
  Level10
];

export default TutorialData;
