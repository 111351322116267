
import axios, { AxiosError, AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';

import { all, getContext, put, select, takeEvery } from "redux-saga/effects";
import * as lessonActions from 'Actions/lesson';
import * as eventActions from 'Actions/events';
import * as appActions from 'Actions/app'
import {
  CreateLevelSession,
  InitiateWrapUp, WrapUpModalPage, lessonActionTypes,
  UpdateCurrentULP,
  UpdateCurrentULPSuccess,
  SetWrapUpIndex
} from 'Types/LessonTypes';
import { getCurrentAuthToken } from 'Utils/Amplify';
import { LessonState, lessonReducer } from 'Reducers/lessonReducer';
import { AppState, SetLevelSelect, UserLevelData, actionTypes } from 'Types';
import { EventActionTypes, EventState } from 'Types/EventTypes';
import StartingULP from 'Fixtures/UserLevelProgress/StartingULP';
import { awsRum, } from 'Utils/AwsRum';
import { mainAppReducer } from 'Reducers/mainAppReducer';
import logger from 'Utils/Logger';
import { has } from 'lodash'
import { dispatch } from 'd3';
import { time } from 'console';


// axiosRetry(axios,{ retryDelay: axiosRetry.linearDelay(), retries: 3 } )

const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/`

async function getTiersForLevel(jwtToken: string, levelNumber: number){
  const options = {
    url: process.env.REACT_APP_BACKEND_URL + `/api/v1/phrases/tiers-by-level?levelNumber=${levelNumber}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Authorization": `Bearer ${jwtToken}`
    }
  }
  let response = await axios
    .request(options)
  return response['data']
}

function *onLevelSelectUpdate(action: SetLevelSelect): any {
  const jwtToken = yield getCurrentAuthToken()
  const tiersForLevel = yield getTiersForLevel(jwtToken, action.payload.levelSelect)
  yield put(lessonActions.updateCurrentLevelTiers(tiersForLevel))
}

function buildULP(levelNumber: number, playSessionId: string | undefined | null, ulp: Partial<UserLevelData>) {
  // maybe this should just be dropped on the backend?

  if(ulp && Object.keys(ulp).contains('user_id')) {
    delete ulp.user_id
  }
  const body = {
    ...ulp,
    level: {
      level_number: levelNumber
    },
  }
  // explicitly checking for undefined here because "null" is how we explicitly wipe the current session from the 
  // ULP of the level. If a user arrives to a level, and a current session is sent from the backend, it can lead to a
  // rerender.
  // I may have fixed that though...
  if(playSessionId !== undefined) {
    body['play_session'] = {
      id: playSessionId
    }
  }
  return body
}

function* onCurrentULPUpdate2(action: UpdateCurrentULP): any {
  const data: AppState = yield select(({ mainAppReducer }) => mainAppReducer)
  const events: EventState = yield select(({ eventReducer }) => eventReducer)
  const lesson: LessonState = yield select(({lessonReducer}) => lessonReducer)
  // const { jwtToken }: AuthState = yield select(({authReducer}) => authReducer)
  const jwtToken = yield getCurrentAuthToken()
  const level = data.levelData[data.levelSelect].level_number // This will determine which tutorial we grab!
  const toUpdateULP = action.payload.currentUserLevelProgress;
  // instead of just using the current play session id if the given is undefined, we're checking for the key 
  // because it can be explicitly set to undefined, which we would want to retain
  const playSessionId = action.payload.currentUserLevelProgress?.play_session?.id
    // action.payload.currentUserLevelProgress && has(action, "payload.currentUserLevelProgress.play_session.id") ?
    // action.payload.currentUserLevelProgress.play_session?.id :
    // lesson.currentUserLevelData?.play_session?.id
  // const levelProgressExists = find(data.levelData, levelDatum => levelDatum )
  if(!toUpdateULP) {return}
  let body = buildULP(level,playSessionId, toUpdateULP);
  let updatedULP:  AxiosResponse<UserLevelData> | undefined = undefined;
  console.log("toupdatebody", body)
  try {
    updatedULP = yield axios.patch<any>(
      baseUrl+`user-level-progress?levelNumber=${level}`,
      body, {
      headers: {'Authorization': `Bearer ${jwtToken}`}
    })
  } catch(err) {
    if( err instanceof AxiosError && err?.response?.status && err.response.status == 404) {
      body = buildULP(level,playSessionId, StartingULP())
      try {
        updatedULP = yield axios.post<any>(
          baseUrl+`user-level-progress?levelNumber=${level}`,
          body, {
          headers: {'Authorization': `Bearer ${jwtToken}`}
        })
      } catch(err) {
        awsRum?.recordError(err);
        console.error(err)
      }
    } else {
      if( err instanceof AxiosError) {
        console.log(err)
        console.log("errorstatus", err?.status)
        console.log("error code", err?.code)
      }
  
      awsRum?.recordError(err);
      console.error(err)
    }
  } finally {
    if(updatedULP) {
      yield put(
        lessonActions.updateCurrentULPSuccess({next: updatedULP.data})
      )
    }
    //TODO figure out failure path (right now will probably just hang)
  }
}


function* onCurrentULPUpdate(action: UpdateCurrentULP): any {
  const data: AppState = yield select(({ mainAppReducer }) => mainAppReducer)
  const events: EventState = yield select(({ eventReducer }) => eventReducer)
  const lesson: LessonState = yield select(({lessonReducer}) => lessonReducer)
  // const { jwtToken }: AuthState = yield select(({authReducer}) => authReducer)
  const jwtToken = yield getCurrentAuthToken()
  const level = data.levelData[data.levelSelect].level_number // This will determine which tutorial we grab!
  const toUpdateULP = action.payload.currentUserLevelProgress;
  let updatedULP: AxiosResponse<UserLevelData>;
  let playSessionId
  if(has(action, 'payload.currentUserLevelProgress.play_session.id') && action.payload.currentUserLevelProgress?.play_session?.id === null) {
    playSessionId = action.payload.currentUserLevelProgress?.play_session?.id;
  } else if(!action.payload.currentUserLevelProgress?.play_session?.id) {
    playSessionId = lesson.currentUserLevelData?.play_session?.id     
  } else if(action.payload.currentUserLevelProgress?.play_session?.id) {
    playSessionId = action.payload.currentUserLevelProgress?.play_session?.id 
  }
  if(!toUpdateULP) {
    try {
      updatedULP = yield axios.post<any>(
        baseUrl+`user-level-progress?levelNumber=${level}`,
        {
          ...StartingULP(),
          level: {
            level_number: level
          },
          play_session: {
            id: playSessionId
          }
        },
        {
          headers: {'Authorization': `Bearer ${jwtToken}`}
        }
      )
      yield put(
        lessonActions.updateCurrentULPSuccess({next: updatedULP.data})
        )
      
    } catch(err) {
      if( err instanceof AxiosError) {
        awsRum?.recordError(err);
        console.error(err)
      }
    }
    return
  }

  if(toUpdateULP && Object.keys(toUpdateULP).contains('user_id')) {
    delete toUpdateULP.user_id
  }
  try {
    updatedULP = yield axios.patch<any>(
        baseUrl+`user-level-progress?levelNumber=${level}`,
        {
          level: {
            level_number: level
          },
          ...toUpdateULP,
          play_session: {
            id: playSessionId
          }
        },
        {
          headers: {'Authorization': `Bearer ${jwtToken}`}
        }
      )

    yield put(
          lessonActions.updateCurrentULPSuccess({next: updatedULP.data, prev: lesson.currentUserLevelData || undefined})
    )   
  } catch(err) {
    if( err instanceof AxiosError) {
      if(err?.response?.data?.message === "User level progress not found.") {
        updatedULP = yield axios.post<any>(
          baseUrl+`user-level-progress?levelNumber=${level}`,
          {
            ...StartingULP(),
            level: {
              level_number: level
            },
            play_session: {
              id: playSessionId
            }
          },
          {
            headers: {'Authorization': `Bearer ${jwtToken}`}
          }
        )
        // TODO: add play session id to current ulp in backend
        yield put(
          lessonActions.updateCurrentULPSuccess({next: updatedULP.data, prev: lesson.currentUserLevelData || undefined})
        )
      }
    } else {
      awsRum?.recordError(err);
      console.error(err)
    }
  }
}

function* onCurrentULPSuccess({payload}: UpdateCurrentULPSuccess) {

    const { userLevelData } = yield select(({mainAppReducer}) => mainAppReducer)
    const updatedArray = userLevelData.some((item: UserLevelData) => item.id === payload.next.id)
      ? userLevelData.map((item: UserLevelData) => item.id === payload.next.id ? payload.next : item) 
      : [...userLevelData, payload.next];
    logger.debug("updated user level progress array")
    logger.debug(updatedArray)
    yield put(appActions.fetchUserLevelsSuccess({userLevelData: updatedArray}))
    
    
}


async function getPlaySessionSummary(jwtToken: string, playSessionId: string){
  const options = {
    url: process.env.REACT_APP_BACKEND_URL + `/api/v1/play-session/summary/${playSessionId}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Authorization": `Bearer ${jwtToken}`
    },
    timeout: 5000
  }
  let response = await axios
    .request(options)
  return response['data']
}

function* onInitWrapUp(): any {
  const jwtToken = yield getCurrentAuthToken()
  const lesson:LessonState = yield select(({ lessonReducer }) => lessonReducer)
  yield put(lessonActions.setLessonProcessPending(true))
  if(lesson.currentUserLevelData?.play_session?.id ) {
    console.log("about to get session summay")
    try {
      const playSessionSummary = yield getPlaySessionSummary(jwtToken, lesson.currentUserLevelData?.play_session.id )
      console.log("play session summary", playSessionSummary)
      yield put(lessonActions.setTierSummary(playSessionSummary))
      console.log("setting show wrap up to true")
      yield put(lessonActions.setShowWrapUp(true))
      yield put(lessonActions.setWrapUpIndex(0))
      yield put(lessonActions.setLessonProcessPending(false))
    } catch(err) {
      console.log("setting summary request error to true")
      yield put(lessonActions.setTierSummaryRequestError(true))
      yield put(lessonActions.setLessonProcessPending(false))
    }
  }
}

async function postSession(jwtToken: string, body: {level: {level_number: number}, tier_number: number} | {repertoire: {id: number}}){
  return axios.post(baseUrl + 'play-session',body, {headers: {'Authorization': `Bearer ${jwtToken}`}})
}

function *createLevelSession(action: CreateLevelSession): any {
  logger.debug("create level session entered")
  const {jwtToken} = yield select(({ authReducer }) => authReducer)
  const body = {
    tier_number: action.payload.tier_number,
    level: {
      level_number: action.payload.level_number
    },
    error_recognition_mode: action.payload.error_recognition_mode 
  };
  try {
    const playSession = yield postSession(jwtToken, body)
    logger.debug("play session created", playSession?.data?.id)
    yield put(lessonActions.updateCurrentULP({
      currentUserLevelProgress: {
        current_tier: action.payload.tier_number,
        level: {
          level_number: action.payload.level_number
        },
        play_session: {id: playSession?.data?.id}
      },
    }))
    yield put(lessonActions.setTierSummary(null))
    yield put(lessonActions.createLevelSessionSuccess(action.payload))
  } catch(err) {
    console.error(err)
    if(err instanceof AxiosError && err?.response?.status && err.response.status > 499) {
      awsRum?.recordError(err)
    } else if(!(err instanceof AxiosError)) {
      awsRum?.recordError(err)
    }
  }
}

function* onCreateLevelSessionSuccess(action: CreateLevelSession) {
  // yield put(eventActions.tierStartedEventAction(action.payload.level_number, action.payload.tier_number));
}

export default [
  takeEvery(lessonActionTypes.UPDATE_CURRENT_ULP, onCurrentULPUpdate2),
  takeEvery(lessonActionTypes.INITIATE_WRAP_UP, onInitWrapUp),
  // takeEvery(EventActionTypes.DISPATCH_EVENTS_SUCCESS, onDispatchEventsSuccess),
  takeEvery(lessonActionTypes.CREATE_LEVEL_SESSION, createLevelSession),
  takeEvery(lessonActionTypes.UPDATE_CURRENT_ULP_SUCCESS, onCurrentULPSuccess),
  takeEvery(lessonActionTypes.CREATE_LEVEL_SESSION_SUCCESS, onCreateLevelSessionSuccess),
  takeEvery(actionTypes.SET_LEVEL_SELECT, onLevelSelectUpdate)
]
