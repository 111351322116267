import Phaser from 'phaser';
import {
  CurrentSceneReady,
  EventBus,
  ListenForReactEvent,
  ListenUpdateObjective,
  NotifyReact,
  ReactToExerciseEventType,
  RemoveListener,
  ShowExercise,
} from '../EventBus';
import { colors } from 'Phaser/config';
import ExerciseBase, { IObjective } from './ExerciseBase';
import Cursor, {
  StaffNote,
  StartTiming,
} from 'Phaser/GameObjects/CursorLegacy';
import Transition, { Wipe } from 'Phaser/Rendering/Transitions';
import TimeSignature from 'Models/TimeSignature';
import IPlayable from 'Phaser/GameObjects/Playable';
import { ProgressState } from 'Phaser/GameObjects/Progress';
import { INoteGraphicsDatabase } from './StaffExercise';

interface TempoObjective extends IObjective {
  numBeats: number;
  tempo: number;
  instructionWaitTime?: number;
}

export interface Config {
  paddingVertical?: number;
  paddingHorizontal?: number;
  objectives: TempoObjective[];
}

type BeatState = 'hit' | 'miss' | 'late' | 'idle';

class Beat implements IPlayable {
  isFocused: boolean = false;
  graphic: Phaser.GameObjects.Text;
  state: BeatState = 'idle';
  tweenHandle?: Phaser.Tweens.Tween;
  scene: Phaser.Scene;
  resetColorTimer?: Phaser.Time.TimerEvent;
  tempo: number;

  constructor(
    graphic: Phaser.GameObjects.Text,
    scene: Phaser.Scene,
    tempo: number
  ) {
    this.graphic = graphic;
    this.scene = scene;
    this.tempo = tempo;
  }

  getHaloOffset() {
    return 0;
  }

  getState() {
    switch (this.state) {
      case 'hit':
        return ProgressState.Ended;
      case 'late':
        return ProgressState.LateStart;
      case 'miss':
        return ProgressState.Missed;
    }
  }

  update(_: number) { }

  setColor(color: number | string) { }
  pulse() { }

  setTimingState(state: ProgressState) {
    switch (state) {
      case ProgressState.TimelyStart: {
        this.state = 'hit';
        this.graphic.setColor(colors.beatNumberFillCorrect);
        break;
      }
      case ProgressState.LateStart:
        this.state = 'late';
        this.graphic.setColor(colors.beatNumberFillOffTime);
        break;

      case ProgressState.Missed:
        this.graphic.setColor(colors.beatNumberFillMiss);
        this.state = 'miss';
        break;
      default:
        break;
    }
  }

  getPosition() {
    return {
      x: this.graphic.x,
      y: this.graphic.y,
    };
  }

  reset(forceColor: boolean = false) {
    this.isFocused = false;
    this.tweenHandle?.destroy();
    this.resetColorTimer?.remove();
    if (forceColor) this.graphic.setColor(colors.beatNumberFillQuestion);
    else {
      this.resetColorTimer = this.scene.time.delayedCall(
        2500,
        () => this.graphic.setColor(colors.beatNumberFillQuestion),
        [],
        this
      );
    }
  }

  setFocus(focus: boolean): void {
    this.resetColorTimer?.remove();
    this.isFocused = focus;
    if (!focus) return this.tweenHandle?.destroy();
    this.tweenHandle = this.scene.tweens.add({
      targets: this.graphic,
      scale: 1.3,
      x: this.graphic.x - this.graphic.width * 0.15,
      y: this.graphic.y - this.graphic.height * 0.15,
      ease: Phaser.Math.Easing.Quadratic.InOut,
      yoyo: true,
      duration: 150,
    });
  }

  shake(): void {
    this.tweenHandle?.remove();
    const target = this.graphic;
    const pos = target.x;
    const dpr = 1;
    const duration = Math.min((60 / this.tempo) * 1000, 200);
    this.tweenHandle = this.scene.tweens.add({
      targets: { value: 0 },
      value: Math.PI * 2,
      duration,
      ease: Phaser.Math.Easing.Sine.In,
      repeat: 1,
      onUpdate: (_tween, _target, _key, current) => {
        target.setX(pos + Math.sin(current) * 10 * dpr);
      },
    });
  }

  destroy() {
    this.isFocused = false;
    this.tweenHandle?.destroy();
    this.resetColorTimer?.remove();
    this.graphic?.destroy();
  }
}

export class ExerciseTempoPractice
  extends ExerciseBase<TempoObjective, Config>
  implements INoteGraphicsDatabase {
  table: Map<string, IPlayable>;
  nextEarly: boolean = false;
  config?: Config;
  cursor?: Cursor;
  playAreaSize?: { width: number; height: number; x: number; y: number };
  beatGraphics: Phaser.GameObjects.Text[];
  currentBeat: number;
  beatState: BeatState[];
  beats: Beat[];
  beatTweenHandle: Phaser.Tweens.Tween[] = [];
  lateTimer?: Phaser.Time.TimerEvent;
  missTimer?: Phaser.Time.TimerEvent;
  completionTimer?: Phaser.Time.TimerEvent;
  hitBuffer: boolean = false;
  hitBufferResetTimer?: Phaser.Time.TimerEvent;
  // running is for keeping track of the cursor's movement
  running: boolean = false;
  // started is to determine whether the exercise has started and is ready for input or not
  started: boolean = false;
  history: BeatState[] = [];
  cursorTweenHandle?: Phaser.Tweens.Tween;
  metronomeHandle?: Phaser.Time.TimerEvent;
  startTimerHandle?: Phaser.Time.TimerEvent;
  shakeHandle?: Phaser.Tweens.Tween;

  constructor() {
    super('ExerciseTempoPractice');
    this.beatGraphics = [];
    this.beatState = [];
    this.beats = [];
    this.currentBeat = -1;
    this.running = false;
    this.table = new Map();
  }

  getNoteById(id: string): IPlayable | null {
    return this.table.get(id) ?? null;
  }

  getNotePosition(id: string) {
    return this.getNoteById(id)?.getPosition().x ?? null;
  }

  getPhraseEndPosition() {
    return 0;
  }

  init(config: Config) {
    this.config = config;
    this.currentObjective = -1;
    this.setupBackground();
    this.instantiateObjects();
    EventBus.on('update-objective', this.updateObjective, this);
    super.init(config);
    //else setTimeout(this.transition.bind(this), 500);
    setTimeout(this.wipe.bind(this), 500);
  }

  skipLoadTransition() {
    //(this.cameras.main.getPostPipeline('Wipe') as Wipe).progress = 1;
    setTimeout(() => NotifyReact(ShowExercise()), 50);
  }

  transition(skip?: boolean, last?: boolean) {
    this.wipe(
      true,
      () => {
        this.clear();
        super.transition(skip, last);
      },
      this
    );
  }

  wipe(reverse?: boolean, onComplete?: () => void, ctx?: object): void {
    const targets = this.cameras.main.getPostPipeline('Wipe') as Wipe;
    this.tweens.add({
      targets,
      progress: reverse ? 0.0 : 1.0,
      duration: reverse ? 500 : 1000,
      onComplete,
      callbackScope: ctx,
    });
    console.log('wipe: wipe');
    if (!reverse) setTimeout(() => NotifyReact(ShowExercise()), 50);
  }

  updateObjective(): void {
    this.currentObjective! += 1;
    console.log('wipe: what', this.currentObjective);
    super.showInstruction(this.config!.objectives[this.currentObjective!].text ?? "Use any key to tap out the beats in sync with the tempo.");
    if (this.currentObjective! > 0) {
      this.wipe();
      this.instantiateObjects();
    }
  }

  onObjectiveComplete(): void {
    //this.transition(true, this.clear, this);
  }

  clear() {
    this.beatGraphics.forEach((g) => g.destroy());
    this.beatState.clear();
    this.history.clear();
    this.beatGraphics.clear();
    this.beats.clear();
    this.beats = [];
    this.beatGraphics = [];
    this.cursor?.destroy();
    this.started = false;
  }

  instantiateObjects() {
    console.log('instantiating');
    // Pixel ratio for retina displays. Without this, the canvas will be blurry on retina displays.
    const dpr = 1;
    const paddingH = (this.config!.paddingHorizontal ?? 50) * dpr;
    const paddingV = (this.config!.paddingVertical ?? 50) * dpr;
    this.playAreaSize = {
      width: this.cameras.main.width - paddingH * 2,
      height: this.cameras.main.height - paddingV * 2,
      x: paddingH,
      y: paddingV,
    };
    const { tempo, numBeats } =
      this.config!.objectives[Math.max(this.currentObjective ?? 0, 0)];
    const yMid = this.playAreaSize.height / 2;
    const beatWidth = this.playAreaSize.width / (numBeats + 1);
    const offset = this.playAreaSize.x;
    for (let i = 0; i < numBeats; i++) {
      const graphic = this.add.text(
        (i + 1) * beatWidth + offset + 3 * dpr,
        yMid - 30 * dpr,
        (i + 1).toString(),
        {
          fontFamily: 'Lato',
          fontStyle: 'bold',
          fontSize: 85 * dpr,
          color: colors.beatNumberFillQuestion,
        }
      );
      graphic.setX(graphic.x - graphic.displayWidth / 2);
      this.beatGraphics.push(graphic);
      this.beats.push(new Beat(graphic, this, tempo));
    }
    const beatDuration = 60000 / tempo;
    const cursorData: StaffNote[] = this.beats.map((beat) => {
      return {
        position: { x: beat.graphic.x, y: beat.graphic.y },
        durationInMs: beatDuration,
        durationRelative: 0.25,
        playable: beat,
      };
    });
    console.debug(cursorData);
    const endPos = {
      x: this.playAreaSize.width + this.playAreaSize.x,
      y: yMid - 30 * dpr,
    };
    cursorData.push({ position: endPos, durationInMs: 0, durationRelative: 0 });
    this.cursor = new Cursor(
      this,
      offset + beatWidth - this.beatGraphics[0].displayWidth / 2,
      yMid - 150 * dpr,
      45 * dpr,
      300 * dpr,
      20 * dpr,
      new TimeSignature(4, 4),
      tempo,
      cursorData
    );
  }

  midiOn(_?: any): void {
    if (this.started) {
      this.onPressKey();
    }
  }

  startExercise() {
    this.started = true;
    this.cursor?.reset();
    this.running = false;
    this.history = [];
    if (this.beats) {
      this.beats.forEach((beat) => beat.reset());
    } else {
      this.instantiateObjects();
    }
    this.currentBeat = -1;
    this.hitBuffer = false;
    this.cursor!.waitForInput();
  }

  /*nextBeat() {
    console.log("current", this.currentBeat);
    this.currentBeat += 1;
    if (this.currentBeat >= this.config!.numBeats) {
      return;
    }

    const beatLength = getDurationFromBeats(1, this.config!.tempo);
    this.missTimer = this.time.delayedCall(
      beatLength * 0.8,
      () => {
        this.setBeatState("miss");
      },
      [],
      this
    );
    if (this.getHitBuffer()) this.setBeatState("hit");
    else this.setBeatState("idle");
  }*/

  /*setBeatState(state: BeatState) {
    if (this.currentBeat >= this.config!.numBeats) return;
    if (state === "hit" || state === "late" || state === "miss") {
      this.history.push(state);
      if (this.missTimer) this.missTimer.remove();
    }
    this.beatState.push(state);
    switch (state) {
      case "hit":
        this.beatGraphics[this.currentBeat].setColor(
          colors.beatNumberFillCorrect
        );
        break;
      case "miss":
        this.beatGraphics[this.currentBeat].setColor(colors.beatNumberFillMiss);
        break;
      case "late":
        this.beatGraphics[this.currentBeat].setColor(
          colors.beatNumberFillOffTime
        );
        break;
      case "idle":
        this.beatGraphics[this.currentBeat].setColor(
          "#" + fillColorIdle.toString(16)
        );
    }
  }*/

  /*visualiseBeatGap() {
    console.log("visual");
    const horiz = [];
    const vert = [];
    for (let i = 0; i < this.config!.numBeats - 1; i++) {
      const [l, c, r] = this.createGapVisualObject(i);
      c.scaleX = 0;
      l.scaleY = 0;
      r.scaleY = 0;
      horiz.push(c);
      vert.push(l, r);
    }
    this.tweens.add({
      targets: horiz,
      scaleX: 1,
      duration: 1000,
      ease: "Power2",
      hold: 1000,
      yoyo: true,
    });
    this.tweens.add({
      targets: vert,
      scaleY: 1,
      duration: 500,
      ease: "Power2",
      hold: 1000,
      yoyo: true,
    });
  }*/

  createGapVisualObject(index: number) {
    const dpr = 1;
    const leftBeat = this.beatGraphics[index];
    const rightBeat = this.beatGraphics[index + 1];
    const padding = 10 * dpr;
    const x = leftBeat.x + leftBeat.displayWidth + padding;
    const y = leftBeat.y + leftBeat.getBounds().height / 2;
    const x2 = rightBeat.x - padding;
    const width = x2 - x;
    const height = 2 * dpr;
    const lines = [];
    lines.push(this.add.rectangle(x + height / 2, y, height, 20 * dpr, 0));
    lines.push(this.add.rectangle(x + width / 2, y, width, height, 0x00));
    lines.push(this.add.rectangle(x2 - height / 2, y, height, 20 * dpr, 0));
    return lines;
  }

  onPressKey() {
    let timing: StartTiming;
    if (this.running === false) {
      [timing] = this.cursor!.tryStart(this.onExerciseEnd, this);
      if (timing === 'early') {
        this.showToast('Too early!');
        this.beats[0]?.shake();
      } else if (timing === 'late') {
        this.showToast('Too late!');
        this.beats[0]?.shake();
      } else {
        this.beatGraphics.forEach((graphic) =>
          graphic.setColor(colors.beatNumberFillQuestion)
        );
        this.running = true;
        this.cursor?.onHoldNote();
      }
    } else {
      timing = this.cursor!.checkTiming();
      this.cursor?.onHoldNote();
    }
    /*if (this.currentBeat === -1) {
      this.nextBeat();
    }
    switch (timing) {
      case "early":
        this.setBeatState("late");
        break;
      case "late":
        this.setBeatState("late");
        break;
      case "perfect":
        this.setBeatState("hit");
        break;
    }*/
  }

  stop() {
    this.scene.stop();
  }

  update(time: number) {
    this.cursor?.update(time);
    /*
    if (this.nextEarly) {
      this.nextEarly = false;
    }
    if (
      this.running &&
      this.currentBeat >= -1 &&
      this.currentBeat < this.config!.numBeats - 1
    ) {
      if (
        this.cursor!.x + this.cursor!.width >
        this.beatGraphics[this.currentBeat + 1].x
      ) {
        this.nextBeat();
      }
    }*/
  }

  setupBackground() { }

  passObjective() {
    this.started = false;
    this.cursor?.stop();
    super.passObjective(
      this.currentObjective === this.config!.objectives.length - 1
    );
  }

  passExercise(): void {
    super.passObjective(true);
  }

  onExerciseEnd() {
    this.beats.forEach((beat) => console.log('beat state: ', beat.state));
    if (
      this.beats.find(
        (beat) =>
          beat.state === 'miss' ||
          beat.state === 'late' ||
          beat.state === 'idle'
      ) !== undefined
    ) {
      this.failExercise();
    } else {
      this.passObjective();
    }
    this.running = false;
    //EventBus.emit("exercise-ended");
  }

  unload(): void {
    console.log('unloaded tempo');
    this.clear();
    this.cursor = undefined;
    super.unload();
  }

  changeScene() {
    //nextExercise(this.config!.exerciseIndex, this.scene);
  }

  pauseExercise() {
    this.cursor?.pause();
    super.pauseExercise();
  }

  resume() {
    this.cursor?.resume();
    super.resume();
  }

  restart(): void {
    this.history.clear();
    this.startExercise();
  }

  create() {
    // This event must be emitted before using any of the integrated phaser modules such as tweens, physics, etc.
    this.cameras.main.setPostPipeline('Wipe');
    NotifyReact(CurrentSceneReady(this));
    //EventBus.on("visualise-gap", this.visualiseBeatGap, this);
    RemoveListener(ReactToExerciseEventType.UpdateObjective);
    ListenForReactEvent(ListenUpdateObjective(this.updateObjective), this);
  }
}
