import { ReactToExerciseEventType } from "Phaser/EventBus";
import Tutorial, { ChapterType, ExerciseScene } from "Types/ExerciseData";

const Level0: Tutorial =
{
  level: 0,
  videoUrl: 'level-0.mp4',
  end: '6:36',
  sections: [
    {
      chapters: [
        {
          start: '0:0',
          title: 'The Piano',
          data: [ChapterType.Lecture],
        },
        {
          start: '0:12.2',
          title: 'Highest and Lowest Keys',
          data: [
            ChapterType.Exercise,
            {
              scene: [
                ExerciseScene.PianoScene,
                {
                  transitionDelay: 2400,
                  textPromptPosition: 'Bottom',
                  objectives: [
                    {
                      text: 'Play the key furthest to the left on your piano.',
                      target: 'any',
                      videoCompleteDestination: '0:18',
                    },
                    {
                      text: 'Play the key furthest to the right on your piano.',
                      target: 'any',
                      videoCompleteDestination: '0:24.2',
                    },
                  ],
                },
              ],
              events: [
                {
                  at: '0:15',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  at: '0:17',
                  pause: true,
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                },
                {
                  at: '0:20.5',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  at: '0:22',
                  pause: true,
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      chapters: [
        {
          start: '0:26',
          title: 'Counting Beats',
          data: [ChapterType.Lecture],
        },
        {
          start: '0:56',
          title: 'Tempo',
          data: [ChapterType.Lecture],
        },
        {
          start: '1:26',
          title: 'Playing Beats in Time',
          data: [
            ChapterType.Exercise,
            {
              scene: [
                ExerciseScene.ExerciseTempoPractice,
                {
                  objectives: [
                    {
                      videoSkipDestination: '1:34.8',
                      videoCompleteDestination: '1:34',
                      numBeats: 8,
                      tempo: 72,
                      text: 'Use any key to tap out 8 beats in sync with the tempo.',
                      instructionWaitTime: 5,
                    },
                    {
                      videoCompleteDestination: '1:43',
                      videoSkipDestination: '1:44',
                      numBeats: 8,
                      tempo: 120,
                      text: 'Use any key to tap out 8 beats in sync with the tempo.',
                      instructionWaitTime: 5,
                    },
                  ],
                },
              ],
              events: [
                {
                  at: '1:30.5',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  at: '1:33.5',
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                  pause: true,
                },
                {
                  at: '1:35.5',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  at: '1:37',
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                  pause: true,
                },
                {
                  at: '1:40',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  at: '1:41.5',
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                  pause: true,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      chapters: [
        {
          start: '1:44',
          title: 'Music Notes',
          data: [ChapterType.Lecture],
        },
        {
          start: '2:26',
          title: 'Quarter Notes',
          data: [
            ChapterType.Exercise,
            {
              scene: [
                ExerciseScene.NoteSequenceExercise,
                {
                  transitionDelay: 2400,
                  tempo: 60,
                  objectives: [
                    {
                      scale: 1.4,
                      text: 'Play four quarter notes in a row, using any key.',
                      videoSkipDestination: '2:36',
                      videoCompleteDestination: '2:34.5',
                      notes: [
                        { length: 'quarter' },
                        { length: 'quarter' },
                        { length: 'quarter' },
                        { length: 'quarter' },
                      ],
                    },
                  ],
                },
              ],
              events: [
                {
                  at: '2:30',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  at: '2:33.5',
                  pause: true,
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                },
              ],
            },
          ],
        },
        {
          start: '2:37',
          title: 'Half Notes',
          data: [ChapterType.Lecture],
        },
        {
          start: '2:49',
          title: 'Half Notes',
          data: [
            ChapterType.Exercise,
            {
              scene: [
                ExerciseScene.NoteSequenceExercise,
                {
                  tempo: 60,
                  objectives: [
                    {
                      scale: 1.4,
                      text: 'Play three half notes in a row, using any key.',
                      videoSkipDestination: '3:02',
                      videoCompleteDestination: '3:01',
                      notes: [
                        { length: 'half' },
                        { length: 'half' },
                        { length: 'half' },
                      ],
                    },
                  ],
                },
              ],
              events: [
                {
                  at: '2:55',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  at: '3:00',
                  pause: true,
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                },
              ],
            },
          ],
        },
        {
          start: '3:03',
          title: 'Whole Notes',
          data: [ChapterType.Lecture],
        },
        {
          start: '3:17',
          title: 'Whole Notes',
          data: [
            ChapterType.Exercise,
            {
              scene: [
                ExerciseScene.NoteSequenceExercise,
                {
                  tempo: 60,
                  paddingHorizontal: 150,
                  objectives: [
                    {
                      scale: 1.4,
                      text: 'Play two whole notes in a row, using any key.',
                      videoSkipDestination: '3:26',
                      videoCompleteDestination: '3:25',
                      notes: [{ length: 'whole' }, { length: 'whole' }],
                    },
                  ],
                },
              ],
              events: [
                {
                  at: '3:20',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  at: '3:24',
                  pause: true,
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      chapters: [
        {
          start: '3:26',
          title: 'The Metronome',
          data: [ChapterType.Lecture],
        },
        {
          start: '3:51',
          title: 'Combining Rhythms',
          data: [
            ChapterType.Exercise,
            {
              scene: [
                ExerciseScene.NoteSequenceExercise,
                {
                  tempo: 72,
                  objectives: [
                    {
                      videoSkipDestination: '4:01',
                      videoCompleteDestination: '4:00',
                      scale: 0.8,
                      text: 'Use any key to play these notes in time with the metronome',
                      notes: [
                        { length: 'half' },
                        { length: 'half' },
                        { length: 'quarter' },
                        { length: 'quarter' },
                        { length: 'half' },
                      ],
                    },
                    {
                      videoSkipDestination: '4:04.5',
                      videoCompleteDestination: '4:04',
                      scale: 0.8,
                      text: 'Use any key to play these notes in time with the metronome',
                      notes: [
                        { length: 'quarter' },
                        { length: 'quarter' },
                        { length: 'quarter' },
                        { length: 'quarter' },
                        { length: 'half' },
                        { length: 'half' },
                      ],
                    },
                    {
                      videoSkipDestination: '4:10',
                      videoCompleteDestination: '4:08',
                      scale: 0.8,
                      text: 'Use any key to play these notes in time with the metronome',
                      notes: [
                        { length: 'quarter' },
                        { length: 'half' },
                        { length: 'quarter' },
                        { length: 'whole' },
                      ],
                    },
                  ],
                },
              ],
              events: [
                {
                  at: '3:56',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  pause: true,
                  at: '3:59',
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                },
                {
                  at: '4:01.5',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  pause: true,
                  at: '4:03',
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                },
                {
                  at: '4:05.5',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  pause: true,
                  at: '4:07',
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      chapters: [
        {
          start: '4:10',
          title: 'The Grand Staff',
          data: [ChapterType.Lecture],
        },
        {
          start: '5:13',
          title: 'Reading Notes on the Staff',
          data: [ChapterType.Lecture],
        },
        {
          start: '5:39',
          title: 'Counting Bars',
          data: [ChapterType.Lecture],
        },
        {
          start: '5:59',
          title: 'Time Signatures',
          data: [ChapterType.Lecture],
        },
      ],
    },
  ],
};

export default Level0;
