import { UserLevelData } from 'Types';
import { 
  lessonActionTypes, 
  SetLevelNumber, 
  SetTierNumber, 
  SetNumberOfTiers, 
  SetTierSummary, 
  SetLessonProcessPending, 
  SetAllowChevrons,
  WrapUpModalPage,
  PlaySessionData,
  InitiateWrapUp,
  GetTierSummary,
  LevelSessionPayload,
  setLessonPlayingPayload,
  UpdateCurrentULPPayload,
  CreateLevelSession,
  SetShowWrapUp,
  SetWrapUpIndex,
  SetCurrentCompletedAt,
  ResetLesson,
  UpdatePhrasesTilNextTier,
  UpdateCurrentLevelTiers,
  UpdateCurrentULPSuccess,
  UpdateCurrentULP,
  SetLessonPlaying,
  CreateLevelSessionSuccess,
  SetTierSummarRequestError
  
} from 'Types/LessonTypes';

export const initiateWrapUp = (): InitiateWrapUp => ({
  type: lessonActionTypes.INITIATE_WRAP_UP,
});

export const setLevelNumber = (payload: number): SetLevelNumber => ({
  type: lessonActionTypes.SET_LEVEL_NUMBER,
  payload
});

export const setTierNumber = (payload: number): SetTierNumber => ({
  type: lessonActionTypes.SET_TIER_NUMBER,
  payload
});

export const setNumberOfTiers = (payload: number): SetNumberOfTiers => ({
  type: lessonActionTypes.SET_NUMBER_OF_TIERS,
  payload
});

export const setTierSummary = (payload: PlaySessionData | null): SetTierSummary => ({
  type: lessonActionTypes.SET_TIER_SUMMARY,
  payload
});

export const getTierSummary = (): GetTierSummary => ({
  type: lessonActionTypes.GET_TIER_SUMMARY,
});

export const setTierSummaryRequestError = (payload: boolean): SetTierSummarRequestError => ({
  type: lessonActionTypes.SET_TIER_SUMMARY_REQUEST_ERROR,
  payload
});

export const setShowWrapUp = (payload: boolean): SetShowWrapUp => ({
  type: lessonActionTypes.SET_SHOW_WRAP_UP,
  payload
});

export const setWrapUpIndex = (payload: number): SetWrapUpIndex => ({
  type: lessonActionTypes.SET_WRAP_UP_INDEX,
  payload
});

export const setLessonProcessPending = (payload: boolean): SetLessonProcessPending => ({
  type: lessonActionTypes.SET_LESSON_PROCESS_PENDING,
  payload
});

export const setAllowChevrons = (payload: boolean): SetAllowChevrons => ({
  type: lessonActionTypes.SET_ALLOW_CHEVRONS,
  payload
});

export const createLevelSession = (payload: LevelSessionPayload): CreateLevelSession => ({
  type: lessonActionTypes.CREATE_LEVEL_SESSION,
  payload
})

export const createLevelSessionSuccess = (payload: LevelSessionPayload): CreateLevelSessionSuccess => ({
  type: lessonActionTypes.CREATE_LEVEL_SESSION_SUCCESS
})

export const setLessonPlaying = (payload: setLessonPlayingPayload): SetLessonPlaying => ({
  type: lessonActionTypes.SET_LESSON_PLAYING,
  payload
})

export const updateCurrentULP = (payload: UpdateCurrentULPPayload): UpdateCurrentULP => ({
  type: lessonActionTypes.UPDATE_CURRENT_ULP,
  payload
})

export const updateCurrentULPSuccess = (payload: {next: UserLevelData, prev?: UserLevelData}): UpdateCurrentULPSuccess => ({
  type: lessonActionTypes.UPDATE_CURRENT_ULP_SUCCESS,
  payload
})

export const updateCurrentLevelTiers = (payload: number): UpdateCurrentLevelTiers => ({
  type: lessonActionTypes.UPDATE_CURRENT_LEVEL_TIERS,
  payload
})

export const resetLesson = (): ResetLesson => ({
  type: lessonActionTypes.RESET_LESSON,
  payload: null
})

export const updatePhrasesTilNextTier = (payload: number): UpdatePhrasesTilNextTier=> ({
  type: lessonActionTypes.UPDATE_PHRASES_TIL_NEXT_TIER,
  payload
})

export const setCurrentCompletedAt = (payload: Date | null): SetCurrentCompletedAt => ({
  type: lessonActionTypes.SET_CURRENT_COMPLETED_AT,
  payload
})