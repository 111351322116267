import { ERROR_RECOGNITION_MODE, LevelData } from "Types";
export type UserRepertoireData = {
  
}

export enum RepertoireActionTypes {
  UPDATE_REPERTOIRE_DATA_REQUEST = "UPDATE_REPERTOIRE_DATA_REQUEST",
  FETCH_REPERTOIRE_DATA_SUCCESS = "FETCH_REPERTOIRE_DATA_SUCCESS",
  UPDATE_REPERTOIRE_DATA_LOCAL = "UPDATE_REPERTOIRE_DATA_LOCAL",
  SET_REPERTOIRE_ENDED = "SET_REPERTOIRE_ENDED",
  SET_REPERTOIRE_SELECT = "SET_REPERTOIRE_SELECT",
  FETCH_URP_SUCCESS = "FETCH_URP_SUCCESS",
  LOAD_REPERTOIRE_STATE = "LOAD_REPERTOIRE_STATE",
  CREATE_REPERTOIRE_SESSION = "CREATE_REPERTOIRE_SESSION",
  CREATE_REPERTOIRE_SESSION_SUCCESS = "CREATE_REPERTOIRE_SESSION_SUCCESS",
  SET_REPERTOIRE_PLAYING = "SET_REPERTOIRE_PLAYING",
  SET_PLAY_SESSION_ID = "SET_PLAY_SESSION_ID",
  SET_CURSOR_VISIBLE = "SET_CURSOR_VIBLE"
}

export type Genre = {
  id: number;
  name: string;
}
export type Composer = {
  id: number;
  name: string;
}
export type Artist = {
  id: number;
  name: string;
}
export type Arranger = {
  id: number;
  name: string;
}

export type RepertoireData = {
  id: number;
  name: string;
  bpm: number;
  level: LevelData;
  genres: Genre[];
  artists: Artist[]
  arranger: Arranger[]
  composers: Composer[];
  repertoire_png_preview_url: string
}

export type RepertoireDataId = {
  id: number;
}

export type UserRepertoireProgress = {
  id?: number;
  user_id?: string;
  status: string;
  repertoire: RepertoireDataId;
  last_played_at: string;
  completed_at: string | null;
  accuracy: number | null;
  percent_complete: number | null;
  last_tempo?: number | null | undefined;
}

export interface SetRepertoireSelectPayload {
  repertoireIdSelect: number;
}

export type RepertoireSessionPayload = {
  repertoire_id: number
  error_recognition_mode: ERROR_RECOGNITION_MODE
}

export enum actionTypes {
  CREATE_REPERTOIRE_SESSION = "CREATE_REPERTOIRE_SESSION",
  UPDATE_REPERTOIRE_DATA_REQUEST = "UPDATE_REPERTOIRE_DATA_REQUEST",
  FETCH_REPERTOIRE_DATA_SUCCESS = "FETCH_REPERTOIRE_DATA_SUCCESS",
  UPDATE_REPERTOIRE_DATA_LOCAL = "UPDATE_REPERTOIRE_DATA_LOCAL",
  SET_REPERTOIRE_ENDED = "SET_REPERTOIRE_ENDED",
  SET_REPERTOIRE_SELECT = "SET_REPERTOIRE_SELECT",
  FETCH_URP_SUCCESS = "FETCH_URP_SUCCESS",
  LOAD_REPERTOIRE_STATE = "LOAD_REPERTOIRE_STATE",
  SET_CURSOR_VISIBLE = "SET_CURSOR_VISIBLE"
}

export interface SetRepertoirePlayingPayload {
  repertoirePlaying: boolean;
}

export type CreateRepertoireSession = {
  type: typeof RepertoireActionTypes.CREATE_REPERTOIRE_SESSION;
  payload: RepertoireSessionPayload
}

export type CreateRepertoireSessionSuccess = {
  type: typeof RepertoireActionTypes.CREATE_REPERTOIRE_SESSION_SUCCESS;
}

export enum REPERTOIRE_PROGRESS {
  COMPLETE = "complete",
  IN_PROGRESS = "in progress",
  NOT_STARTED = "not started",
}

export interface RepertoireState {
  repertoireData?: RepertoireData[],
  userRepertoireProgresses: UserRepertoireProgress[],
  repertoireIdSelect: number;
  repertoirePlaying: boolean;
  repertoireEnded: boolean;
  cursorVisible: boolean;
  playSessionId: string | null;
}

export type UpdateRepertoireDataRequest = {
  type: typeof RepertoireActionTypes.UPDATE_REPERTOIRE_DATA_REQUEST;
  payload: RepertoireData
}

export type FetchRepertoireDataSuccess = {
  type: typeof RepertoireActionTypes.FETCH_REPERTOIRE_DATA_SUCCESS;
  payload: RepertoireData[]
}

export type FetchRepertoireDataLocal = {
  type: typeof RepertoireActionTypes.UPDATE_REPERTOIRE_DATA_LOCAL;
  payload: Partial<UserRepertoireProgress>
}

export type SetRepertoireEnded = {
  type: typeof RepertoireActionTypes.SET_REPERTOIRE_ENDED;
  payload: boolean;
}

export type SetRepertoireSelect = {
  type: typeof RepertoireActionTypes.SET_REPERTOIRE_SELECT;
  payload: SetRepertoireSelectPayload;
}

export type FetchURPSuccess = {
  type: typeof RepertoireActionTypes.FETCH_URP_SUCCESS;
  payload: UserRepertoireProgress[]
}

export type LoadRepertoireState = {
  type: typeof RepertoireActionTypes.LOAD_REPERTOIRE_STATE;
  payload: RepertoireData
}

export type SetRepertoirePlaying = {
  type: typeof RepertoireActionTypes.SET_REPERTOIRE_PLAYING;
  payload: SetRepertoirePlayingPayload;
}

export type SetPlaySessionId = {
  type: typeof RepertoireActionTypes.SET_PLAY_SESSION_ID
  payload: string;
}


export type SetCursorVisible = {
  type: typeof RepertoireActionTypes.SET_CURSOR_VISIBLE;
  payload: boolean
}


export interface RepertoireReducer {
  repertoireReducer: RepertoireState,
}


export type RepertoireActions =
  | UpdateRepertoireDataRequest
  | FetchRepertoireDataSuccess
  | FetchRepertoireDataLocal
  | FetchURPSuccess
  | SetRepertoireEnded
  | SetRepertoireSelect
  | LoadRepertoireState
  | CreateRepertoireSession
  | CreateRepertoireSessionSuccess
  | SetRepertoirePlaying
  | SetPlaySessionId
  | SetCursorVisible
