import { ReactToExerciseEventType } from "Phaser/EventBus";
import Tutorial, { ChapterType, ExerciseScene } from "Types/ExerciseData";

const Level3: Tutorial = {
  end: '2:46',
  level: 3,
  videoUrl: 'level-3.mp4',
  sections: [
    {
      chapters: [
        {
          title: 'B in the Bass Clef',
          start: '0:0',
          data: [ChapterType.Lecture],
        },
        {
          title: 'B on the Keyboard',
          start: '0:25',
          data: [ChapterType.Lecture],
        },
        {
          title: 'Find B',
          start: '0:39',
          data: [
            ChapterType.Exercise,
            {
              scene: [
                ExerciseScene.PianoScene,
                {
                  transition: 'Dissolve',
                  textPromptPosition: 'Top',
                  objectives: [
                    {
                      text: 'Play B on your keyboard.',
                      target: 'B3',
                      videoCompleteDestination: '0:44',
                    },
                  ],
                },
              ],
              events: [
                {
                  at: '0:40.5',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  at: '0:42',
                  pause: true,
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      chapters: [
        {
          title: 'Playing with the 2nd Finger',
          start: '0:46',
          data: [ChapterType.Lecture],
        },
        {
          title: 'Playing B',
          start: '0:56',
          data: [
            ChapterType.Exercise,
            {
              scene: [
                ExerciseScene.NoTimeExercise,
                {
                  centre_align_horizontal: false,
                  padding_x: 650,
                  firstObjectiveAppearDelay: 1500,
                  objectives: [
                    {
                      videoSkipDestination: '1:09',
                      videoCompleteDestination: '1:07',
                    },
                  ],
                },
              ],
              events: [
                {
                  at: '0:59',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  at: '1:01',
                  pause: true,
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                },
              ],
            },
          ],
        },
        {
          title: 'Alternating Fingers',
          start: '1:09',
          data: [ChapterType.Lecture],
        },
        {
          title: 'Playing B and C',
          start: '1:29.5',
          data: [
            ChapterType.Exercise,
            {
              scene: [
                ExerciseScene.NoTimeExercise,
                {
                  centre_align_horizontal: true,
                  objectives: [
                    {
                      videoSkipDestination: '1:40.5',
                      videoCompleteDestination: '1:39.5',
                    },
                  ],
                },
              ],
              events: [
                {
                  at: '1:31',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  at: '1:32.5',
                  pause: true,
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                },
              ],
            },
          ],
        },
        {
          title: 'Playing in Time',
          start: '1:41',
          data: [
            ChapterType.Exercise,
            {
              scene: [
                ExerciseScene.StaffExercise,
                {
                  objectives: [
                    {
                      tempo: 72,
                      videoCompleteDestination: '1:49',
                      videoSkipDestination: '1:50',
                    },
                    {
                      tempo: 72,
                      videoCompleteDestination: '1:53.5',
                      videoSkipDestination: '1:54.5',
                    },
                  ],
                },
              ],
              events: [
                {
                  at: '1:43',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  at: '1:46.5',
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                  pause: true,
                },
                {
                  at: '1:51',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  at: '1:52',
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                  pause: true,
                },
              ],
            },
          ],
        },
        {
          title: 'Using Both Hands',
          start: '1:55',
          data: [ChapterType.Lecture],
        },
        {
          title: 'Switching Hands',
          start: '2:30.5',
          data: [
            ChapterType.Exercise,
            {
              scene: [
                ExerciseScene.StaffExercise,
                {
                  firstObjectiveAppearDelay: 500,
                  objectives: [
                    {
                      tempo: 72,
                      videoCompleteDestination: '2:37',
                      videoSkipDestination: '2:38',
                    },
                    {
                      tempo: 72,
                      videoCompleteDestination: '2:42',
                      videoSkipDestination: '2:44',
                    },
                  ],
                },
              ],
              events: [
                {
                  at: '2:33',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  at: '2:35',
                  pause: true,
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                },
                {
                  at: '2:39.5',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  at: '2:41',
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                  pause: true,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
export default Level3;
