import { Box, SxProps } from "@mui/material"
import { ReactNode } from "react"
import RoadmapTierComplete from 'assets/images/RoadmapTierComplete.png'
import { mediaQueryValues } from "Utils/MediaQueries";
import { useMediaQueries } from "Hooks/useMediaQueries";

const Background = () => (
  <Box sx={{position: 'absolute', left: '0px', top:'0px', width: '100%', height: '100%', zIndex: -100}}>
    <img src={RoadmapTierComplete} style={{width: '100%', height: '100%'}}/>
  </Box>
)


const Modal = ({children, sx }:{children?: ReactNode, sx?: SxProps}) => {
  const {maxHeight} = useMediaQueries()
  const modalStyles = {
    position: 'absolute',
    width: '60%',
    minHeight: '600px',
    height: '60%',
    top: maxHeight == mediaQueryValues.minHeight750 ? '45%': '50%',
    left: '50%',
    transform: 'translate(-50%, -45%)',
    zIndex: 999,
    background: 'linear-gradient(229.67deg, #1D2F44 -6.17%, #071423 89.04%)',
    borderRadius: '8px',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center', 
    justifyItems: 'center',
    minWidth: '1200px',
    maxWidth: '1300px',
  }
  return (
    <Box
      sx={Object.assign(modalStyles, sx)}
    >
      <Background/>
      {children}
    </Box>
  )
}
export default Modal