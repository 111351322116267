import { Boot } from './Scenes/Boot';
import { AUTO, Game } from 'phaser';
import { Preloader } from './Scenes/Preloader';
import NoteSequenceExercise from './Scenes/NoteSequenceExercise';
import { ExerciseTempoPractice } from './Scenes/ExerciseTempoPractice';
import { PianoScene } from './Scenes/Piano';
import { TestExercise } from './Scenes/TestExercise';
import PauseMenu from './Scenes/PauseMenu';
import { Dissolve, Wipe } from './Rendering/Transitions';
import Empty from './Scenes/Empty';
import StaffExercise from './Scenes/StaffExercise';
import NoTimeExercise from './Scenes/NoTime';
import SightReadingMobile from './Scenes/SightReadingMobile';

const StartGame = (parent: string, width: number, height: number) => {
  //  Find out more information about the Game Config at:
  //  https://newdocs.phaser.io/docs/3.70.0/Phaser.Types.Core.GameConfig
  const dpr = window.devicePixelRatio;
  console.log('dpr: ', dpr);
  const config: Phaser.Types.Core.GameConfig = {
    type: AUTO,
    antialias: true,
    scale: {
      mode: Phaser.Scale.FIT,
      width: width,
      height: height,
    },
    transparent: true,
    width,
    height,
    parent,
    autoRound: true,
    antialiasGL: true,
    scene: [
      Boot,
      Preloader,
      NoteSequenceExercise,
      ExerciseTempoPractice,
      PianoScene,
      TestExercise,
      PauseMenu,
      StaffExercise,
      NoTimeExercise,
      Empty,
      SightReadingMobile,
    ],
    pipeline: {
      Dissolve: Dissolve,
      Wipe: Wipe,
    } as any,
  };
  return new Game(config);
};

export default StartGame;
