//import { Scene } from 'phaser';

import TimeKeeper from 'Models/TimeKeeper';
import {
  CurrentSceneReady,
  ListenForReactEvent,
  ListenPulseNote,
  ListenSetNoteState,
  ListenUpdateObjective,
  NotifyReact,
  ReactToExerciseEventType,
  RemoveListener,
  ShowExercise,
} from 'Phaser/EventBus';
import CursorMobile, {
  EventStreamPhaser2,
  IteratorExtensible,
} from 'Phaser/GameObjects/CursorMobile';
import IPlayable from 'Phaser/GameObjects/Playable';
import {
  CursorData,
  PhraseData,
  PhraseGraphic,
} from 'Phaser/GameObjects/StaffLine';
import ExerciseBase, { IConfig, IObjective } from './ExerciseBase';
import { NoteType } from 'opensheetmusicdisplay';
import { TimingOffsetsConfig } from 'Models/EventStream';
import TimeSignature from 'Models/TimeSignature';
import * as Tone from 'tone';
import Phrase, { Iterator } from 'Models/Phrase';
import { ProgressState } from 'Phaser/GameObjects/Progress';
import MusicXML from 'Models/MusicXML';

export class MusicXMLDummy extends MusicXML {
  // we don't really have a need for the MusicXML currently
  // TODO: This is a stinky hack. Try to think of a better
  // way of dealing with this.
  static getElements() {
    return [];
  }
}

export interface INoteGraphicsDatabase {
  getNoteById: (id: string) => IPlayable | null;
  getPhraseEndPosition: () => number;
  getNotePosition: (id: string) => number | null;
}

export type PhraseObjectiveData = {
  cursorData: CursorData;
  uuid: string;
  phrase: Phrase;
  timeSignature: TimeSignature;
}

interface Objective extends IObjective {
  tempo: number;
  paddingHorizontal?: number;
  phraseDetails?: { svgUrl: string; jsonUrl: string; id: string }[];
  // This is set by the parent component (after fetching the cursor data
  // from the backend) and not in the configuration.
  // TODO: Refactor this type and its dependants to make that fact more apparent
  phrases?: PhraseObjectiveData[];
}

export interface Config extends IConfig<Objective> {
  transition?: 'Wipe' | 'Dissolve';
}

enum PlayingState {
  // The cursor blinks while waiting for the user to play the first note.
  WaitingForInput,
  // Playback has started and the cursor is moving through the phrase(s).
  Playing,
  // Playback has ended or has not yet been started; The cursor is not visible.
  Stopped,
}

export default class StaffExercise
  extends ExerciseBase<Objective, Config>
  implements INoteGraphicsDatabase {
  static METRONOME_SCHEDULE_AHEAD_TIME = 0;

  staffLines: PhraseGraphic[] = [];
  cursor?: CursorMobile;
  timers: Phaser.Time.TimerEvent[] = [];

  eventStream?: EventStreamPhaser2;
  timeKeeper: TimeKeeper;
  lastMeasure?: number;

  clickAudio?: Phaser.Sound.BaseSound;
  lastTickTime?: number;

  playingState: PlayingState = PlayingState.Stopped;

  constructor() {
    super('StaffExercise');
    this.timeKeeper = new TimeKeeper();
  }

  create() {
    NotifyReact(CurrentSceneReady(this));
  }

  metronomeClick() {
    if (this.playingState === PlayingState.Stopped) return;
    this.clickAudio!.stop();
    this.clickAudio!.play();
  }

  update() {
    if (this.paused || this.currentObjective === undefined) return;
    if (this.playingState !== PlayingState.Stopped) {
      const timestamp = this.timeKeeper.audioTimeToMeasureTimestamp();
      const { tempo } = this.config!.objectives[this.currentObjective];
      if (!this.lastTickTime)
        this.lastTickTime =
          timestamp - StaffExercise.METRONOME_SCHEDULE_AHEAD_TIME;

      // TODO: replace the divisor with the numerator in the Time Signature for this phrase.
      const beatFraction = 1 / 4;
      const measureDurationSinceLastTickTime = timestamp - this.lastTickTime;
      if (this.playingState === PlayingState.Playing) {
        // Check for the highest measure played in full till now
        this.lastMeasure = Math.max(0, Math.floor(timestamp) - 1);
        this.cursor?.update();
        this.eventStream?.update();
      }
      if (measureDurationSinceLastTickTime > beatFraction) {
        this.metronomeClick();
        if (this.playingState === PlayingState.WaitingForInput) {
          const beatDuration = (60 / tempo) * 1000;
          this.cursor?.blink(beatDuration);
        }
        this.lastTickTime = timestamp - (timestamp - this.lastTickTime - 0.25);
      }
    }
  }

  updateObjective(_: number) {
    this.currentObjective =
      this.currentObjective !== undefined ? this.currentObjective + 1 : 0;
    super.showInstruction(this.config!.objectives[this.currentObjective].text ?? "Play this line of music in sync with the metronome.");
    if (this.currentObjective > 0) this.loadCurrentObjective();
  }

  pauseExercise() {
    this.lastTickTime = undefined;
    this.timeKeeper.pause();
    console.log('pausing');
    super.pauseExercise();
  }

  resume() {
    Tone.start();
    if (this.currentObjective !== undefined) {
      this.timeKeeper.resetTime();
      this.timeKeeper?.unpause(
        this.config!.objectives[this.currentObjective].tempo,
        1,
        this.lastMeasure ? this.lastMeasure + 1 : 0,
        NoteType._32nd
      );
    }
    super.resume();
  }

  init(config: Config) {
    this.sound.pauseOnBlur = true;
    this.playingState = PlayingState.Stopped;
    NotifyReact(CurrentSceneReady(this));
    super.init(config);
    this.clickAudio = this.sound.add('click');
    if (this.config!.firstObjectiveAppearDelay) {
      this.timers.push(
        this.time.delayedCall(
          this.config!.firstObjectiveAppearDelay,
          this.loadCurrentObjective,
          [],
          this
        )
      );
    } else this.loadCurrentObjective();
    ListenForReactEvent(
      ListenUpdateObjective(() => this.updateObjective(Math.random()))
    );
    ListenForReactEvent(
      ListenSetNoteState(this.setNoteState),
      this,
      false,
      false
    );
    ListenForReactEvent(ListenPulseNote(this.pulseNote), this, false, false);
    this.transitionIn();
  }

  transitionIn() {
    setTimeout(() => NotifyReact(ShowExercise()), 50);
  }

  loadCurrentObjective() {
    this.staffLines.forEach((staff) => staff?.destroy());
    this.staffLines.clear();
    let x = 0;
    console.debug(this.config, this.currentObjective);
    this.staffLines = this.config!.objectives[
      this.currentObjective ?? 0
    ].phrases!.map(({ cursorData, uuid, timeSignature }, idx) => {
      const data = new PhraseData(idx, cursorData, uuid, timeSignature, true, true);
      console.debug('constructed data: ', data);
      return new PhraseGraphic(
        this,
        x,
        {
          globalScaling: 1,
          pitchLineGap: 20,
          pitchLineWidth: 2,
          measureLineWidth: 3,
          phraseWidth: 1660,
          staffSpacingVertical: 100,
          centreAlign: true,
        },
        data
      );
    });
  }

  getNoteById(id: string): IPlayable | null {
    for (const staff of this.staffLines) {
      const found = staff.getNoteById(id);
      if (found) return found;
    }
    return null;
  }

  setNoteState({ id, state }: { id: string; state: ProgressState }) {
    this.getNoteById(id)?.setTimingState(state);
  }

  pulseNote({ id }: { id: string }) {
    this.getNoteById(id)?.pulse();
  }

  stop() {
    this.tweens.killAll();
    this.scene.stop();
  }

  onExerciseEnd() {
    console.log('evaluateing');
    let fail = false;
    /*this.table.forEach((val) => {
      console.debug("state", val.timingState, val.isRest);
      if (!val.isRest && val.timingState !== ProgressState.Ended) {
        fail = true;
      }
    });*/
    //fail = false;
    if (fail) this.failExercise();
    else this.passObjective();
  }

  onExerciseFail() {
    this.startExercise();
  }

  passObjective() {
    super.passObjective(
      this.currentObjective === (this.config?.objectives.length ?? 1) - 1
    );
  }

  playNote(note: number, velocity: number) {
    if (this.paused) {
      return;
    }
    if (this.currentObjective === undefined || this.currentObjective < 0) {
      console.debug(this.currentObjective);
      console.error(`objective undefined at index: ${this.currentObjective}`);
      return;
    }
    const { tempo, phrases } = this.config!.objectives[this.currentObjective];
    if (phrases === undefined) {
      console.error(`no phrases defined for the current objective: index ${this.currentObjective}`);
      return;
    }
    switch (this.playingState) {
      case PlayingState.WaitingForInput: {
        const timestamp = this.timeKeeper.audioTimeToMeasureTimestamp();
        if (!this.lastTickTime) return;
        const timeDiff = timestamp - this.lastTickTime;
        if (timeDiff > 0.03 && timeDiff < 0.125) {
          this.showToast('Too Late!');
        } else if (timeDiff > 0.125 && timeDiff < 0.23) {
          this.showToast(`Too early!`);
        } else {
          const inaccCompensation = timeDiff > 0.125 ? timeDiff - 0.25 : timeDiff;
          this.startPlaying(tempo, note, velocity, phrases[0].timeSignature, inaccCompensation);
        }
        break;
      }
      case PlayingState.Playing:
        this.eventStream?.handleNoteOnEvent(note, velocity);
        break;
      default:
        break;
    }
  }

  startPlaying(bpm: number, note: number, velocity: number, timeSig: TimeSignature, inaccCompensation: number) {
    this.lastTickTime = undefined;
    this.playingState = PlayingState.Playing;
    this.cursor?.unpause();
    const secondsPerMeasure = (60 / bpm) * timeSig.numerator;
    const measureOffset = (TimeKeeper.METRONOME_SCHEDULE_AHEAD_TIME / secondsPerMeasure) + inaccCompensation;
    this.timeKeeper.unpause(bpm, 0, measureOffset, NoteType._32nd);
    this.eventStream?.handleNoteOnEvent(note, velocity);
  }

  startExercise(): void {
    this.lastTickTime = undefined;
    this.playingState = PlayingState.WaitingForInput;
    const timeSig =
      this.config!.objectives[this.currentObjective ?? 0].phrases![0]
        .timeSignature;
    this.timeKeeper.setTimeSignatures(0, [timeSig]);
    this.timeKeeper.resetTime();
    this.eventStream = new EventStreamPhaser2(
      this.timeKeeper!,
      new TimingOffsetsConfig(),
      null
    );
    const { phrase } =
      this.config!.objectives[this.currentObjective ?? 0].phrases![0]!;
    const phraseGraphic = this.staffLines[0];
    const height = phraseGraphic.getHeight() + 100 * 1;
    const iterator = new IteratorExtensible(this, [], phrase);
    this.cursor = new CursorMobile(
      this,
      phraseGraphic.getFirstNotePosition(),
      this.cameras.main.height / 2 - height / 2,
      40 * 1,
      height,
      this.eventStream,
      iterator,
      false,
      this.onFinishPlaying,
      this
    );
    this.eventStream?.addEvents2(
      new Iterator(phraseGraphic._data.serializedIterator, phrase),
      0
    );
    this.cursor.addPhrase(phraseGraphic._data.serializedIterator);
    this.timeKeeper.resetTime();
    this.timeKeeper.unpause(72, 0, 0, NoteType._64th);
    Tone.start();
  }

  onFinishPlaying() {
    this.cursor?.destroy();
    this.playingState = PlayingState.Stopped;
    const success = this.staffLines.reduce(
      (prev, curr) => prev && curr.checkAllNotes(),
      true
    );
    if (success) {
      this.passObjective();
    } else {
      this.failExercise("Try Again!");
    }
  }

  transition(skip?: boolean, last?: boolean) {
    //super.transition(skip, last);
    this.tweens.add({
      targets: [this.staffLines],
      alpha: 0,
      duration: 500,
      onComplete: () => super.transition(skip, last),
      callbackScope: this,
    });
    /*let notes = [];
    for (const note of this.table.values()) {
      notes.push(note);
    }
    this.tweens.add({
      targets: [this.staffGraphic, ...notes],
      alpha: 0,
      duration: 600,
      onComplete: () => super.transition(skip, last),
      callbackScope: this,
    });*/
  }

  midiOn({ note, velocity }: { note: number; velocity: number }): void {
    this.playNote(note, velocity);
  }

  midiOff({ note }: { note: number }): void {
    if (!this.paused) this.eventStream?.handleNoteOffEvent(note);
  }

  getNotePosition(id: string) {
    for (const staff of this.staffLines) {
      const found = staff.getNotePosition(id);
      if (found) return found;
    }
    return null;
  }

  getPhraseEndPosition() {
    const val = Math.max(
      ...this.staffLines.map((staffLine) => staffLine.getNextPosition())
    );
    return val;
  }

  unloadGraphics() {
    this.timers.forEach((timer) => timer.remove());
    this.timers.clear();
    this.staffLines.forEach((staff) => staff?.destroy());
    this.staffLines.clear();
    this.cursor?.destroy();
    this.playingState = PlayingState.Stopped;
    this.cursor = undefined;
  }

  onObjectivePassed() {
    this.unloadGraphics();
  }

  onObjectiveSkipped() {
    this.unloadGraphics();
  }

  unload() {
    this.lastTickTime = undefined;
    this.playingState = PlayingState.Stopped;
    this.tweens.killAll();
    console.log('scene: unloading staff');
    RemoveListener(ReactToExerciseEventType.UpdateObjective);
    RemoveListener(ReactToExerciseEventType.SetNoteState);
    RemoveListener(ReactToExerciseEventType.PulseNote);
    this.unloadGraphics();
    super.unload();
  }
}
