import { EventActions, EventActionTypes, EventType, EventTypes, LevelCompletedEvent, LevelPlayEvent, LevelPlayingEvent, LevelStartedEvent, RepertoirePlayingEvent, TierCompletedEvent, TierStartedEvent } from "Types/EventTypes"
import { put, select, takeEvery } from "redux-saga/effects";
import axios from 'axios'
import { getCurrentAuthToken } from "Utils/Amplify";
import * as appActions from 'Actions/app'
import * as eventActions from 'Actions/events';
import * as lessonActions from 'Actions/lesson';
import logger from 'Utils/Logger'
import { mainAppReducer } from "Reducers/mainAppReducer";
import axiosRetry from 'axios-retry';
import { eventQueue } from 'Models/EventQueue';
import { awsRum } from 'Utils/AwsRum';

axiosRetry(axios,{ retryDelay: axiosRetry.linearDelay(), retries: 3 } )
const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/`
const eventMaxBatchSize = process.env.REACT_APP_EVENT_MAX_BATCH_SIZE;
if(!eventMaxBatchSize) {
  throw Error("REACT_APP_EVENT_MAX_BATCH_SIZE is not set")
}

const eventMaxBatchSizeInt = parseInt(eventMaxBatchSize)


function* onStartup() {
  yield put(
    eventActions.setSessionId()
  )  
}
type BaseEventType = {
  datetime: string,
  analytics_session_id: string,
}

type PlaySessionEventType = 
  BaseEventType & 
  {play_session_id?: string}

type PlaySessionEvents =
  PlaySessionEventType &
  (
    LevelCompletedEvent |
    LevelPlayEvent |
    LevelPlayingEvent |
    RepertoirePlayingEvent | 
    TierCompletedEvent |
    TierStartedEvent |
    LevelStartedEvent
  )

type GenericEventType = ((BaseEventType & EventTypes) | (PlaySessionEvents))

function* pushToQueue(action: EventActions): any {
  const eventsUrl = baseUrl + 'events';
  const {sessionId, playSessionId} = yield select(({ eventReducer }) => eventReducer)
  const { currentUserLevelData } = yield select(({ lessonReducer }) => lessonReducer)

  // const {queue} = yield select(({ eventReducer }) => eventReducer)

  const baseEvent = ({
    datetime: new Date().toISOString(),
    analytics_session_id: sessionId,
    platform: 'web'
  })
  const payload: GenericEventType = {
    ...action.payload,
    ...baseEvent
  }
  logger.debug("about to add event to queue:", payload)
  try {
    // yield put(eventActions.setEventQeue(queue.concat([payload])))
    eventQueue.pushEvent(payload)
  } catch (error) {
    console.error(error)
  }
}


function *dispatchEvents(): any {
  eventQueue.dispatchEvents()
  yield put(eventActions.dispatchEventsSuccess())
}


export default [
  takeEvery(EventActionTypes.DISPATCH_EVENTS, dispatchEvents),
  takeEvery(EventActionTypes.VISIT_EVENT_ACTION, pushToQueue),
  takeEvery(EventActionTypes.PAGE_VIEW_EVENT_ACTION, pushToQueue),
  takeEvery(EventActionTypes.WELCOME_LETTER_EVENT_ACTION, pushToQueue),
  takeEvery(EventActionTypes.SIGN_IN_EVENT_ACTION, pushToQueue),
  takeEvery(EventActionTypes.MIDI_CONNECTION_SUCCESS_EVENT_ACTION, pushToQueue),
  takeEvery(EventActionTypes.MIDI_CONNECTION_FAILURE_EVENT_ACTION, pushToQueue),
  takeEvery(EventActionTypes.LEVEL_PLAYING_EVENT_ACTION, pushToQueue),
  takeEvery(EventActionTypes.LEVEL_PLAY_EVENT_ACTION, pushToQueue),
  takeEvery(EventActionTypes.LEVEL_PAUSE_EVENT_ACTION, pushToQueue),
  takeEvery(EventActionTypes.LEVEL_STARTED_EVENT_ACTION, pushToQueue),
  takeEvery(EventActionTypes.LEVEL_COMPLETED_EVENT_ACTION, pushToQueue),
  takeEvery(EventActionTypes.TIER_STARTED_EVENT_ACTION, pushToQueue),
  takeEvery(EventActionTypes.TIER_COMPLETED_EVENT_ACTION, pushToQueue),
  // takeEvery(EventActionTypes.REPERTOIRE_PLAYING_EVENT_ACTION, pushToQueue), //TODO
  // takeEvery(EventActionTypes.REPERTOIRE_COMPLETED_EVENT_ACTION, pushToQueue), //TODO
  takeEvery(EventActionTypes.TUTORIAL_CHAPTER_STARTED_EVENT_ACTION, pushToQueue),
  takeEvery(EventActionTypes.TUTORIAL_SKIPPED_EVENT_ACTION, pushToQueue),
  takeEvery(EventActionTypes.MOBILE_BLOCKED_EVENT_ACTION, pushToQueue),
  takeEvery(EventActionTypes.TUTORIAL_STARTED_EVENT_ACTION, pushToQueue),
  takeEvery(EventActionTypes.PHRASE_COMPLETED_EVENT_ACTION, pushToQueue),
  takeEvery(EventActionTypes.SAFARI_BLOCKED_EVENT_ACTION, pushToQueue),
  onStartup()
]
