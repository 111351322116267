import { Map, Set } from "immutable";

export interface CompletionData {
  lesson: number;
  chapter: number;
}

export interface LastChapterData {
  lesson: number;
  chapter: number;
}

// Maps a lesson number to a set of completed chapters within it
export type CompletionState = {
  completed: Map<number, Set<number>>;
  lastChapter: Map<number, number>;
};

export enum ChapterActionTypes {
  LOAD_COMPLETION_STATE = "LOAD_COMPLETION_STATE",
  MARK_CHAPTER_COMPLETE = "MARK_CHAPTER_COMPLETE",
  SET_LAST_CHAPTER = "SET_LAST_CHAPTER",
}

export type LoadCompletionState = {
  type: typeof ChapterActionTypes.LOAD_COMPLETION_STATE;
  payload: CompletionState;
};

export type MarkAsCompleted = {
  type: typeof ChapterActionTypes.MARK_CHAPTER_COMPLETE;
  payload: CompletionData;
};

export type SetLastChapter = {
  type: typeof ChapterActionTypes.SET_LAST_CHAPTER;
  payload: LastChapterData;
};

export type ChapterAction =
  | LoadCompletionState
  | MarkAsCompleted
  | SetLastChapter;

export interface ChapterReducer {
  chapterReducer: CompletionState;
}
