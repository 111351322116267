import {
  CheckCircle,
  RadioButtonUnchecked,
  OndemandVideoRounded,
} from "@mui/icons-material";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  List,
} from "@mui/material";
import Immutable from "immutable";
import { useSelector } from "react-redux";
import { ChapterReducer } from "Types/ChapterTypes";
import { ChapterData, ChapterType } from "Types/ExerciseData";

export type Timestamp = `${number}:${number}`;

export function getTime(stamp: Timestamp): number {
  const [min, sec] = stamp.split(":").map(parseFloat);
  return min * 60 + sec;
}

export type ChapterSection = {
  chapters: ChapterData[];
};

type ChapterListProps = {
  sections: ChapterSection[];
  lesson: number;
  active: number;
  jumpTo: (x: number, chapter?: number) => void;
};

const Chapter = ({
  jumpTo,
  completionStatus,
  active,
  chapter,
}: {
  jumpTo: (x: number, chapter?: number) => void;
  completionStatus: Immutable.Set<number>;
  active: number;
  chapter: ChapterData;
}) => {
  const completed =
    completionStatus.find((i) => i === chapter.id) !== undefined;
  const icon_color = completed
    ? "#28856f"
    : active === chapter.id
      ? "#ffffff"
      : "#b2b2b2";
  const timeInSecs = getTime(chapter.start);
  return (
    <ListItemButton
      onClick={() => jumpTo(timeInSecs, chapter.id)}
      id={`chapter-${chapter.id}`}
      sx={{
        bgcolor: active === chapter.id ? "#4A5AA1" : "transparent",
        ":hover": {
          bgcolor: "#4a5aa1",
        },
      }}
      onKeyUp={(e) => {
        if (e.key === " ") e.preventDefault();
      }}
    >
      {chapter.data[0] === ChapterType.Exercise ? (
        <ListItemIcon>
          {completionStatus.find((i) => i === chapter.id) ? (
            <CheckCircle sx={{ color: icon_color }} />
          ) : (
            <RadioButtonUnchecked sx={{ color: icon_color }} />
          )}
        </ListItemIcon>
      ) : (
        <ListItemIcon>
          <OndemandVideoRounded sx={{ color: icon_color }} />
        </ListItemIcon>
      )}
      <ListItemText
        sx={{ color: active === chapter.id ? "#ffffff" : "#b2b2b2" }}
      >
        {chapter.data[0] === ChapterType.Exercise && <b>Exercise: </b>}
        {`${chapter.title} (${formatTime(chapter.start)})`}
      </ListItemText>
    </ListItemButton>
  );
};

function formatTime(timestamp: Timestamp) {
  const [minutes, seconds] = timestamp.split(":");
  const formattedSeconds = Math.floor(parseFloat(seconds))
    .toString()
    .padStart(2, "0");
  return `${minutes}:${formattedSeconds}`;
}

const ChapterList = ({
  sections,
  lesson,
  active,
  jumpTo,
}: ChapterListProps) => {
  const completionData = useSelector(
    (state: ChapterReducer) => state.chapterReducer,
  );
  const completionStatus =
    Immutable.Set(Immutable.get(completionData.completed, lesson)) ||
    Immutable.Set([]);
  let idx = 0;
  return (
    <Box
      sx={{
        bgcolor: "#1d2f44",
        overflowY: "scroll",
      }}
    >
      <h3 style={{ color: "#b2b2b2", margin: 20 }}>Chapters</h3>
      <List sx={{ overflowY: "scroll" }}>
        {sections.map((section, key1) => (
          <Box key={`section-${key1}`} sx={{ margin: 2, borderBottom: "1px solid #b2b2b255" }}>
            {section.chapters.map((chapter) => (
              <Chapter
                key={`chapter-${idx}`}
                jumpTo={jumpTo}
                completionStatus={completionStatus}
                active={active}
                chapter={{ ...chapter, id: idx++ }}
              />
            ))}
          </Box>
        ))}
      </List>
    </Box>
  );
};

export default ChapterList;
