import Events from './Events';
import AppData from './AppData';
import Auth from './Auth';
import Lesson from './Lesson';
import Repertoire from './Repertoire';

import { all } from "redux-saga/effects";


export default function* rootSaga() {
  yield all([
    ...Auth,
    ...Events,
    ...AppData,
    ...Lesson,
    // ...Repertoire
  ])
}
