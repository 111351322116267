import { ERROR_RECOGNITION_MODE, UserInfo, UserLevelData } from "Types";




export enum lessonActionTypes {
  RESET_LESSON = "RESET_LESSON",
  SET_LESSON_PLAYING = "SET_LESSON_PLAYING",
  INITIATE_WRAP_UP = 'INITIATE_WRAP_UP',
  SET_LEVEL_NUMBER = 'SET_LEVEL_NUMBER',
  SET_TIER_NUMBER = 'SET_TIER_NUMBER',
  SET_NUMBER_OF_TIERS = 'SET_NUMBER_OF_TIERS',
  GET_TIER_SUMMARY = 'GET_TIER_SUMMARY',
  SET_TIER_SUMMARY = 'SET_TIER_SUMMARY',
  SET_WRAP_UP_INDEX = 'SET_WRAP_UP_INDEX',
  SET_SHOW_WRAP_UP = 'SET_SHOW_WRAP_UP',
  SET_LESSON_PROCESS_PENDING = 'SET_LESSON_PROCESS_PENDING',
  SET_WRAP_UP_MODAL_PAGE = 'SET_WRAP_UP_MODAL_PAGE',
  SET_ALLOW_CHEVRONS = 'SET_ALLOW_CHEVRONS',
  CREATE_PLAY_SESSION = 'CREATE_PLAY_SESSION',
  CREATE_LEVEL_SESSION = "CREATE_LEVEL_SESSION",
  CREATE_LEVEL_SESSION_SUCCESS = "CREATE_LEVEL_SESSION_SUCCESS",
  UPDATE_CURRENT_ULP = "UPDATE_CURRENT_ULP",
  UPDATE_CURRENT_ULP_SUCCESS = "UPDATE_CURRENT_ULP_SUCCESS",
  UPDATE_PHRASES_TIL_NEXT_TIER = "UPDATE_PHRASES_TIL_NEXT_TIER",
  UPDATE_CURRENT_LEVEL_TIERS = "UPDATE_CURRENT_LEVEL_TIERS",
  SET_CURRENT_COMPLETED_AT = "SET_CURRENT_COMPLETED_AT",
  SET_TIER_SUMMARY_REQUEST_ERROR = "SET_TIER_SUMMARY_REQUEST_ERROR"
}

export interface UpdateCurrentULPPayload { 
  currentUserLevelProgress: Partial<UserLevelData> | undefined
}


export enum WrapUpModalPage {
  TIER_SUMMARY = 'TIER_SUMMARY',
  TIER_COMPLETE = 'TIER_COMPLETE',
  LEVEL_COMPLETE = 'LEVEL_COMPLETE',
  UNIT_COMPLETE = 'UNIT_COMPLETE',
  REPERTOIRE_UNLOCKED = 'REPERTOIRE_UNLOCKED'
}

export type AccuracyType =
  | 'avg_running_accuracy'
  | 'avg_lh_running_accuracy'
  | 'avg_rh_running_accuracy'
  | 'avg_accuracy'
  | 'avg_lh_accuracy'
  | 'avg_rh_accuracy';

export type PhraseData = {
  [key in AccuracyType]: string;
} & {
  bpm: number;
  datetime: string;
  total_notes: string;
};

export type UpdateCurrentULP = {
  type: typeof lessonActionTypes.UPDATE_CURRENT_ULP;
  payload: UpdateCurrentULPPayload
}

export interface setLessonPlayingPayload {
  lessonPlaying: boolean;
}

export type PreviousTierPlaySessions = {
  total_play_time: number
  tier_number: number
}

export type LevelSessionPayload = {
  level_number: number,
  tier_number: number,
  error_recognition_mode: ERROR_RECOGNITION_MODE
}

export type CreateLevelSession = {
  type: typeof lessonActionTypes.CREATE_LEVEL_SESSION;
  payload: LevelSessionPayload
}

export type CreateLevelSessionSuccess = {
  type: typeof lessonActionTypes.CREATE_LEVEL_SESSION_SUCCESS;
}

export type PlaySessionData = {
  id: number;
  user_id: string;
  tier_number: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  level_number: number;
  repertoire_id: number | null;
  level_completed_event_id: number | null;
  tier_completed_event_id: number | null;
  avg_running_accuracy: number;
  total_notes: number;
  error_recognition_mode: string;
  avg_accuracy: number;
  avg_lh_accuracy: number;
  avg_rh_accuracy: number;
  avg_lh_running_accuracy: number;
  avg_rh_running_accuracy: number;
  total_error: number;
  total_off_time: number;
  total_play_time: number;
  avg_error_score: number;
  phraseData: PhraseData[];
  previousTiersPlaySessions: PreviousTierPlaySessions[]; // Adjust this to the correct type if you have more details 
}

export interface InitiateWrapUp {
  type: lessonActionTypes.INITIATE_WRAP_UP,
}

export interface SetLevelNumber {
  type: lessonActionTypes.SET_LEVEL_NUMBER;
  payload: number;
}

export interface SetTierNumber {
  type: lessonActionTypes.SET_TIER_NUMBER;
  payload: number;
}

export interface SetNumberOfTiers {
  type: lessonActionTypes.SET_NUMBER_OF_TIERS;
  payload: number;
}

export interface SetTierSummary {
  type: lessonActionTypes.SET_TIER_SUMMARY;
  payload: PlaySessionData | null;
}

export interface GetTierSummary {
  type: lessonActionTypes.GET_TIER_SUMMARY;
}

export interface SetShowWrapUp {
  type: lessonActionTypes.SET_SHOW_WRAP_UP;
  payload: boolean
}

export interface SetWrapUpIndex {
  type: lessonActionTypes.SET_WRAP_UP_INDEX;
  payload: number
}

export interface SetTierSummary {
  type: lessonActionTypes.SET_TIER_SUMMARY;
  payload: PlaySessionData | null;
}

export interface SetLessonProcessPending {
  type: lessonActionTypes.SET_LESSON_PROCESS_PENDING;
  payload: boolean;
}

export interface SetWrapUpModalPage {
  type: lessonActionTypes.SET_WRAP_UP_MODAL_PAGE;
  payload: WrapUpModalPage | null
}


export interface SetAllowChevrons {
  type: lessonActionTypes.SET_ALLOW_CHEVRONS;
  payload: boolean;
}


export interface SetTierSummarRequestError {
  type: lessonActionTypes.SET_TIER_SUMMARY_REQUEST_ERROR;
  payload: boolean;
}

export type UpdateCurrentULPSuccess = {
  type: typeof lessonActionTypes.UPDATE_CURRENT_ULP_SUCCESS;
  payload: { next: UserLevelData, prev?: UserLevelData}
}

export type UpdateCurrentLevelTiers = {
  type: typeof lessonActionTypes.UPDATE_CURRENT_LEVEL_TIERS;
  payload: number
}

export type ResetLesson = {
  type: typeof lessonActionTypes.RESET_LESSON;
  payload: null
}

export type UpdatePhrasesTilNextTier = {
  type: typeof lessonActionTypes.UPDATE_PHRASES_TIL_NEXT_TIER;
  payload: number
}

export type SetLessonPlaying = {
  type: typeof lessonActionTypes.SET_LESSON_PLAYING;
  payload: setLessonPlayingPayload;
}

export type SetCurrentCompletedAt = {
  type: typeof lessonActionTypes.SET_CURRENT_COMPLETED_AT;
  payload: Date | null;
}


export type LessonActions = 
  | InitiateWrapUp
  | SetLevelNumber
  | SetTierNumber
  | SetNumberOfTiers
  | GetTierSummary
  | SetTierSummary
  | SetWrapUpIndex
  | SetShowWrapUp
  | SetLessonProcessPending
  | SetLessonPlaying
  | SetTierSummarRequestError
  | SetWrapUpModalPage
  | SetAllowChevrons
  | UpdateCurrentULP
  | UpdateCurrentULPSuccess
  | UpdatePhrasesTilNextTier
  | UpdateCurrentLevelTiers
  | ResetLesson
  | CreateLevelSession
  | CreateLevelSessionSuccess
  | SetLessonPlaying
  | SetCurrentCompletedAt