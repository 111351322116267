import { ERROR_RECOGNITION_MODE } from 'Types'
import { 
  RepertoireActionTypes,
  RepertoireData,
  UserRepertoireProgress,
  SetRepertoireSelectPayload,
  SetRepertoirePlayingPayload,
  RepertoireState,
  SetCursorVisible,
  SetPlaySessionId,
} from 'Types/RepertoireTypes'

export type RepertoireSessionPayload = {
  repertoire_id: number,
  error_recognition_mode: ERROR_RECOGNITION_MODE
}

export type CreateRepertoireSession = {
  type: typeof RepertoireActionTypes.CREATE_REPERTOIRE_SESSION;
  payload: RepertoireSessionPayload
}

export type CreateRepertoireSessionSuccess = {
  type: typeof RepertoireActionTypes.CREATE_REPERTOIRE_SESSION_SUCCESS;
}

export const updateRepertoireDataRequest = (payload: UserRepertoireProgress) => ({
  type: RepertoireActionTypes.UPDATE_REPERTOIRE_DATA_REQUEST,
  payload
})

export const fetchRepertoireDataSuccess = (payload: RepertoireData[]) => ({
  type: RepertoireActionTypes.FETCH_REPERTOIRE_DATA_SUCCESS,
  payload
})

export const setRepertoireEnded = (payload: boolean) => ({
  type: RepertoireActionTypes.SET_REPERTOIRE_ENDED,
  payload
})

export const setRepertoireSelect = (payload: SetRepertoireSelectPayload) => ({
  type: RepertoireActionTypes.SET_REPERTOIRE_SELECT,
  payload
})

export const createRepSession = (payload: RepertoireSessionPayload): CreateRepertoireSession => ({
  type: RepertoireActionTypes.CREATE_REPERTOIRE_SESSION,
  payload
})

export const setPlaySessionId = (payload: string): SetPlaySessionId => ({
  type: RepertoireActionTypes.SET_PLAY_SESSION_ID,
  payload
})

export const fetchURPSuccess = (payload: UserRepertoireProgress[]) => ({
  type: RepertoireActionTypes.FETCH_URP_SUCCESS,
  payload
})

export const updateRepertoireDataLocal = (payload: Partial<UserRepertoireProgress>) => {
  return ({
  type: RepertoireActionTypes.UPDATE_REPERTOIRE_DATA_LOCAL,
  payload
})}

export const loadRepertoireState = (payload: RepertoireState) => ({
  type: RepertoireActionTypes.LOAD_REPERTOIRE_STATE,
  payload
})

export const setRepertoirePlaying = (payload: SetRepertoirePlayingPayload) => ({
  type: RepertoireActionTypes.SET_REPERTOIRE_PLAYING,
  payload
})

export const setCursorVisible = (payload: boolean): SetCursorVisible => ({
  type: RepertoireActionTypes.SET_CURSOR_VISIBLE,
  payload
})