import ExerciseData, { ChapterData, ChapterType, ExerciseScene, ExerciseSceneConfig, InteractiveEvent, Timestamp } from "Types/ExerciseData";
import * as PianoSceneNS from "Phaser/Scenes/Piano";
import * as NoteSequenceExerciseNS from "Phaser/Scenes/NoteSequenceExercise";
import * as ExerciseTempoPracticeNS from "Phaser/Scenes/ExerciseTempoPractice";
import * as NoTimeExerciseNS from "Phaser/Scenes/NoTime";
import * as StaffExerciseNS from "Phaser/Scenes/StaffExercise";
import { ReactToExerciseEvent, ReactToExerciseEventType } from "Phaser/EventBus";

export const Lecture = (title: string, start: Timestamp) => ({
  start,
  title,
  data: [ChapterType.Lecture]
} as ChapterData);

export const Exercise = (title: string, start: Timestamp, scene: ExerciseSceneConfig, events: InteractiveEvent[]) => ({
  start,
  title,
  data: [ChapterType.Exercise, {
    scene,
    events
  }]
} as ChapterData);

export const PianoScene = (config: PianoSceneNS.Config) => (
  [ExerciseScene.PianoScene, config] as ExerciseSceneConfig
);

export const NoteSequenceExercise = (config: NoteSequenceExerciseNS.Config) => (
  [ExerciseScene.NoteSequenceExercise, config] as ExerciseSceneConfig
);

export const ExerciseTempoPractice = (config: ExerciseTempoPracticeNS.Config) => (
  [ExerciseScene.ExerciseTempoPractice, config] as ExerciseSceneConfig
);

export const NoTimeExercise = (config: NoTimeExerciseNS.Config) => (
  [ExerciseScene.NoTimeExercise, config] as ExerciseSceneConfig
);

export const StaffExercise = (config: StaffExerciseNS.Config) => (
  [ExerciseScene.StaffExercise, config] as ExerciseSceneConfig
);

export const Event = (at: Timestamp, phaserEvent: ReactToExerciseEvent, pause?: boolean) => ({
  at,
  phaserEvent,
  pause: pause ?? phaserEvent[0] === ReactToExerciseEventType.StartExercise,
});

export const Tutorial = (level: number, end: Timestamp, sections: { chapters: ChapterData[] }[]) => ({
  level,
  end,
  videoUrl: `level-${level}.mp4`,
  sections
});

export const Section = (chapters: ChapterData[]) => ({
  chapters
});
