import { Fraction, NoteType } from "opensheetmusicdisplay";
import { noteTypeToDivisionsPerMeasure } from "Utils";
import TimeSignature from "./TimeSignature";
import * as Tone from "tone";
import ITimeKeeper from "./ITimeKeeper";

// Keeps track of the audio context for us, and provides methods to start and stop it.
// Can convert tick times to "audio time" and vice-versa.
export default class TimeKeeper implements ITimeKeeper {
  public static get METRONOME_SCHEDULE_AHEAD_TIME(): number {
    return 0.2;
  }

  // Configuration parameters (these are set by the outside world)
  // Beats per minute
  private bpm: number = -1
  // Eventually we'll handle time signatures per measure; this is prepping for that.
  // For now we just look at the first time signature in this array.
  private timeSignatures: TimeSignature[] = []
  private timeSigFracts: number[] = []
  // TODO make me configurable...
  // private timeSignature: Fraction = new Fraction(4, 4, 1, false)
  // When unpaused, the TimeKeeper will count in to this measure.
  private startMeasure: number = 0
  // The number of measures we should spend counting in.
  private countInMeasures: number = 1
  // Determines how many ticks there are per beat.
  private tickNoteType: NoteType = NoteType._32nd

  // State tracking parameters, some of these are exposed to the outside world.
  private paused: boolean = true
  // private elapsedTicks: number = 0
  private elapsedTicksOffset: number = 0
  // Audio time in seconds when playback should begin.
  private startTime: number = 0
  private measureDurationSecs: number = 3.333
  private ticksPerMeasure: number = 0
  private secondsPerTick: number = 0

  unpause(bpm: number,
    countInMeasures: number,
    startMeasure: number,
    tickNoteType: NoteType) {
    console.log("unpausing");
    this.bpm = bpm
    this.countInMeasures = countInMeasures
    this.startMeasure = startMeasure
    this.tickNoteType = tickNoteType

    // For now we assume time signatures won't change per-lesson...
    const timeSignature = this.timeSignatures[0];
    if (!timeSignature) {
      return;
    }

    if (timeSignature?.denominator === 4) {
      this.measureDurationSecs = (60.0 / this.bpm) * timeSignature.numerator
    } else {
      throw `Time signature with denominator of ${timeSignature.denominator} is not implemented (yet)!`;
    }

    // Initialize some important timing numbers.

    this.ticksPerMeasure = noteTypeToDivisionsPerMeasure(
      this.tickNoteType,
      timeSignature,
    );
    this.secondsPerTick = this.measureDurationSecs / this.ticksPerMeasure;

    this.elapsedTicksOffset = this.ticksPerMeasure * this.startMeasure;

    const metronomeMeasureStartTime =
      Tone.context.currentTime + TimeKeeper.METRONOME_SCHEDULE_AHEAD_TIME;
    // Calculate the first beat of user MIDI playback.
    this.startTime =
      metronomeMeasureStartTime +
      this.measureDurationSecs * this.countInMeasures;
    this.paused = false;
    Tone.Transport.start();
  }

  pause() {
    console.log("pausing");
    Tone.Transport.pause();
    this.paused = true;
  }

  isPaused() {
    return this.paused;
  }

  setBpm(newBpm: number) {
    // If we aren't currently playing back, we'll update BPM numbers later
    const oldBpm = this.bpm;
    this.bpm = newBpm;

    if (!this.paused) {
      // First calculate and remember the current timestamp
      const timestamp = this.audioTimeToMeasureTimestamp();

      // BPM adjustment during playback requires us to move the start time.
      // We're going to move the start time so it scales to the new BPM.
      const timeSinceStart = Tone.context.currentTime - this.startTime;
      const bpmScale = oldBpm / newBpm;
      this.startTime = Tone.context.currentTime - timeSinceStart * bpmScale;

      const newTimestamp = this.audioTimeToMeasureTimestamp();

      // Recalculate affected numbers.
      this.measureDurationSecs =
        (60.0 / this.bpm) * this.timeSignatures[0].numerator;
      this.secondsPerTick = this.measureDurationSecs / this.ticksPerMeasure;
    }
  }

  setTimeSignatures(startMeasure: number, timeSignatures: TimeSignature[] = []) {
    // First overwrite any time signatures that we already store,
    // and then push the new ones.
    if (timeSignatures.length) {
      let i = 0;
      for (let j = startMeasure; i < timeSignatures.length && j < this.timeSignatures.length; i++, j++) {
        this.timeSignatures[j] = timeSignatures[i]
      }
      for (; i < timeSignatures.length; i++) {
        this.timeSignatures.push(timeSignatures[i])
        this.timeSigFracts[i] = this.timeSignatures[i].numerator / this.timeSignatures[i].denominator

      }
    }
  }

  calculateElapsedTicks(): number {
    const elapsedTime = Tone.context.currentTime - this.startTime;
    let elapsedTicks =
      Math.floor(elapsedTime / this.secondsPerTick) + this.elapsedTicksOffset;
    // elapsedTicks = elapsedTicks === Infinity ? 0 - (this.ticksPerMeasure * this.countInMeasures): elapsedTicks
    elapsedTicks = elapsedTicks === Infinity ? 0 : elapsedTicks;
    return elapsedTicks;
  }

  resetTime() {
    // this.startMeasure = 0
    // this.startTime = 0 + this.measureDurationSecs * this.countInMeasures
    // const metronomeMeasureStartTime = Tone.context.currentTime + TimeKeeper.METRONOME_SCHEDULE_AHEAD_TIME
    // Calculate the first beat of user MIDI playback.
    this.startMeasure = 0
    this.elapsedTicksOffset = 0
    this.pause()
    this.startTime = Tone.context.currentTime
  }

  noteTypeToTicksPerMeasure(noteType: NoteType) {
    const timeSignature = this.timeSignatures[0];
    return noteTypeToDivisionsPerMeasure(noteType, timeSignature);
  }

  // Convert an OSMD timestamp to ticks.
  // Note this will return non-integer ticks if the math works out that way!
  measureTimestampToTicks(measureTimestamp: number): number {
    return this.ticksPerMeasure * measureTimestamp;
  }

  measureDurationAt(index: number) {
    var timeSignature = this.timeSignatures[0];
    if (timeSignature) {
      return timeSignature.numerator / timeSignature.denominator;
    } else {
      // if no time signature, return 0 (not in Lesson Page)
      return 0;
    }
  }

  // BPM-aware conversion of a measure timestamp to the current audio time.
  measureTimestampToAudioTime(measureTimestamp: number): number {
    measureTimestamp = measureTimestamp;
    const measureOffset = measureTimestamp - this.startMeasure;
    return this.startTime + measureOffset * this.measureDurationSecs;
  }

  audioTimeToMeasureTimestamp(): number {
    const timeOffset = Tone.context.currentTime - this.startTime;
    const timestamp = this.startMeasure + timeOffset / this.measureDurationSecs;
    // console.log("this.startMeasure = " + this.startMeasure)
    // console.log("timeOffset = " + timeOffset)
    // console.log("this.measureDurationSecs = " + this.measureDurationSecs )
    // console.log("timestamp = " + timestamp)
    // console.log("this.measureDurationAt(0) = " + this.measureDurationAt(0) )
    // console.log("timestamp * this.measureDurationAt(0) = " + timestamp * this.measureDurationAt(0))
    return timestamp * this.measureDurationAt(0);
  }

  // TODO a lot of these could maybe be setters idk

  getPlaybackStartTicks(): number {
    return this.elapsedTicksOffset;
  }

  getPlaybackStartTime(): number {
    return this.startTime;
  }

  getTicksPerMeasure(): number {
    return this.ticksPerMeasure;
  }

  getAudioContext(): Tone.BaseContext {
    return Tone.context;
  }

  getAudioContextTime(): number {
    return Tone.context.currentTime;
  }

  getTimeSignature(): TimeSignature {
    return this.timeSignatures[0]
  }

  getTimeSigFract(): number {
    return this.timeSigFracts[0]
  }

  getTickNoteType(): NoteType {
    return this.tickNoteType;
  }

  getMeasureDurationSecs(): number {
    return this.measureDurationSecs;
  }

  getBpm(): number {
    return this.bpm;
  }

  getIsCountingIn(): boolean {
    return Tone.context.currentTime < this.startTime;
  }
}
