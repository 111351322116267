import Phaser from 'phaser';
import {
  EventBus,
  ListenForReactEvent,
  ListenUpdateObjective,
  NotifyReact,
  ReactToExerciseEventType,
  RemoveListener,
  ShowExercise,
} from '../EventBus';
import RoundedRect from '../GameObjects/RoundedRect';
import {
  BaseNote,
  getMidiNumberFromNote,
} from '../GameObjects/Piano';
import { colors } from 'Phaser/config';
import ExerciseBase, { IConfig, IObjective } from './ExerciseBase';
import { Dissolve } from 'Phaser/Rendering/Transitions';
import { noteFromOsmdPitch, PhraseData, PhraseGraphic, VoiceData } from 'Phaser/GameObjects/StaffLine';
import { ProgressState } from 'Phaser/GameObjects/Progress';
import { PhraseObjectiveData } from './StaffExercise';


interface Objective extends IObjective {
  phrases?: PhraseObjectiveData[];
}

export interface Config extends IConfig<Objective> {
  line_gap_vertical?: number;
  padding_x?: number;
  centre_align_horizontal?: boolean;
  line_thickness?: number;
  width?: number;
  clef_gap?: number;
  clef_padding?: number;
  first_note_offset?: number;
}

function DefaultConfig(config: Config): Config {
  const dpr = 1;
  const line_gap_vertical = (config.line_gap_vertical ?? 20) * dpr;
  const padding_x = (config.padding_x ?? 50) * dpr;
  const line_thickness = (config.line_thickness ?? 2) * dpr;
  const width = (config.width ?? 650) * dpr;
  const clef_gap = (config.clef_gap ?? 60) * dpr;
  const clef_padding = (config.clef_padding ?? 10) * dpr;
  const first_note_offset = (config.first_note_offset ?? 50) * dpr;
  return {
    objectives: config.objectives ?? [],
    firstObjectiveAppearDelay: config.firstObjectiveAppearDelay ?? 0,
    line_gap_vertical,
    padding_x,
    line_thickness,
    width,
    clef_gap,
    clef_padding,
    first_note_offset,
    centre_align_horizontal: config.centre_align_horizontal,
  };
}

function getLineNumber(note: BaseNote) {
  switch (note) {
    case 'C':
      return 6;
    case 'D':
      return 5;
    case 'E':
      return 4;
    case 'F':
      return 3;
    case 'G':
      return 2;
    case 'A':
      return 1;
    case 'B':
      return 0;
  }
}

/*function getLineNumberOctave(note: Note, octave: number) {
  return getLineNumber(note) + octave * 7;
}*/


class NoTimeCursor extends RoundedRect {

  endX: number;
  currentNote: number = 0;
  onFinish: () => void;
  cbCtx?: object;
  db: NoTimeExercise;

  heldKeys: Set<number> = new Set([]);

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    width: number,
    height: number,
    endX: number,
    db: NoTimeExercise,
    onFinish: () => void,
    cbCtx?: object
  ) {
    const color = colors.cursor;
    const radius = width / 2;
    super(
      scene,
      x,
      y,
      width,
      height,
      radius,
      color,
      0
    );
    this.currentNote = 0;
    this.onFinish = onFinish;
    this.cbCtx = cbCtx;
    this.db = db;
    this.endX = endX;
  }

  findNoteByMidi(midi: number) {
    if (this.currentNote < 0 || this.currentNote >= this.db.getAllSlices().length) return undefined;
    else {
      const notes = this.db.getNotes(this.currentNote);
      if (notes) {
        const _id = notes.find(n =>
          n.voice !== 'rest' &&
          getMidiNumberFromNote(`${n.voice.note[0]}${n.voice.note[1]}`) === midi)?.id;
        if (_id) return this.db.getNote(_id);
      }
    }
  }

  onPressKey(midi: number) {
    if (this.currentNote < 0 || this.currentNote >= this.db.getAllSlices().length) return;
    else {
      this.heldKeys.add(midi);
      const note = this.findNoteByMidi(midi);
      if (note) {
        note.setTimingState(ProgressState.Ended);
        note.pulse();
      } else {
        const found = this.db.getNotes(this.currentNote).map(n => this.db.getNote(n.id)).find(n => n?.timingState === undefined);
        if (found) {
          found.shake();
        }
      }
      if (this.checkNotes()) {
        this.currentNote++;
        let target = 0;
        if (this.currentNote === this.db.getAllSlices().length) {
          target = this.endX;
          this.onFinish.call(this.cbCtx);
        } else {
          target = this.db.getNotePosition(this.db.getNotes(this.currentNote)[0].id) ?? 0;
        }
        this.move(target, 4);
      }
    }
  }

  onReleaseKey(midi: number) {
    const note = this.findNoteByMidi(midi);
    if (note) {
      note.reset(true);
    }
    this.heldKeys.delete(midi);
  }

  checkNotes() {
    if (this.currentNote < 0 || this.currentNote >= this.db.getAllSlices().length) return false;
    else {
      const notes = this.db.getNotes(this.currentNote).map(n => this.db.getNote(n.id));
      console.debug(this.db.getNotes(this.currentNote));
      console.debug(notes);
      if (notes) {
        for (const note of notes) {
          console.debug(note, note?.timingState !== ProgressState.Ended);
          if (note?.timingState !== ProgressState.Ended) {
            return false;
          }
        }
        return true;
      }
      return false;
    }
  }

  xWithCentre(x: number) {
    return x - this.width / 2;
  }

  yWithCentre(y: number) {
    return y - this.height / 2;
  }

  move(targetPos: number, speed: number) {
    this.scene.tweens.add({
      targets: this,
      x: this.xWithCentre(targetPos),
      duration: 1000 / speed,
      ease: 'Power2',
      onUpdate: this.draw,
      callbackScope: this,
    });
  }

  show() {
    this.scene.tweens.add({
      targets: this,
      alpha: colors.cursorAlpha,
      duration: 1000,
      ease: 'Power2',
      onUpdate: this.draw,
      callbackScope: this,
    });
  }

  destroy() {
    this.endX = 0;
    this.currentNote = -1;
    this.onFinish = () => { };
    this.cbCtx = undefined;
    super.destroy();
  }
}

export default class NoTimeExercise
  extends ExerciseBase<Objective, Config> {
  config?: Config;
  private notes: VoiceData[][] = [];
  listening: boolean = true;
  cursor?: NoTimeCursor;
  appearanceDelay?: Phaser.Time.TimerEvent;

  phrase?: PhraseGraphic;

  constructor() {
    super('NoTimeExercise');
  }

  stop() {
    this.scene.stop();
  }

  getNotes(id: number) {
    return this.notes[id];
  }

  getAllSlices() {
    return this.notes;
  }

  getNote(id: string) {
    return this.phrase?.notes.get(id);
  }

  getNotePosition(id: string) {
    return this.phrase?.getNotePosition(id);
  }

  updateObjective() {
    this.currentObjective =
      this.currentObjective !== undefined ? this.currentObjective + 1 : 0;
    super.showInstruction(this.config!.objectives[this.currentObjective].text ?? "Play these notes at your own pace.");
    this.transitionIn();
    if (this.currentObjective > 0) {
      this.createGrandStaff(
        this.config!.objectives[this.currentObjective ?? 0].phrases![0],
      );
    }
  }

  unload() {
    RemoveListener(ReactToExerciseEventType.UpdateObjective);
    this.unloadGraphics();
    super.unload();
  }

  unloadGraphics() {
    this.phrase?.destroy();
    this.cursor?.destroy();
    this.appearanceDelay?.remove();
  }

  startExercise() {
    this.cursor?.show();
  }

  createGrandStaff(phrase: PhraseObjectiveData) {
    console.debug(this.config);
    console.debug(phrase);
    const data = new PhraseData(0, phrase.cursorData, phrase.uuid, undefined, true, true);
    const phraseWidth = this.config!.width ?? 800;
    const x = this.config!.padding_x ?? 0;
    this.phrase = new PhraseGraphic(this, x, {
      globalScaling: 0.7,
      pitchLineGap: 20,
      pitchLineWidth: 2,
      measureLineWidth: 3,
      firstNoteGap: 100,
      staffSpacingVertical: 100,
      centreAlign: this.config!.centre_align_horizontal,
      phraseWidth
    }, data);
    this.notes = phrase.cursorData.serializedIterator.map(({ currentVoiceEntries, currentTimestamp }) =>
      currentVoiceEntries
        .flatMap(
          voiceEntry => voiceEntry.Notes
            .filter(n => !n.isRest && n.printObject)
            .map(note => ({
              length: 0.25,
              timestamp: currentTimestamp,
              measureIdx: 0,
              voice: { note: noteFromOsmdPitch(note.pitch) },
              id: note.graphics[0].staveNote.id,
              x: 0 // NOTE: Do not use this x in the cursor. Use PhraseGraphic.getNotePosition(id) instead
            } as VoiceData)
            ))
    );
    const y = this.centreHeight(this.phrase.getHeight());
    this.phrase.setY(y);
    const cursorHeight = this.phrase.getHeight() * 1.25;
    const cursorWidth = cursorHeight * .1;
    const cursorY = this.centreHeight(cursorHeight);
    const cursorX = this.phrase.getFirstNotePosition() - cursorWidth / 2;
    this.cursor = new NoTimeCursor(this, cursorX, cursorY, cursorWidth, cursorHeight, this.phrase.getNextPosition(), this, this.onDone, this);
  }

  midiOn({ note }: { note: number; velocity: number }) {
    console.debug('exercise: key: ', note);
    if (this.listening) this.cursor?.onPressKey(note);
  }

  midiOff({ note }: { note: number; velocity: number }) {
    this.cursor?.onReleaseKey(note);
  }

  onDone() {
    //this.transition(true);
    super.passObjective(
      this.currentObjective === this.config!.objectives.length - 1
    );
  }

  create() {
    EventBus.emit('current-scene-ready', this);
  }

  onObjectivePassed() {
    this.cursor?.destroy();
    this.phrase?.destroy();
    super.onObjectivePassed();
  }

  transitionIn() {
    this.tweens.add({
      targets: this.cameras.main.getPostPipeline('Dissolve') as Dissolve,
      progress: 1,
      duration: 500,
      onComplete: () => NotifyReact(ShowExercise()),
      callbackScope: this,
    });
  }

  transition(skip?: boolean, last?: boolean) {
    this.tweens.add({
      targets: this.cameras.main.getPostPipeline('Dissolve') as Dissolve,
      progress: 0,
      duration: 500,
      onComplete: () => super.transition(skip, last),
      callbackScope: this,
    });
  }

  init(config: Config) {
    super.init(config);
    this.config = DefaultConfig(config);
    if (this.config.centre_align_horizontal) {
      this.config.padding_x =
        this.cameras.main.width / 2 - this.config.width! / 2;
    }
    console.debug(this.config);
    let delay = this.config.firstObjectiveAppearDelay ?? 0;
    this.appearanceDelay = this.time.delayedCall(
      delay,
      () => this.createGrandStaff(this.config!.objectives[0].phrases![0]),
      [],
      this
    );
    this.cameras.main.setPostPipeline('Dissolve');
    (this.cameras.main.getPostPipeline('Dissolve') as Dissolve).progress = 1;
    ListenForReactEvent(ListenUpdateObjective(this.updateObjective), this);
    NotifyReact(ShowExercise());
  }
}
