import Phaser from "phaser";
import { EventBus, NotifyReact, PhaserReady } from "Phaser/EventBus";

export default class Empty extends Phaser.Scene {
  constructor() {
    super("Empty");
  }
  create(): void {
    console.log("scene: empty");
    EventBus.emit("current-scene-ready", this);
    NotifyReact(PhaserReady());
  }
  init() {
    let font = this.add.text(0, 0, ".", {
      fontFamily: "Lato",
      fontStyle: "900",
    });
    font.setAlpha(0);
    this.events.once("shutdown", () => font.destroy());
  }
  stop() {
    this.scene.stop();
  }
}
