import Immutable, { Set, Map } from "immutable";
import {
  CompletionState,
  ChapterAction,
  ChapterActionTypes,
} from "Types/ChapterTypes";

const defaultCompletionState: CompletionState = {
  completed: Map<number, Set<number>>([]),
  lastChapter: Map<number, number>([]),
};


export const chapterReducer = (state = defaultCompletionState, action: ChapterAction) => {
  switch (action.type) {
    case ChapterActionTypes.MARK_CHAPTER_COMPLETE: {
      return {
        ...state,
        completed: Immutable.set(
          state.completed,
          action.payload.lesson,
          Immutable.Set(
            Immutable.get(state.completed, action.payload.lesson) || [],
          ).union([action.payload.chapter]),
        ),
      };
      /*return Immutable.set(
        state,
        action.payload.lesson,
        Immutable.Set(
          Immutable.get(state, action.payload.lesson) || []
        ).union([action.payload.chapter])
      );*/
    }
    case ChapterActionTypes.LOAD_COMPLETION_STATE: {
      console.debug("reducer:", {...state, ...action.payload});
      return {
        ...state,
        ...action.payload,
      };
    }
    case ChapterActionTypes.SET_LAST_CHAPTER: {
      return {
        ...state,
        lastChapter: Immutable.set(
          state.lastChapter,
          action.payload.lesson,
          action.payload.chapter,
        ),
      };
    }
    default:
      return state;
  }
};
