//import EventStream from "Models/EventStream";
import { EventStreamPhaser2 } from "Phaser/GameObjects/CursorMobile";
import { IteratorExtensible } from "Phaser/GameObjects/CursorMobile";
import Phaser from "phaser";
import {
  CurrentSceneReady,
  ListenAddPhrase,
  ListenForReactEvent,
  ListenPulseNote,
  ListenSetCursor,
  ListenSetNoteState,
  NotifyReact,
  ReactToExerciseEventType,
  RemoveListener,
} from "Phaser/EventBus";
import CursorMobile from "Phaser/GameObjects/CursorMobile";
import IPlayable from "Phaser/GameObjects/Playable";
import { PhraseData, PhraseGraphic } from "Phaser/GameObjects/StaffLine";
import { PrerenderedPhraseData } from "Types";
import { INoteGraphicsDatabase } from "./StaffExercise";
import { ProgressState } from "Phaser/GameObjects/Progress";

export default class SightReadingMobile
  extends Phaser.Scene
  implements INoteGraphicsDatabase {
  staffLines: PhraseGraphic[] = [];
  cursor?: CursorMobile;
  currentIdx?: number;
  eventStream?: EventStreamPhaser2;
  log?: Phaser.GameObjects.Text;

  phrasesSeen: Set<string> = new Set();

  // This is a hack for maintaining the correct sequence when new phrases get loaded in
  // should no longer be necessary once we get all the data baked in from the backend
  phraseToBeLoadedNext: { phrase: PhraseData, cursorData: PrerenderedPhraseData[] } | null = null;

  constructor() {
    super("SightReadingMobile");
  }

  create() {
    NotifyReact(CurrentSceneReady(this));
    this.events.once("shutdown", this.destroy, this);
  }

  init({
    eventStream,
  }: {
    eventStream: EventStreamPhaser2;
  }) {
    //this.cameras.main.setZoom(0.5);
    this.eventStream = eventStream;
    this.staffLines = [];
    this.cameras.main.setBackgroundColor("0xebebeb");
    //this.log = this.add.text(0, 0, "log");
    ListenForReactEvent(ListenAddPhrase(this.addPhrase), this, false, true);
    ListenForReactEvent(ListenSetNoteState(this.setNoteState), this, false, false);
    ListenForReactEvent(ListenPulseNote(this.pulseNote), this, false, false);
    //ListenForReactEvent(ListenAddPhrase(this.addPhrase), this, false, true);
    //ListenForReactEvent(ListenSetCursor(this.setCursor), this);
  }

  update() {
    if (this.eventStream) {
      /*this.log?.setText(
        `time: ${this.eventStream.timeKeeper.audioTimeToMeasureTimestamp()}`,
      );*/
    }
    this.cursor?.update();
  }

  addPhrase({
    phrase,
    cursorData,
  }: {
    phrase: PhraseData | undefined;
    cursorData: PrerenderedPhraseData[];
  }) {
    if (phrase && !this.phrasesSeen.has(phrase.uuid)) {
      if (phrase.startTimestamp !== 0 && this.staffLines.length === 0) {
        this.phraseToBeLoadedNext = { phrase, cursorData };
        return;
      }
      const x =
        this.currentIdx !== undefined
          ? this.staffLines[this.currentIdx].getNextPosition()
          : 100;
      this.currentIdx =
        this.currentIdx !== undefined ? (this.currentIdx + 1) % 2 : 0;
      console.log('index', this.currentIdx);

      console.log("position: ", x, this.currentIdx);
      const staff = new PhraseGraphic(
        this,
        x,
        {
          globalScaling: 1,
          pitchLineGap: 20,
          pitchLineWidth: 2,
          measureLineWidth: 3,
          phraseWidth: 1660,
          staffSpacingVertical: 100,
        },
        phrase,
        //cursorData
      );
      if (this.staffLines.length === 0) {
        this.staffLines[0] = staff;
      } else {
        if (this.staffLines.length >= 2 && this.currentIdx !== undefined) {
          console.log("destroyed", this.currentIdx);
          this.staffLines[this.currentIdx].destroy();
        }
        this.staffLines[this.currentIdx] = staff;
      }
      this.cursor?.addPhrase(cursorData);
      this.phrasesSeen.add(phrase.uuid);
      console.log("added phrase ", phrase.phraseIdx, this.currentIdx);
      if (this.phraseToBeLoadedNext) {
        const phrase = this.phraseToBeLoadedNext;
        this.phraseToBeLoadedNext = null;
        this.addPhrase(phrase);
      }
    }
  }

  getNoteById(id: string): IPlayable | null {
    console.debug(this.staffLines);
    for (let staff of this.staffLines) {
      const found = staff.getNoteById(id);
      if (found) return found;
    }
    return null;
  }

  setNoteState({ id, state }: { id: string, state: ProgressState }) {
    this.getNoteById(id)?.setTimingState(state);
  }

  pulseNote({ id }: { id: string }) {
    this.getNoteById(id)?.pulse();
  }

  getNotePosition(id: string) {
    for (let staff of this.staffLines) {
      const found = staff.getNotePosition(id);
      if (found) return found;
    }
    return null;
  }

  getPhraseEndPosition() {
    return Math.max(
      ...this.staffLines.map((staffLine) => staffLine.getNextPosition()),
    );
  }

  destroy() {
    this.cursor?.destroy(true);
    RemoveListener(ReactToExerciseEventType.SetCursor);
    RemoveListener(ReactToExerciseEventType.AddPhrase);
  }
}
