import { ReactToExerciseEventType } from "Phaser/EventBus";
import Tutorial, { ChapterType, ExerciseScene } from "Types/ExerciseData";

const Level10: Tutorial = {
  level: 10,
  end: '2:13',
  videoUrl: 'level-10.mp4',
  sections: [
    {
      chapters: [
        {
          title: 'G in the Treble Clef',
          start: '0:0',
          data: [ChapterType.Lecture]
        },
        {
          title: 'G on the Keyboard',
          start: '0:12',
          data: [ChapterType.Lecture]
        },
        {
          title: 'Find G',
          start: '0:24',
          data: [
            ChapterType.Exercise,
            {
              scene: [
                ExerciseScene.PianoScene,
                {
                  start: 'A0',
                  end: 'C8',
                  transition: 'Dissolve',
                  textPromptPosition: 'Top',
                  objectives: [
                    {
                      text: 'Play G on your keyboard.',
                      target: 'G4',
                      videoCompleteDestination: '0:27.5',
                    },
                  ],
                },
              ],
              events: [
                {
                  at: '0:25',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  at: '0:26.5',
                  pause: true,
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                },
              ],
            },
          ],
        }
      ]
    },
    {
      chapters: [
        {
          title: 'Playing with the 5th Finger',
          start: '0:30',
          data: [ChapterType.Lecture]
        },
        {
          title: 'Playing G',
          start: '0:37',
          data: [
            ChapterType.Exercise,
            {
              scene: [
                ExerciseScene.NoTimeExercise,
                {
                  centre_align_horizontal: false,
                  padding_x: 700,
                  firstObjectiveAppearDelay: 1500,
                  objectives: [
                    {
                      text: 'Play these notes at your own pace.',
                      videoSkipDestination: '0:43',
                      videoCompleteDestination: '0:41.5',
                    },
                  ],
                },
              ],
              events: [
                {
                  at: '0:38',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  at: '0:40.5',
                  pause: true,
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                },
              ],
            }
          ]
        },
        {
          title: '5th Finger Technique',
          start: '0:43',
          data: [ChapterType.Lecture]
        },
        {
          title: 'Alternating Fingers',
          start: '1:09',
          data: [
            ChapterType.Exercise,
            {
              scene: [
                ExerciseScene.NoTimeExercise,
                {
                  centre_align_horizontal: false,
                  padding_x: 700,
                  firstObjectiveAppearDelay: 1500,
                  objectives: [
                    {
                      text: 'Play these notes at your own pace.',
                      videoSkipDestination: '1:14',
                      videoCompleteDestination: '1:12.5',
                    },
                    {
                      text: 'Play these notes at your own pace.',
                      videoSkipDestination: '1:20',
                      videoCompleteDestination: '1:19',
                    },
                    {
                      text: 'Play these notes at your own pace.',
                      videoSkipDestination: '1:25.5',
                      videoCompleteDestination: '1:24.5',
                    }
                  ],
                },
              ],
              events: [
                {
                  at: '1:10',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  at: '1:11.5',
                  pause: true,
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                },
                {
                  at: '1:14.5',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  at: '1:18',
                  pause: true,
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                },
                {
                  at: '1:20.5',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  at: '1:23.5',
                  pause: true,
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                },
              ],
            }
          ]
        },
        {
          title: 'Playing in Time',
          start: '1:26',
          data: [
            ChapterType.Exercise,
            {
              scene: [
                ExerciseScene.StaffExercise,
                {
                  firstObjectiveAppearDelay: 500,
                  objectives: [
                    {
                      tempo: 72,
                      text: 'Play this line of music in sync with the metronome.',
                      paddingHorizontal: 20,
                      phrases: [],
                      videoCompleteDestination: '1:35.5',
                      videoSkipDestination: '1:36.5',
                    },
                    {
                      tempo: 72,
                      text: 'Play this line of music in sync with the metronome.',
                      paddingHorizontal: 20,
                      phrases: [],
                      videoCompleteDestination: '1:39',
                      videoSkipDestination: '1:41',
                    },
                  ],
                },
              ],
              events: [
                {
                  at: '1:32',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  at: '1:34',
                  pause: true,
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                },
                {
                  at: '1:36.5',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  at: '1:38',
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                  pause: true,
                },
              ],
            }
          ]
        }
      ]
    },
    {
      chapters: [
        {
          title: 'Using Both Hands',
          start: '1:42',
          data: [ChapterType.Lecture]
        },
        {
          title: 'Alternating Hands',
          start: '1:49',
          data: [
            ChapterType.Exercise,
            {
              scene: [
                ExerciseScene.StaffExercise,
                {
                  firstObjectiveAppearDelay: 500,
                  objectives: [
                    {
                      tempo: 72,
                      text: 'Play this line of music in sync with the metronome.',
                      paddingHorizontal: 20,
                      phrases: [],
                      videoCompleteDestination: '1:53.5',
                      videoSkipDestination: '1:54.5',
                    },
                    {
                      tempo: 72,
                      text: 'Play this line of music in sync with the metronome.',
                      paddingHorizontal: 20,
                      phrases: [],
                      videoCompleteDestination: '2:00.5',
                      videoSkipDestination: '2:02',
                    },
                    {
                      tempo: 72,
                      text: 'Play this line of music in sync with the metronome.',
                      paddingHorizontal: 20,
                      phrases: [],
                      videoCompleteDestination: '2:04.5',
                      videoSkipDestination: '2:06',
                    },
                  ],
                },
              ],
              events: [
                {
                  at: '1:49',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  at: '1:52.5',
                  pause: true,
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                },
                {
                  at: '1:54.5',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  at: '1:59.5',
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                  pause: true,
                },
                {
                  at: '2:02',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  at: '2:03.8',
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                  pause: true,
                },
              ],
            }
          ]
        }
      ]
    }
  ]
};
export default Level10;
