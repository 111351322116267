import Phaser from 'phaser';
import { colors } from '../config';

type Natural = 'C' | 'D' | 'E' | 'F' | 'G' | 'A' | 'B';
type Sharp = 'C#' | 'D#' | 'F#' | 'G#' | 'A#';
type Flat = 'Db' | 'Eb' | 'Gb' | 'Ab' | 'Bb';

export type BaseNote = Natural | Sharp | Flat;
export type Note = `${BaseNote}${number}`;

function darken(color: number, factor: number = 0.5): number {
  // Extract individual color components
  const r = (color >> 16) & 0xff;
  const g = (color >> 8) & 0xff;
  const b = color & 0xff;

  // Darken each component
  const darkR = Math.max(0, Math.floor(r * (1 - factor)));
  const darkG = Math.max(0, Math.floor(g * (1 - factor)));
  const darkB = Math.max(0, Math.floor(b * (1 - factor)));

  // Reconstruct the color number
  return (darkR << 16) | (darkG << 8) | darkB;
}

export function midiNumberToBaseNote(number: number): BaseNote {
  const note = number % 12;
  switch (note) {
    case 0:
      return 'C';
    case 1:
      return 'C#';
    case 2:
      return 'D';
    case 3:
      return 'D#';
    case 4:
      return 'E';
    case 5:
      return 'F';
    case 6:
      return 'F#';
    case 7:
      return 'G';
    case 8:
      return 'G#';
    case 9:
      return 'A';
    case 10:
      return 'A#';
    case 11:
      return 'B';
    default:
      throw 'must be 0 <= number <= 11';
  }
}

export function getNoteNameFromMidiNumber(number: number): Note {
  const octave = number / 12;
  return (midiNumberToBaseNote(number) + (octave - 1).toString()) as Note;
}

export function getMidiNumberFromBaseNote(note: BaseNote): number {
  switch (note) {
    case 'C':
      return 0;
    case 'C#':
    case 'Db':
      return 1;
    case 'D':
      return 2;
    case 'D#':
    case 'Eb':
      return 3;
    case 'E':
      return 4;
    case 'F':
      return 5;
    case 'F#':
    case 'Gb':
      return 6;
    case 'G':
      return 7;
    case 'G#':
    case 'Ab':
      return 8;
    case 'A':
      return 9;
    case 'A#':
    case 'Bb':
      return 10;
    case 'B':
      return 11;
    default:
      throw new Error(`Invalid base note: ${note}`);
  }
}

export function getBaseNote(note: Note): BaseNote {
  const matches = note.match(/([A-G][#, b]?)(-?[0-9])/);
  if (matches != null) {
    const [, note, _] = matches;
    return note as BaseNote;
  } else {
    throw new Error(`Invalid note: ${note}`);
  }
}

export function getOctave(note: Note): number {
  const matches = note.match(/([A-G][#, b]?)(-?[0-9])/);
  if (matches != null) {
    const [, , octave] = matches;
    return Number(octave);
  } else {
    throw new Error(`Invalid note: ${note}`);
  }
}

export function getMidiNumberFromNote(note: Note): number {
  const matches = note.match(/([A-G][#, b]?)(-?[0-9])/);
  if (matches != null) {
    const [, note, octave] = matches;
    return (
      (Number(octave) + 1) * 12 + getMidiNumberFromBaseNote(note as BaseNote)
    );
  } else {
    throw new Error(`Invalid note: ${note}`);
  }
}

export function isNatural(note: Note | number): boolean {
  const base =
    (typeof note === 'number' ? note : getMidiNumberFromNote(note)) % 12;
  if (base == 1 || base == 3 || base == 6 || base == 8 || base == 10) {
    return false;
  } else {
    return true;
  }
}

function getNumWhiteKeys(start: number, end: number): number {
  let count = 0;
  for (let i = start; i <= end; i++) {
    if (isNatural(i)) {
      count++;
    }
  }
  return count;
}

export default class Piano extends Phaser.GameObjects.Container {
  keys: Phaser.GameObjects.Image[];
  overlays: Phaser.GameObjects.Image[];
  labels: (Phaser.GameObjects.Text | null)[];
  frame: Phaser.GameObjects.Rectangle;
  scene: Phaser.Scene;
  keyStart: number;
  keyWidth: number;
  keyHeight: number;
  tweens: (Phaser.Tweens.Tween | null)[] = [];

  keyValidator: (key: number) => boolean = (_) => true;

  keyDownListeners: Map<number, [() => void, Object?, boolean?][]> = new Map();
  keyUpListeners: Map<number, [() => void, Object?, boolean?][]> = new Map();
  anyKeyDownListeners: [(key: number) => void, Object?, boolean?][] = [];
  anyKeyUpListeners: [(key: number) => void, Object?, boolean?][] = [];

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    startingNote: Note,
    endingNote: Note
  ) {
    super(scene, x, y);
    this.keys = [];
    this.overlays = [];
    this.labels = [];
    this.scene = scene;
    const starting = getMidiNumberFromNote(startingNote);
    const ending = getMidiNumberFromNote(endingNote);
    this.keyStart = starting;
    const totalWhiteKeys = getNumWhiteKeys(starting, ending);
    let dummy_key = scene.add.image(0, 0, 'white_key');
    //dummy_key.setScale(dpr)
    this.keyWidth = dummy_key.width;
    this.keyHeight = dummy_key.height;
    dummy_key.destroy();
    dummy_key = scene.add.image(0, 0, 'black_key');
    const black_key_height_no_shadow = dummy_key.height;
    dummy_key.destroy();
    const keyGroupWidth = totalWhiteKeys * this.keyWidth;
    const frameWidth = (totalWhiteKeys + 2) * this.keyWidth;
    const frameHeight = this.keyHeight * 1.5;
    this.setSize(frameWidth, frameHeight);
    this.frame = scene.add.rectangle(0, 0, frameWidth, frameHeight, 0x000000);
    this.add(this.frame);
    this.createKeys(
      keyGroupWidth,
      starting,
      ending,
      black_key_height_no_shadow
    );
    this.scene.add.existing(this);
    this.sendToBack(this.frame);
    this.setSize(frameWidth, frameHeight);
    console.log('piano x: ', this.x);
  }

  createKey(
    white: boolean,
    idx: number,
    groupWidth: number,
    keyNumber: number,
    black_key_height: number
  ) {
    let offset = this.keyWidth / 2;
    if (!white) {
      const base = keyNumber % 12;
      if (base === 1 || base === 6) offset = -this.keyWidth / 8 + this.keyWidth;
      else if (base === 10 || base === 3)
        offset = this.keyWidth / 8 + this.keyWidth;
      else offset = this.keyWidth;
    }
    const obj = white
      ? this.scene.add.image(0, 0, 'white_key')
      : this.scene.add.image(0, 0, 'black_key_shadow');
    obj.setX((white ? idx : idx - 1) * this.keyWidth + offset - groupWidth / 2);
    const yOffset = white ? 0 : (obj.height - black_key_height) / 2;
    obj.setY(
      0 + (this.frame.height - 2 * this.keyHeight + obj.height) / 2 - yOffset
    );
    const color = white ? 'white' : 'black';
    const overlay = this.scene.add.image(obj.x, 0, `${color}_key_overlay`);
    overlay.setY(
      0 + (this.frame.height - 2 * this.keyHeight + overlay.height) / 2
    );
    return [obj, overlay];
  }

  createKeys(
    keyGroupWidth: number,
    start: number,
    end: number,
    black_key_height: number
  ) {
    let whites = 0;
    for (let i = start; i <= end; i++) {
      const white = isNatural(i);
      const [key, overlay] = this.createKey(
        white,
        whites,
        keyGroupWidth,
        i,
        black_key_height
      );
      this.add(key);
      this.add(overlay);
      this.keys.push(key);
      this.overlays.push(overlay);
      this.labels.push(null);
      this.tweens.push(null);
      overlay.setAlpha(0);
      if (white) {
        this.sendToBack(overlay);
        this.sendToBack(key);
      }
      whites += white ? 1 : 0;
    }
  }

  setValidator(validator: (key: number) => boolean): void {
    this.keyValidator = validator;
  }

  // piano uses pngs which have a fixed scale during import, so we need to
  // adjust the scale later by multiplying it with the dpr when scaling
  setScale(scale: number): this {
    super.setScale(scale * 1);
    return this;
  }

  highlightKey(key: number | Note, actionRequired?: boolean): void {
    if (typeof key == 'string') {
      key = getMidiNumberFromNote(key);
    }
    if (key < this.keyStart || key > this.keyStart + this.keys.length) return;
    const keyIdx = key - this.keyStart;
    if (keyIdx < 0 || keyIdx >= this.keys.length) return;
    const valid = this.keyValidator(key);
    const color = actionRequired
      ? colors.keyTintActionRequired
      : valid
        ? colors.keyTintPressed
        : colors.keyTintPressedWrong;
    const labelColor = actionRequired
      ? colors.beatNumberFillQuestion
      : valid
        ? colors.keyLabelCorrect
        : colors.beatNumberFillMiss;
    this.overlays[keyIdx].setTint(color);
    this.tweens[keyIdx]?.remove();
    this.tweens[keyIdx] = this.scene.tweens.add({
      targets: this.overlays[keyIdx],
      alpha: 1,
      duration: 200,
    });
    if (!this.labels[keyIdx]) this.addKeyLabel(getNoteNameFromMidiNumber(key));
    const label = this.labels[keyIdx];
    label!.setAlpha(1);
    //label!.setColor(`#${darken(color, 0.25).toString(16)}`);
    label!.setColor(labelColor);
  }

  onPressKey(key: number | Note) {
    if (typeof key == 'string') {
      key = getMidiNumberFromNote(key);
    }
    if (key < this.keyStart || key > this.keyStart + this.keys.length) return;
    /*const keyIdx = key - this.keyStart;
    if (keyIdx < 0 || keyIdx >= this.keys.length) return;
    this.overlays[keyIdx].setTint(colors.keyTintPressed);
    this.scene.tweens.add({
      targets: this.overlays[keyIdx],
      alpha: 1,
      duration: 200,
    });*/
    this.highlightKey(key);
    const listeners = this.keyDownListeners.get(key);
    console.debug(listeners);
    //console.debug(this.keyListeners);
    if (listeners) {
      console.log('listeners');
      for (const l of listeners) {
        l[0].call(l[1]);
      }
    }
    // all listeners are one-shot by default?! make this more easily controllable
    listeners?.clear();
    const anyListeners = this.anyKeyDownListeners;
    if (anyListeners) {
      for (const l of anyListeners) l[0].call(l[1], key);
    }
    this.anyKeyDownListeners = anyListeners.filter((x) => !x[2]);
    //this.keys[keyIdx].setTint(colors.keyTintPressed);
  }

  onReleaseKey(key: number | Note) {
    if (typeof key == 'string') {
      key = getMidiNumberFromNote(key);
    }
    if (key < this.keyStart || key > this.keyStart + this.keys.length) return;
    const keyIdx = key - this.keyStart;
    console.log(keyIdx);
    //if (keyIdx < 0 || keyIdx >= this.keys.length) return;
    //this.keys[keyIdx].clearTint();
    //this.overlay.destroy();
    this.tweens[keyIdx]?.remove();
    this.tweens[keyIdx] = this.scene.tweens.add({
      targets: this.overlays[keyIdx],
      alpha: 0,
      duration: 200,
    });
    //this.overlays[keyIdx].setAlpha(0);
    this.labels[keyIdx]?.setAlpha(0);
    const listeners = this.keyUpListeners.get(key);
    if (listeners) {
      for (const l of listeners) {
        l[0].call(l[1]);
      }
    }
  }

  addKeyListener(
    event: 'down' | 'up',
    key: Note | number | 'any',
    cb: (key?: number) => void,
    context?: Object,
    oneShot?: boolean
  ): void {
    console.log('adding listeners');
    if (key == 'any') {
      if (event == 'down')
        this.anyKeyDownListeners.push([cb, context, oneShot]);
      if (event == 'up') this.anyKeyUpListeners.push([cb, context, oneShot]);
      return;
    }
    const k = typeof key == 'string' ? getMidiNumberFromNote(key) : key;
    const list =
      event === 'down'
        ? this.keyDownListeners.get(k) ?? []
        : this.keyUpListeners.get(k) ?? [];
    console.debug(cb);
    list.push([cb, context]);
    if (event === 'down') {
      this.keyDownListeners.set(k, list);
    } else {
      this.keyUpListeners.set(k, list);
    }
    console.debug(this.keyDownListeners);
    //console.debug(this.keyListeners);
    // TODO: generate random id for every added callback and return it
  }

  removeKeyListener(
    event: 'down' | 'up',
    key: Note | number | 'any',
    id?: number
  ): void {
    if (key === 'any') {
      // Instead of clearing the whole listener list, use ids to better your life
      if (event === 'down') this.anyKeyDownListeners.clear();
      else this.anyKeyUpListeners.clear();
      return;
    }
    const k = typeof key == 'string' ? getMidiNumberFromNote(key) : key;
    if (event === 'down') {
      this.keyDownListeners.delete(k);
    } else {
      this.keyUpListeners.delete(k);
    }
    // TODO: use ids to only remove those callbacks that match the id
  }

  clearAllHighlights() {
    this.scene.tweens.add({
      targets: this.overlays,
      alpha: 0,
      duration: 200,
      onComplete: () => this.overlays.forEach((o) => o.clearTint()),
      callbackScope: this,
    });
    this.labels.forEach((label) => label?.setAlpha(0));
  }

  clearKeyHighlight(key: Note | number): void {
    const k = typeof key == 'string' ? getMidiNumberFromNote(key) : key;
    const keyIdx = k - this.keyStart;
    this.scene.tweens.add({
      targets: this.overlays[keyIdx],
      alpha: 0,
      duration: 200,
      onComplete: () => this.overlays[keyIdx].clearTint(),
      callbackScope: this,
    });
    if (this.labels[keyIdx]) this.labels[keyIdx]!.setAlpha(0);
  }

  addKeyLabel(key: Note): void {
    const keyIdx = getMidiNumberFromNote(key) - this.keyStart;
    const k = this.keys[keyIdx];
    if (this.labels[keyIdx]) {
      this.labels[keyIdx]!.setAlpha(1);
      return;
    }
    const label = this.scene.add.text(
      k.x,
      k.y + k.displayHeight / 2 + 70 * 1,
      getBaseNote(key),
      {
        fontFamily: 'Lato',
        fontStyle: '900',
        fontSize: 300,
        color: '#000000',
      }
    );
    this.add(label);
    label.setX(label.x - label.displayWidth / 2);
    this.labels[keyIdx] = label;
  }

  hideKeyLabel(key: Note): void {
    const keyIdx = getMidiNumberFromNote(key) - this.keyStart;
    if (this.labels[keyIdx]) this.labels[keyIdx]!.destroy();
  }

  lowestOctave() {
    return Math.floor(this.keyStart / 12);
  }

  highestOctave() {
    return Math.floor((this.keyStart + this.keys.length - 1) / 12);
  }
}
