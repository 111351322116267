import { ReactToExerciseEventType } from "Phaser/EventBus";
import Tutorial, { ChapterType, ExerciseScene } from "Types/ExerciseData";

const Level2: Tutorial = {
  end: '2:14',
  level: 2,
  videoUrl: 'level-2.mp4',
  sections: [
    {
      chapters: [
        {
          start: '0:0',
          title: 'D in the Treble Clef',
          data: [ChapterType.Lecture],
        },
        {
          start: '0:20',
          title: 'D on the Keyboard',
          data: [ChapterType.Lecture],
        },
        {
          start: '0:31',
          title: 'Find D',
          data: [
            ChapterType.Exercise,
            {
              scene: [
                ExerciseScene.PianoScene,
                {
                  transition: 'Dissolve',
                  textPromptPosition: 'Top',
                  objectives: [
                    {
                      text: 'Play D on your keyboard.',
                      target: 'D4',
                      videoCompleteDestination: '0:36',
                    },
                  ],
                },
              ],
              events: [
                {
                  at: '0:33',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  at: '0:34.5',
                  pause: true,
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      chapters: [
        {
          title: 'Finger Numbers',
          start: '0:38.5',
          data: [ChapterType.Lecture],
        },
        {
          title: 'Playing D',
          start: '0:54',
          data: [
            ChapterType.Exercise,
            {
              scene: [
                ExerciseScene.NoTimeExercise,
                {
                  firstObjectiveAppearDelay: 500,
                  padding_x: 230,
                  objectives: [
                    {
                      videoSkipDestination: '1:11.5',
                      videoCompleteDestination: '1:10',
                    },
                  ],
                },
              ],
              events: [
                {
                  at: '0:55',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  at: '1:03',
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                  pause: true,
                },
              ],
            },
          ],
        },
        {
          start: '1:11',
          title: 'Playing with the 2nd Finger',
          data: [ChapterType.Lecture],
        },
        {
          start: '1:45',
          title: 'Playing C and D',
          data: [
            ChapterType.Exercise,
            {
              scene: [
                ExerciseScene.NoTimeExercise,
                {
                  centre_align_horizontal: true,
                  objectives: [
                    {
                      videoSkipDestination: '1:58',
                      videoCompleteDestination: '1:56',
                    },
                  ],
                },
              ],
              events: [
                {
                  at: '1:47',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  at: '1:50',
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                  pause: true,
                },
              ],
            },
          ],
        },
        {
          start: '2:01',
          title: 'Playing in Time',
          data: [
            ChapterType.Exercise,
            {
              scene: [
                ExerciseScene.StaffExercise,
                {
                  objectives: [
                    {
                      tempo: 72,
                      videoCompleteDestination: '2:06',
                      videoSkipDestination: '2:07',
                    },
                    {
                      tempo: 72,
                      videoCompleteDestination: '2:10.5',
                      videoSkipDestination: '2:12',
                    },
                  ],
                },
              ],
              events: [
                {
                  at: '2:02.5',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  at: '2:04',
                  pause: true,
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                },
                {
                  at: '2:07',
                  phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                },
                {
                  at: '2:08.5',
                  pause: true,
                  phaserEvent: [ReactToExerciseEventType.StartExercise],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
export default Level2;

